import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Cooperativa = lazy(() => import('../../../views/LibrosFormas/Cooperativas/index'))
const Sede = lazy(() => import('../../../views/LibrosFormas/Sede/index'))
const Actividad = lazy(() => import('../../../views/LibrosFormas/Actividad/index'))
const Categoria = lazy(() => import('../../../views/LibrosFormas/Categoria/index'))
const SeguimientoOficio = lazy(() => import('../../../views/LibrosFormas/SeguimientoOficios/index'))
const CortesFormas = lazy(() => import('../../../views/LibrosFormas/CortesForma/index'))
const Sticker = lazy(() => import('../../../views/LibrosFormas/Sticker/index'))
const AutorizacionCorteFormas = lazy(() => import('../../../views/LibrosFormas/CorteFormas/index'))
const // BajaDocumentos = lazy(() => import('../../../views/LibrosFormas/BajaDocumentos/index')),
  Atributos = lazy(() => import('../../../views/LibrosFormas/Catalogos/atributos/index')),
  Formas = lazy(() => import('../../../views/LibrosFormas/Catalogos/Formas/index')),
  TipoChecklist = lazy(() => import('../../../views/LibrosFormas/Catalogos/tipoChecklist/index')),
  LibroSucursal = lazy(() => import('../../../views/LibrosFormas/LibroSucursal/index')),
  AutorizarLibroSucursal = lazy(
    () => import('../../../views/LibrosFormas/LibroSucursalAutorizacion/index')
  ),
  // BajaLibros = lazy(() => import('../../../views/LibrosFormas/BajaLibros/index')),
  FormaSucursal = lazy(() => import('../../../views/LibrosFormas/FormaSucursal/index')),
  FormaSucursalAutorizacion = lazy(
    () => import('../../../views/LibrosFormas/FormaSucursalAutorizacion/index')
  ),
  Checklist = lazy(() => import('../../../views/LibrosFormas/Checklist/index')),
  Constancias = lazy(() => import('../../../views/LibrosFormas/Constancias/index')),
  // Archivo
  TipoArchivo = lazy(() => import('../../../views/LibrosFormas/Archivo/TipoArchivo/index')),
  TipoResguardo = lazy(() => import('../../../views/Archivo/TipoResguardo/index')),
  Archivo = lazy(() => import('../../../views/LibrosFormas/Archivo/Archivo/index')),
  DespachoArchivo = lazy(() => import('../../../views/LibrosFormas/Archivo/DespachoArchivo/index')),
  //BusquedaArchivo = lazy(()=> import('../../../views/LibrosFormas/Archivo/BusquedaArchivo/index')),
  RecepcionArchivo = lazy(
    () => import('../../../views/LibrosFormas/Archivo/RecepcionArchivo/index')
  ),
  // Persona = lazy(() => import('../../../views/LibrosFormas/Persona/index')),
  TipoManejo = lazy(() => import('../../../views/LibrosFormas/Catalogos/TipoManejo/index')),
  EstadoCooperativa = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/EstadoCooperativa/index')
  ),
  TipoAutorizacion = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/TipoAutorizacion/index')
  ),
  TipoContabilidad = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/TipoContabilidad/index')
  ),
  TipoContador = lazy(() => import('../../../views/LibrosFormas/Catalogos/TipoContador/index')),
  Federacion = lazy(() => import('../../../views/LibrosFormas/Catalogos/Federacion/index')),
  TipoApartado = lazy(() => import('../../../views/LibrosFormas/Catalogos/TipoApartado/index')),
  GestionFormatos = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/GestionFormatos/index')
  ),
  GestionFormatosCooperativas = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/GestionFormatosCooperativas/index')
  ),
  FormaEntrega = lazy(() => import('../../../views/LibrosFormas/Catalogos/FormaEntrega/index')),
  TipoDetalleSolvencia = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/TipoDetalleSolvencia/index')
  ),
  Cargo = lazy(() => import('../../../views/LibrosFormas/Catalogos/Cargo/index')),
  TipoSeccionDocumento = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/TipoSeccionDocumento/index')
  ),
  Clasificacion = lazy(() => import('../../../views/LibrosFormas/clasificacion/index')),
  ClaseAsociacion = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/ClaseAsociacion/index')
  ),
  TipoCooperativa = lazy(
    () => import('../../../views/LibrosFormas/Catalogos/TipoCooperativas/index')
  ),
  TipoBaja = lazy(() => import('../../../views/LibrosFormas/Catalogos/TipoBaja/index')),
  TipoHoja = lazy(() => import('../../../views/LibrosFormas/Catalogos/TipoHoja/index')),
  SeccionOficios = lazy(() => import('../../../views/LibrosFormas/Oficios/index')),
  Nombramientos = lazy(() => import('../../../views/LibrosFormas/Nombramientos/index')),
  Buzon = lazy(() => import('../../../views/LibrosFormas/Buzon/index')),
  ControlInactivasMemoriasPT = lazy(
    () => import('../../../views/LibrosFormas/ControlInactivas/index')
  ),
  Metas = lazy(
    () => import('../../../views/LibrosFormas/Metas/index')
  )

const Index = (props: any) => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/actividad`} component={Actividad} />
      <Route exact path={`${props.match.path}/fisca-nombramiento`} component={Nombramientos} />
      <Route exact path={`${props.match.path}/categoria`} component={Categoria} />
      <Route exact path={`${props.match.path}/tipoapartado`} component={TipoApartado} />
      <Route
        exact
        path={`${props.match.path}/tiposecciondocumento`}
        component={TipoSeccionDocumento}
      />
      <Route exact path={`${props.match.path}/cooperativa/:codigo`} component={Sede} />
      <Route exact path={`${props.match.path}/cooperativa`} component={Cooperativa} />
      <Route exact path={`${props.match.path}/seguimiento-oficios`} component={SeguimientoOficio} />
      <Route exact path={`${props.match.path}/cortes-forma`} component={CortesFormas} />
      <Route exact path={`${props.match.path}/sticker`} component={Sticker} />
      {/* <Route exact path={`${props.match.path}/baja-documentos`} component={BajaDocumentos} /> */}
      <Route exact path={`${props.match.path}/catalogos/tipomanejo`} component={TipoManejo} />
      <Route
        exact
        path={`${props.match.path}/catalogos/estadocooper`}
        component={EstadoCooperativa}
      />
      <Route
        exact
        path={`${props.match.path}/catalogos/tipoautorizacion`}
        component={TipoAutorizacion}
      />
      <Route exact path={`${props.match.path}/catalogos/tipoContador`} component={TipoContador} />
      <Route
        exact
        path={`${props.match.path}/catalogos/tipocontabilidad`}
        component={TipoContabilidad}
      />
      <Route exact path={`${props.match.path}/catalogos/tipoHoja`} component={TipoHoja} />
      <Route exact path={`${props.match.path}/catalogos/federacion`} component={Federacion} />
      <Route exact path={`${props.match.path}/catalogos/FormaEntrega`} component={FormaEntrega} />
      <Route
        exact
        path={`${props.match.path}/catalogos/TipoDetalleSolvencia`}
        component={TipoDetalleSolvencia}
      />
      <Route exact path={`${props.match.path}/catalogos/Cargo`} component={Cargo} />
      <Route
        exact
        path={`${props.match.path}/catalogos/claseasociacion`}
        component={ClaseAsociacion}
      />
      <Route
        exact
        path={`${props.match.path}/catalogos/tipocooperativa`}
        component={TipoCooperativa}
      />
      <Route exact path={`${props.match.path}/catalogos/tipobaja`} component={TipoBaja} />
      <Route exact path={`${props.match.path}/catalogos/atributos`} component={Atributos} />
      <Route exact path={`${props.match.path}/catalogos/formas`} component={Formas} />
      <Route
        exact
        path={`${props.match.path}/catalogos/GestionFormatos`}
        component={GestionFormatos}
      />
      <Route
        exact
        path={`${props.match.path}/lbfexterno/gestion-formatos-cooperativas`}
        component={GestionFormatosCooperativas}
      />
      <Route
        exact
        path={`${props.match.path}/catalogos/tipo-checklist`}
        component={TipoChecklist}
      />
      <Route exact path={`${props.match.path}/libro-sucursal`} component={LibroSucursal} />
      <Route
        exact
        path={`${props.match.path}/autorizacion-libro-sucursal`}
        component={AutorizarLibroSucursal}
      />
      {/* <Route exact path={`${props.match.path}/baja-libros`} component={BajaLibros} /> */}
      <Route exact path={`${props.match.path}/forma-sucursal`} component={FormaSucursal} />
      <Route
        exact
        path={`${props.match.path}/autorizacion-forma-sucursal`}
        component={FormaSucursalAutorizacion}
      />
      <Route exact path={`${props.match.path}/checklist`} component={Checklist} />
      <Route exact path={`${props.match.path}/constancias`} component={Constancias} />
      {/* <Route exact path={`${props.match.path}/usuario-externo`} component={Persona} /> */}
      {/* // archivo */}
      <Route path={`${props.match.path}/tipoArchivo`} component={TipoArchivo} />
      <Route path={`${props.match.path}/tipoResguardo`} component={TipoResguardo} />
      <Route path={`${props.match.path}/archivo`} component={Archivo} />
      <Route path={`${props.match.path}/despachoArchivo`} component={DespachoArchivo} />
      {/* <Route path={`${props.match.path}/busquedaArchivo`} component={BusquedaArchivo} /> */}
      <Route path={`${props.match.path}/recepcionArchivo`} component={RecepcionArchivo} />
      <Route path={`${props.match.path}/clasificacion`} component={Clasificacion} />
      <Route
        path={`${props.match.path}/autorizacion-corteFormas`}
        component={AutorizacionCorteFormas}
      />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Route
        path={`${props.match.path}/control-inactivas`}
        component={ControlInactivasMemoriasPT}
      />
      <Route
        path={`${props.match.path}/buzon`}
        component={Buzon}
      />
      <Route
        path={`${props.match.path}/meta`}
        component={Metas}
      />

      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
