/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'

const Publicaciones = () => {
  return (
    <AsideMenuItemWithSub
      to='/juridico/historial-publicaciones'
      title={'Publicación'}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
        <AsideMenuItem
          to='/juridico/historial-publicaciones'
          title={"Historial Publicaciones"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />

    </AsideMenuItemWithSub>
  )
}

export default Publicaciones
