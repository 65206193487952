import { createContext, useState, useContext, useEffect } from 'react'
import { PostRoute, GetRoute } from '../../services/Private'
import { LoadingContext } from '../../utility/Loading/provider'
import {useRequest} from '../../utility/Hooks/useRequestSWR'
import {useSWRConfig} from 'swr'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {

const [oneData, setOneData] = useState([]),
[ubicaciones, setUbicaciones] = useState([]),
    {setShowLoad} = useContext(LoadingContext),
    nameController = 'persona',
    nameController7 = 'ubicacion',
    nameController8 = 'puesto',
    nameControlle9 = 'datosPersona',
    {data: DataAll} = useRequest(`${nameControlle9}/all`, 'GET'),
    One = async () => {
        setShowLoad(true)
        const response = await PostRoute(`${nameController}/get-perfil`)
        setOneData((response.data !== null ) ? response.data[0] : [])
        setShowLoad(false)
    },
     AllUbicaciones = async () => {
        const response = await GetRoute(`${nameController7}/label`)
        setUbicaciones(response.data !== null ? response.data : [])
      }

    const value = {
        oneData,
        ubicaciones,
        DataAll
    }

    useEffect(
        () => {
            const functionFetch=async()=>{
                await One()
                AllUbicaciones()
            }
            functionFetch();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )

      
    return ( 
        <ContentContext.Provider value={value} >
            { children}
        </ContentContext.Provider>
    )

}

