import {DefaultLayoutConfig} from '../../_metronic/layout/core/DefaultLayoutConfig'
import Blowfish from 'javascript-blowfish'

export const userService = {
  login,
  logout,
  Exit,
}

const RouteBase = process.env.REACT_APP_ROUTE_BASE
// const RouteBase = 'https://siscoop.ingecop.gob.gt:44377/api'

async function PostLogin(url, form) {
  const data = JSON.stringify({
    ...form,
  })

  // le enviamos el formulario con fetch por el método post
  const response = await fetch(`${RouteBase}/${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data,
  })

  return await response
}

async function login(data) {
  const verificar = localStorage.getItem('metronic-react-demo100')

  var user = await PostLogin('login', data)

  user = await handleResponse(user)
  if (user) {
    // store user details and basic auth credentials in local storage
    // to keep user logged in between page refreshes
    if (!verificar) {
      localStorage.setItem('metronic-react-demo100', JSON.stringify(DefaultLayoutConfig))
    }
    const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY)
    user.authdata = window.btoa(data)
    user.token = user.data.token
    user.code = user.data.codigo
    const dataString = JSON.stringify(user.data)
    const encrypted = await bf.encrypt(dataString)
    const encryptedMime = bf.base64Encode(encrypted)
    user.data = encryptedMime
    localStorage.setItem('authUserI', JSON.stringify(user))
  }

  return user
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('authUserI')
  localStorage.removeItem('img')
}

function Exit() {
  // remove user from local storage to log user out
  localStorage.removeItem('authUserI')
  window.location.href = '/login'
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout()
        // eslint-disable-next-line no-restricted-globals
        location.reload(true)
      }
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
