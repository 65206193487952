import { createContext, useState, useContext, useEffect } from 'react'
import { Notification } from '../../../utility/Notify/index'
import { PostRoute, GetRoute } from '../../../services/Private'
import { LoadingContext } from '../../../utility/Loading/provider'
import { useSWRConfig } from 'swr'
import { useRequest } from '../../../utility/Hooks/useRequestSWR'
export const ContentContext = createContext()

export const ContentProvider = ({ children, codigo, examen }) => {

    const
        { mutate } = useSWRConfig(),
        // { setShowLoad } = useContext(LoadingContext),
        [show, setShow] = useState(false),
        [oneData, setOneData] = useState([]),
        [allData, setAllData] = useState([]),
        [allDataPlantilla, setAllDataPlantilla] = useState([]),
        [allDataCapa, setAllDataCapa] = useState([]),
        [categorias, setCategorias] = useState([]),
        [opcion, setOpcion] = useState(0),
        [ errorToken, setErrorToken ] = useState(false),
        [showVisorDiploma, setShowVisorDiploma] = useState(false),
        [successAlert, setSuccessAlert] = useState(false),
        [pdf, setPdf] = useState([]),
        [img, setImg] = useState([]),
        [total, setTotal] = useState(0),
        nameComponent = 'Tipo Edades',
        nameController = 'Evaluaciones',
        nameController2 = 'tipoEdades',
        nameController3 = 'Persona',
        nameController4 = 'Plantillas',
        nameController5 = 'Capacitaciones',
        nameFileExport = 'Reporte de Tipo Edades',
        { data: DataAll } = useRequest(`${nameController}/all`, 'POST', {id: examen}),
        { data: DataAllCapa } = useRequest(`${nameController5}/all`, 'GET'),
        { data: labelPersonas } = useRequest(`${nameController3}/label`, 'GET'),
        { data: labelEdades } = useRequest(`${nameController2}/label`, 'GET'),

        toggleModal = data => {
            setOpcion(data)
            if (data === 1) { setOneData([]) }
            setShow(!show)
        },
        One = async (data) => {
            //setShowLoad(true)
            const response = await PostRoute(`${nameController4}/one`, { id: data })
            setOneData((response.data.length) ? response.data[0] : [])
            //setShowLoad(false)
        },
        StoreUpdate = async (data) => {
            //setShowLoad(true)
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'create' : 'update'}`, data)
            if (response.response === 0) {
                Notification(response.message, 2)
            } else {
                Notification(response.message, 1)
            }
            toggleModal(0)
            mutate(`${nameController}/all`)
            //setShowLoad(false)
        },
        GenerarPlantillaDiploma = async (id) => {
            setImg([])
            let response = []
            response = await PostRoute(`${nameController4}/one-archivo`, {id: id})
            if(response) {
                setImg(response.data[0].adjunto)
                setSuccessAlert(true)
            }
        },
        Actions = async (data) => {
            //setShowLoad(true)
                   const response = await PostRoute(`${nameController}/status`, {
        id: data.id,
        estado: data.idEstado === 1 ? 0 : 1,
      })
            Notification(response.message, 1)
            mutate(`${nameController}/all`)
            //setShowLoad(false)
        },
        value = {
            Actions,
            One,
            setOpcion,
            StoreUpdate,
            toggleModal,
            allData,
            allDataPlantilla,
            allDataCapa,
            categorias,
            nameComponent,
            nameFileExport,
            oneData,
            opcion,
            show,
            errorToken, setErrorToken,
            labelPersonas,
            labelEdades,
            showVisorDiploma, setShowVisorDiploma,
            pdf, setPdf,
            total, setTotal,
            img, setImg,
            GenerarPlantillaDiploma,
            setSuccessAlert, successAlert
        }

    useEffect(() => {
        if (DataAll && DataAll.value === 1) {
            setAllData(DataAll.data);
          }
    }, [DataAll, codigo])

    useEffect(() => {
        if (DataAllCapa && DataAllCapa.value === 1) {
            setAllDataCapa(DataAllCapa.data);
          }
    }, [DataAllCapa, codigo])


    return (
        <ContentContext.Provider value={value} >
            {children}
        </ContentContext.Provider>
    )
}