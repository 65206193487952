/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'

const Admin = () => {
  return (
    <AsideMenuItemWithSub
      to='/compras/admin'
      title={'Admin'}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
      <AsideMenuItem to='/compras/admin/articulo' title={'Artículo'} biIcon='bi-basket-fill' />

      <AsideMenuItem to='/compras/admin/unidad' title={'Unidad de Medida'} biIcon='bi-rulers' />

      <AsideMenuItem to='/compras/admin/presentacion' title={'Presentación'} biIcon='bi-square' />

      <AsideMenuItem to='/compras/admin/renglon' title={'Renglón'} biIcon='bi-sort-numeric-down' />
      <AsideMenuItem
        to='/compras/admin/tipo-checklist'
        title={'Tipo Checklist'}
        biIcon='bi-file-check'
      />
      <AsideMenuItem to='/compras/admin/atributo' title={'Atributos'} biIcon='bi-shield-check' />
      <AsideMenuItem to='/compras/admin/checklist' title={'Checklist'} biIcon='bi-list-check' />
      <AsideMenuItem
        to='/compras/admin/asig-checklist-atributo'
        title={'Atributo Checklist'}
        biIcon='bi-card-checklist'
      />
    </AsideMenuItemWithSub>
  )
}

export default Admin
