import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// import { UserContext } from '../../../../app/utility/context/userContext'

// const PapeleriaRecibida = lazy(() => import('../../../views/DespachoInspeccion/Recibida/index')),
const Revision = lazy(() => import('../../../views/DespachoInspeccion/Revision/index')),
  Redaccion = lazy(() => import('../../../views/DespachoInspeccion/Redaccion/index')),
  Traslados = lazy(() => import('../../../views/DespachoInspeccion/Traslados/index')),
  Distribucion = lazy(() => import('../../../views/DespachoInspeccion/Recepcion/index')),
  TipoDpcumento = lazy(() => import('../../../views/DespachoInspeccion/Catalogos/tipoDocumento/index')),
  Papeleria = lazy(() => import('../../../views/DespachoInspeccion/Papeleria/index')),
  Fiscalizacion = lazy(() => import('../../../views/DespachoInspeccion/Fiscalizacion/index')),
  SeguimientoSupervision = lazy(() => import('../../../views/DespachoInspeccion/SeguimientoSupervion/index')),
  RangoExcedente = lazy(() => import('../../../views/DespachoInspeccion/Caja/rangoExcedente/index')),
  SeccionOficios = lazy(() => import('../../../views/DespachoInspeccion/Oficios/index')),
  // ReqTrasladoDespacho = lazy(() => import('../../../views/DespachoInspeccion/RequerimientoTrasladoDesp/index')),
  EnvioProvidenciaOficios = lazy(() => import('../../../views/DespachoInspeccion/EnvioProvidenciasOficios/index')),
  FiscaRegional = lazy(() => import('../../../views/DespachoInspeccion/fiscaregional/index')),
  PlanAnual = lazy(() => import('../../../views/DespachoInspeccion/Fiscalizacion/PlanAnual/index')),
  SeguimientoRegional = lazy(() => import('../../../views/DespachoInspeccion/seguimientoRegional/index')),
  PapeleriaSubDespacho = lazy(() => import('../../../views/DespachoInspeccion/PapeleriaRecibidaSubDespacho/index'))
  const ReqTraslado = lazy(() => import('../../../views/RequerimientoTraslado/index'))
  const RevisionAutorizadoInforme = lazy(() => import('../../../views/DespachoInspeccion/InformeVatSupervisor/index'))
  const CooperativasJefatura = lazy(() => import('../../../views/CooperativasJefatura/Cooperativas/index'))
  const CooperativasHeaderJefatura = lazy(() => import('../../../views/CooperativasJefatura/Sede/index'))
const Index = (props: any) => {
  // const { Roles, userRoles } = useContext(UserContext)

  return (
    <Switch>
      {
        // userRoles.includes(Roles.a_cpr) &&
        <>
          {/* <Route path={`${props.match.path}/recibida`} component={PapeleriaRecibida} /> */}
          <Route path={`${props.match.path}/informes-fiscalizacion`} component={Fiscalizacion} />
          <Route path={`${props.match.path}/revision-autorizacion-informes`} component={RevisionAutorizadoInforme} />
          <Route path={`${props.match.path}/revision`} component={Revision} />
          <Route path={`${props.match.path}/redaccion`} component={Redaccion} />
          <Route path={`${props.match.path}/traslado`} component={Traslados} />
          <Route path={`${props.match.path}/distribucion`} component={Distribucion} />
          <Route path={`${props.match.path}/catalogos/tipo-documento`} component={TipoDpcumento} />
          <Route path={`${props.match.path}/recibida`} component={Papeleria} />
          <Route path={`${props.match.path}/informes-seguimiento`} component={SeguimientoSupervision} />
          <Route path={`${props.match.path}/caja/rango-excedente`} component={RangoExcedente} />
          <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
          <Route path={`${props.match.path}/envio-providencias-oficios`} component={EnvioProvidenciaOficios} />
          <Route path={`${props.match.path}/fiscalizacion`} component={FiscaRegional} />
          <Route path={`${props.match.path}/fiscalizacion-plan-anual`} component={PlanAnual} />
          <Route path={`${props.match.path}/seguimiento-regional`} component={SeguimientoRegional} />
          <Route path={`${props.match.path}/PapeleriaRecibidaSubDespacho`} component={PapeleriaSubDespacho} />
          <Route path={`${props.match.path}/cooperativasjefatura`} component={CooperativasJefatura} />
          <Route path={`${props.match.path}/cooperativasjefatura/:copejefa`} component={CooperativasHeaderJefatura} />
          {
            <Route  path={`${props.match.path}/despachortraslado`} component={ReqTraslado}/>
          }
        </>
      }
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
