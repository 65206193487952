/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const IT = () => {

  return (
      <AsideMenuItemWithSub
        to='/TI'
        title= {"Administración"}
        biIcon="bi-hdd-stack-fill"
      >
        
        <AsideMenuItem
          to='/it/config-correo'
          title={"Configuración Servidor de Correo"}
          biIcon="bi-envelope"
        />

        <AsideMenuItem
          to='/it/rol'
          title={"Rol"}
          biIcon="bi-check2-circle"
        />

        <AsideMenuItem
          to='/it/genero'
          title={"Genero"}
          faIcon="fa-venus-mars"
        />

        <AsideMenuItem
          to='/it/tipo-persona'
          title={"Tipo Persona"}
          biIcon="bi-people-fill"
        />
                
        <AsideMenuItem
          to='/it/usuario'
          title={"Usuario"}
          biIcon="bi-person-circle"
        />
        
        <AsideMenuItem
          to='/it/departamento'
          title={"Departamento"}
          biIcon="bi-geo-alt-fill"
        />
        
        <AsideMenuItem
          to='/it/grupo'
          title={"Grupo"}
          biIcon="bi-grid-fill"
        />
        
        <AsideMenuItem
          to='/it/sub-grupo'
          title={"Sub-Grupo"}
          biIcon="bi-grid-3x3-gap-fill"
        />
        
        <AsideMenuItem
          to='/it/renglon'
          title={"Renglón"}
          biIcon="bi-stack"
        />
        <AsideMenuItem
          to='/it/modalidad'
          title={"Modalidad Compra"}
          biIcon="bi-bag-plus"
        />
        
        <AsideMenuItem
          to='/it/municipio'
          title={"Municipio"}
          biIcon="bi-pin-map-fill"
        />
        <AsideMenuItem
          to='/it/acceso-modalidad'
          title={"Restablecer Acceso Cooperativa"}
          biIcon="bi-key"
        />
        <AsideMenuItem
          to='/it/controlsesiones'
          title={"Control de Inicio de Sesión"}
          biIcon="bi-person-circle"
        />
        <AsideMenuItem
          to='/it/actividadcontrol'
          title={"Bitacora de Actividades de Usuario"}
          biIcon="bi-list"
        />
        {/* <AsideMenuItemWithSub
          to='/it/italmacen'
          title= {"Almacén"}
          biIcon="bi-box-seam"
        >
          <AsideMenuItem
            to='/it/almacen/bodega'
            title="Bodegas"
            biIcon="bi-house"
          />

          <AsideMenuItem
            to='/it/almacen/admin'
            title={"Admin"}
            biIcon="bi-book"
          />
        </AsideMenuItemWithSub> */}

        {/* <AsideMenuItem
          to='/it/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}
      </AsideMenuItemWithSub>
  )
}

export default IT