import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Cooperativas = lazy(() => import('../../../views/vigilanciaAlertaTemprana/cooperativas/index'));
const DetalleCoperativa = lazy(() => import('../../../views/vigilanciaAlertaTemprana/cooperativa/index')),
      Herramienta = lazy(() => import('../../../views/vigilanciaAlertaTemprana/Catalogos/herramienta/index')),
      Segmentador = lazy(() => import('../../../views/vigilanciaAlertaTemprana/Catalogos/rango/index')),
      Nivel = lazy(() => import('../../../views/vigilanciaAlertaTemprana/Catalogos/configRiesgos/index')),
      TipoNombramiento = lazy(() => import('../../../views/vigilanciaAlertaTemprana/Catalogos/tipoNombramiento/index')),
      Nombramiento = lazy(() => import('../../../views/vigilanciaAlertaTemprana/NombramientoN/index')),
      PlanAnual = lazy(() => import('../../../views/vigilanciaAlertaTemprana/PlanAnual/index')),
      Ejecucion = lazy(() => import('./Ejecucion/index')),
      Historico = lazy(() => import('../../../views/vigilanciaAlertaTemprana/HistoricoVigilanciaAlerta/index')),

      SeccionOficios = lazy(() => import('../../../views/vigilanciaAlertaTemprana/Oficios/index')),
      revisionAutorizacionInformes = lazy(()=> import('../../../views/vigilanciaAlertaTemprana/revisionAutorizacionInformes/index')),
      informeAnalista = lazy(()=> import('../../../views/vigilanciaAlertaTemprana/InformesAnalista/index')),
      ListaMemoriasAlertas = lazy(()=> import('../../../views/vigilanciaAlertaTemprana/ListaMemoriasAlertas/index')),
      segmentoColor = lazy(()=> import('../../../views/vigilanciaAlertaTemprana/Catalogos/color/index')),
      ReporteSegmentacion = lazy(()=> import('../../../views/vigilanciaAlertaTemprana/ReporteSegmentacion/index')),
      ReporteFinanciero = lazy(()=> import('../../../views/vigilanciaAlertaTemprana/ReporteFinanciero/index'))

const Index = (props: any) =>{
  
  return (
    <Switch>
      <Route path={`${props.match.path}/ejecucion/cooperativas/:code/:idNombramiento`} component={DetalleCoperativa} />

      <Route path={`${props.match.path}/ejecucion`} component={Ejecucion} />
      <Route path={`${props.match.path}/cooperativas`} component={Cooperativas} />
      {/* <Route path={`${props.match.path}/catalogos/recomendaciones`} component={Recomendacion} /> */}
      <Route path={`${props.match.path}/catalogos/herramienta`} component={Herramienta} />
      <Route path={`${props.match.path}/catalogos/segmentador`} component={Segmentador} />
      <Route path={`${props.match.path}/catalogos/nivel`} component={Nivel} />
      <Route path={`${props.match.path}/catalogos/color`} component={segmentoColor} />
      <Route path={`${props.match.path}/nombramiento`} component={Nombramiento} />
      <Route path={`${props.match.path}/plananual`} component={PlanAnual} />
      <Route path={`${props.match.path}/catalogos/tipo-nombramiento`} component={TipoNombramiento} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Route path={`${props.match.path}/vigilanciaAlertaTemprana`} component={Historico} />
      <Route path={`${props.match.path}/informe-analista`} component={informeAnalista} />
      <Route path={`${props.match.path}/ListaMemoriasAlertas`} component={ListaMemoriasAlertas} />
      <Route path={`${props.match.path}/revision-autorizacion-informes`} component={revisionAutorizacionInformes} />
      <Route path={`${props.match.path}/reporte-segmentacion`} component={ReporteSegmentacion} />
      <Route path={`${props.match.path}/reporte-financiero`} component={ReporteFinanciero} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
