
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from '../../utility/Globales/index'
import { PostRouteFD, GetRoute } from '../../services/Public'
import CustomLabel from '../../utility/customLabel/index'
import { Form, Card, Row, Col, Button } from "react-bootstrap-v5"
import { Notification } from '../../utility/Notify/index'
import { useForm, Controller } from "react-hook-form";
import * as Icon from 'react-feather'
import DatePicker from 'react-datepicker'
import Select from 'react-select'

interface IFormInputs {

    nombre1: string
    nombre2: string
    nombre3: string

    nombre4: string
    nombre5: string
    nombre6: string

    apellido1: string
    apellido2: string
    apellido3: string

    dpi: string
    nit: string

    fechaNacimiento: any
    genero: any
    tipoSangre: any
    afiliacionIGSS: string
    profesion: any
    gradoAcademico: any
    cursos: any
    estadoColegiado: any
    colegiado: string
    tipoPersona: number
    estadoCivil: any
    etnia: any
    foto: any
}

const ForgotPassword = () => {

    const { control, register, handleSubmit, formState: { errors }, setValue } = useForm<IFormInputs>()
    const [Visualizar, setVisualizar] = useState('')
    const [allDataEstadoCivil, setAllDataEstadoCivil] = useState([])
    const [generos, setGeneros] = useState([])
    const nameController = 'UsuarioExternoLBF'

    // eslint-disable-next-line 
    const onSubmit = async (data: any) => {
        let formData = new FormData();
        formData.append('foto', data.foto[0]);
        formData.append('nombre1', data.nombre1);
        formData.append('nombre2', data?.nombre2 || '');
        formData.append('nombre3', data?.nombre3 || '');
        formData.append('apellido1', data.apellido1);
        formData.append('apellido2', data?.apellido2 || '');
        formData.append('apellidoCasada', data?.apellidoCasada || '');
        formData.append('fechaNacimiento', formatDate(data.fechaNacimiento) || '');
        formData.append('dpi', data?.dpi || '');
        formData.append('nit', data?.nit || '');
        formData.append('genero', data.genero.value);
        formData.append('estadoCivil', data.estadoCivil.value);
        const response = await PostRouteFD(`${nameController}/create`, formData)

        Notification(response.message, 1)
        ResetData()
    }

    const ResetData = async () => {
        await setValue('nombre1', '')
        await setValue('nombre2', '')
        await setValue('nombre3', '')
        await setValue('apellido1', '')
        await setValue('apellido2', '')
        await setValue('apellido3', '')

        await setValue('dpi', '')
        await setValue('nit', '')
        await setValue('fechaNacimiento', new Date())
        await setValue('genero', '')
        await setValue('estadoCivil', '')
        await setValue('etnia', '')
        await setValue('profesion', '')
        await setVisualizar('')
    }

    const AllGenero = async () => {
        const response = await GetRoute(`genero/label`)
        setGeneros(response.data !== null ? response.data : [])
    }

    const AllEstadosCiviles = async () => {
        const response = await GetRoute(`estadoCivil/label`)
        setAllDataEstadoCivil(response.data !== null ? response.data : [])
    }

    const MostrarDoc = (value: any) => {
        if (value.target.value) {
            var pdffile = value.target.files[0],
                pdffile_url = URL.createObjectURL(pdffile);

            setVisualizar(`${pdffile_url}`);
        }
        else {
            setVisualizar('');
        }
    }
    const clearFoto = () => {
        setVisualizar("");
        setValue('foto', null);
    }

    useEffect(
        () => {
            AllGenero()
            AllEstadosCiviles()
        }, []
    )
    return (
        <div>
            <Card.Title className='fw-bold mb-1 text-center'>
                Crear Cuenta
            </Card.Title>
            <Card.Text className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 text-primary d-flex justify-content-center w-100">
                Por favor, proporcione los datos requeridos a continuación.
            </Card.Text>
            <Form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
                <div className="size-form">
                    <Row>
                        <Col lg={3} >
                            <Row>
                                <Col className="d-flex justify-content-center align-self-center">
                                    <div className={`kt-avatar kt-avatar--outline ${(Visualizar) && 'kt-avatar--changed'}`} id="kt_user_avatar">
                                        <div className={`kt-avatar__holder ${errors && errors.foto && errors.foto.message && 'border-danger'}`} style={{ backgroundImage: `url('${Visualizar}')` }}></div>
                                        <>
                                            <Form.Label className="kt-avatar__upload p-0" data-toggle="kt-tooltip" title="Cargar Foto" data-original-title="Change avatar">
                                                {/* <Icon.Edit size={40}/> */}
                                                <i className={`fa fa-image`}></i>
                                                <Form.Control
                                                    {
                                                    ...register('foto',
                                                        {
                                                            required:
                                                            {
                                                                value: true,
                                                                message: "Este campo es requerido"
                                                            }
                                                        }
                                                    )
                                                    }
                                                    lang="es"
                                                    type="file"
                                                    className="fileInput"
                                                    accept=".jpg, .jpeg, .png"
                                                    onChange={(e) => MostrarDoc(e)}
                                                />
                                            </Form.Label>
                                            <span className="kt-avatar__cancel" onClick={() => clearFoto()} >
                                                <i className="fas fa-trash text-danger"></i>
                                            </span>
                                        </>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="text-center">
                                <Col lg={12}>
                                    <small className="text-danger">{errors && errors.foto && errors.foto.message}</small>
                                </Col>
                                <Col lg={12}>
                                    <small className="text-muted">Permitido *.jpeg, *.jpg, *.png</small>
                                </Col>
                                <Col lg={12} >
                                    <small className="text-muted">tamaño máximo de 10 MB</small>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={9}>
                            <Row>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <CustomLabel required={true} text="Primer Nombre" type="danger" />
                                        <Form.Control
                                            {...register('nombre1',
                                                {
                                                    required: "Este campo es requerido",
                                                }
                                            )
                                            }

                                            isInvalid={!!errors.nombre1}
                                        />
                                        <p className="text-danger fw-danger">{!!errors.nombre1 && errors.nombre1.message}</p>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <CustomLabel required={false} text="Segundo Nombre" />
                                        <Form.Control
                                            {...register('nombre2',

                                            )
                                            }


                                        />

                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="fw-bolder">{"Tercer Nombre"}</Form.Label>
                                        <Form.Control
                                            {...register('nombre3',

                                            )
                                            }


                                        />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <CustomLabel required={true} text="Primer Apellido" type="danger" />
                                        <Form.Control
                                            {...register('apellido1',
                                                {
                                                    required: "Este campo es requerido",
                                                }
                                            )
                                            }

                                            isInvalid={!!errors.apellido1}
                                        />
                                        <p className="text-danger fw-danger">{!!errors.apellido1 && errors.apellido1.message}</p>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <CustomLabel required={false} text="Segundo Nombre" />
                                        <Form.Control
                                            {...register('apellido2')
                                            }

                                            isInvalid={!!errors.apellido2}
                                        />
                                        <p className="text-danger fw-danger">{!!errors.apellido2 && errors.apellido2.message}</p>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <CustomLabel required={false} text="Apellido Casada" />
                                        <Form.Control
                                            {...register('apellido3',

                                            )
                                            }
                                        />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <CustomLabel required={true} text="No. de DPI" type="danger" />
                                        <Form.Control
                                            {...register('dpi',
                                                {
                                                    required: "Este campo es requerido",
                                                }
                                            )
                                            }

                                            isInvalid={!!errors.dpi}
                                        />
                                        <p className="text-danger fw-danger">{!!errors.dpi && errors.dpi.message}</p>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <CustomLabel required={true} text="No. de NIT" type="danger" />
                                        <Form.Control
                                            {...register('nit',
                                                {
                                                    required: "Este campo es requerido",
                                                }
                                            )
                                            }

                                            isInvalid={!!errors.dpi}
                                        />
                                        <p className="text-danger fw-danger">{!!errors.dpi && errors.dpi.message}</p>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <CustomLabel required={true} text="Fecha Nacimiento" type="danger" />
                                        <Controller
                                            control={control}
                                            name='fechaNacimiento'
                                            defaultValue={new Date()}
                                            render={({ field }) => (
                                                <DatePicker
                                                    autoComplete='off'
                                                    className='form-control'
                                                    placeholderText='Fecha Ingreso'
                                                    onChange={(e) => field.onChange(e)}
                                                    selected={field.value}
                                                    dateFormat='dd/MM/yyyy'
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Seleccione una fecha',
                                                },
                                            }}
                                        />
                                        <p className="text-danger fw-danger">{!!errors.fechaNacimiento && errors.fechaNacimiento.message}</p>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} sm={12}>
                            <Form.Group className="mb-3">
                                <CustomLabel required={true} text="Género" type="danger" />
                                <Controller
                                    name="genero"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                {...field}
                                                isClearable
                                                isSearchable
                                                defaultValue={null}
                                                options={generos}
                                                placeholder={"Seleccione una opción"}
                                                noOptionsMessage={() => 'sin resultados'}
                                            />
                                        )
                                    }
                                    }
                                    rules={{ required: "Este campo es requerido" }}
                                />
                                <p className="text-danger fw-danger">{!!errors.genero && errors.genero.message}</p>
                            </Form.Group>
                        </Col>
                        <Col lg={3} sm={12}>
                            <Form.Group className="mb-3">
                                <CustomLabel required={true} text="Estado Civil" type="danger" />
                                <Controller
                                    name="estadoCivil"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                {...field}
                                                isClearable
                                                isSearchable
                                                defaultValue={null}
                                                options={allDataEstadoCivil}
                                                placeholder={"Seleccione una opción"}
                                                noOptionsMessage={() => 'sin resultados'}
                                            />
                                        )
                                    }
                                    }
                                    rules={{ required: "Este campo es requerido" }}
                                />
                                <p className="text-danger border-2 fw-danger">{!!errors.estadoCivil && errors.estadoCivil.message}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Button type="submit">Crear</Button>
                        </Col>
                    </Row>
                </div>
                <div className='pt-2 text-center w-100'>
                    <Link to="/auth/login">
                        <Icon.ChevronLeft size={18} /> Atrás para iniciar sesión
                    </Link>
                </div>
            </Form>
        </div>
    )
}

export default ForgotPassword