import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const TipoLlamada = lazy(() => import('../../../views/Recepcion/TipoLLamada/index')),
  LlamadasEntrantes = lazy(() => import('../../../views/Recepcion/LlamadasEntrantes/index')),
  LlamadasSalientes = lazy(() => import('../../../views/Recepcion/LlamadasSalientes/index')),
  TipoEnvio = lazy(() => import('../../../views/Recepcion/TipoEnvio/index')),
  Envios = lazy(() => import('../../../views/Recepcion/Envios/index')),
  Recepcion = lazy(() => import('../../../views/Recepcion/Recepcion/index')),
  SeccionOficios = lazy(() => import('../../../views/Recepcion/Oficios/index'))

const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/tipoLlamada`} component={TipoLlamada} />
      <Route path={`${props.match.path}/llamadasEntrantes`} component={LlamadasEntrantes} />
      <Route path={`${props.match.path}/llamadasSalientes`} component={LlamadasSalientes} />
      <Route path={`${props.match.path}/tipoEnvio`} component={TipoEnvio} />
      <Route path={`${props.match.path}/envios`} component={Envios} />
      <Route path={`${props.match.path}/recepcion`} component={Recepcion} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
