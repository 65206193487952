import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const Metas = lazy(() => import('../../../views/tablero/metasDespacho/index'))

const Index = (props: any) => {
    return (
        <Switch>
            <Route path={`${props.match.path}/metas`} component={Metas} />
            <Redirect to='/error/404' />
        </Switch>
    )
}

export default Index