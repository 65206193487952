/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const Notificaciones = () => {
  return (
    <>
      <AsideMenuItemWithSub to='/mis-notificaciones' title='Notificaciones' biIcon='bi-book'>
      <AsideMenuItem to='/mis-notificaciones/notificacion' title='Mis Notificaciones' biIcon='bi-envelope' />
    </AsideMenuItemWithSub>
    <AsideMenuItemWithSub to='/mis-capacitaciones' title='Capacitaciones' biIcon='bi-laptop'>
      <AsideMenuItem to='/mis-capacitaciones/capacitaciones' title='Mis Capacitaciones' biIcon='bi-award-fill' />
      <AsideMenuItem to='/mis-capacitaciones/capa-ubicacion' title='Capacitaciones De Unidad Administrativa' biIcon='bi-award-fill' />
    </AsideMenuItemWithSub>  
    </>

  )
}

export default Notificaciones
