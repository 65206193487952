
// import { useContext } from 'react'
import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'
// import { UserContext } from '../../../../../../app/utility/context/userContext'

const Seguimiento = (Props: any) => {
  // const { Roles, userRoles } = useContext(UserContext)
  return (
    <AsideMenuItemWithSub
      to='/supervision-inspeccion '
      title={"Sección de Supervisión"}
      biIcon="bi-file-earmark-text"
    >

      <AsideMenuItem
        to='/supervision-inspeccion/fiscalizacion'
        title={"Revisión de Informes de Fiscalización"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/supervision-inspeccion/informes-seguimiento'
        title={"Revisión de Costancias o Notificaciones de Seguimiento"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'  
      />
       <AsideMenuItem
          to='/supervision-inspeccion/revision-autorizacion-informes'
          title='Vigilancia y Alerta Temprana'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/supervision-inspeccion/cooperativasjefatura'
          title='Cooperativas'
          icon='/media/icons/duotune/general/gen022.svg'
        />
      
    </AsideMenuItemWithSub>
  )
}

export default Seguimiento