import { Fragment ,useContext} from 'react'
import { ContentProvider } from './context'
import Formulario from './form/Form'
import { UserContext } from '../../../utility/context/userContext'

export const Index = () => {
    const { Roles,userRoles } = useContext(UserContext)
    return (
        <Fragment>
            <ContentProvider>
                {!userRoles.includes(Roles.accs_coope)  && <Formulario />}
            </ContentProvider>
        </Fragment>
    )
}

export default Index