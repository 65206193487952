import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const CapasPorPersona = lazy(() => import('../../../views/Capacitaciones/CapacitacionesPersona/index')),
      CapasPorUbicacion = lazy(() => import('../../../views/Capacitaciones/CapacitacionesPorUbicacion/index'))


const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/capacitaciones`} component={CapasPorPersona} />
      <Route path={`${props.match.path}/capa-ubicacion`} component={CapasPorUbicacion} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
