import {createContext, useState} from 'react'
import {Notification} from '../../utility/Notify/index'
import {PostRoute} from '../../services/Public'
export const ContentContext = createContext()

export const ContentProvider = ({children}) => {
  const // { setShowLoad } = useContext(LoadingContext),
    [idCapacitacion, setIdCapacitacion] = useState([]),
    [idCooperativa, setIdCooperativa] = useState([]),
    [idSucursales, setIdSucursales] = useState([]),
    [dataSelect, setDataSelect] = useState([]),
    [opcion, setOpcion] = useState(0),
    nameComponent = 'Capacitaciones',
    nameController = 'Capacitaciones',
    nameFileExport = 'Reporte Capacitaciones',
    InviteNotify = async (data) => {
      const response = await PostRoute(`${nameController}/invite-notify`, {id: data.idSucursal})
      if (response.response === 0) {
        Notification(response.message, 2)
      } else {
        Notification(response.message, 1)
      }
    },
    value = {
      InviteNotify,
      setDataSelect,
      setIdCapacitacion,
      setIdCooperativa,
      setIdSucursales,
      setOpcion,
      dataSelect,
      idCapacitacion,
      idCooperativa,
      idSucursales,
      nameComponent,
      nameFileExport,
      opcion,
    }

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
}
