import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Liquidacion = lazy(() => import('../../../views/Viaticos/ViaticoLiquidacion/index')),
  TipoAnticipo = lazy(() => import('../../../views/Viaticos/Catalogos/tipoAnticipo/index')),
  TipoComision = lazy(() => import('../../../views/Viaticos/Catalogos/tipoComision/index')),
  TipoViatico = lazy(() => import('../../../views/Viaticos/Catalogos/tipoViatico/index')),
  Solicitud = lazy(() => import('../../../views/Viaticos/Solicitud')),
  Gastos = lazy(() => import('../../../views/Viaticos/Gastos/index')),
  ReporteViaticos = lazy(() => import('../../../views/Viaticos/reporteViaticos/index')),
  AdministrarSolicitudesViaticos = lazy(() => import('../../../views/Viaticos/administrarSolicitudesViaticos/index')),
  LibroBancosViaticos =  lazy(() => import('../../../views/Viaticos/LibroBancosViaticos/index')),
  SeccionOficios =  lazy(() => import('../../../views/Viaticos/Oficios/index'))

const Index = (props: any) => {
  return (
    <Switch>
      {/* <Route path={`${props.match.path}/liquidacion`} component={Liquidacion} /> */}
      <Route path={`${props.match.path}/reporte-viaticos`} component={ReporteViaticos} />
      <Route path={`${props.match.path}/catalogos/tipo-anticipo`} component={TipoAnticipo} />
      <Route path={`${props.match.path}/catalogos/tipo-comision`} component={TipoComision} />
      <Route path={`${props.match.path}/catalogos/tipo-viatico`} component={TipoViatico} />
      {/* <Route path={`${props.match.path}/solicitud-viatico`} component={Solicitud} /> */}
      <Route path={`${props.match.path}/administrar-solicitudes-viaticos`} component={AdministrarSolicitudesViaticos} />
      <Route path={`${props.match.path}/libro-bancos-viaticos`} component={LibroBancosViaticos} />
      {/* <Route path={`${props.match.path}/gastos`} component={Gastos} /> */}
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
