export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export function formatDate4(date, lang) {
  let fecha
  if (lang === 'es-en') {
    const f = date.split('/')
    fecha = new Date(
      `${`${Number(f[2])}`}-${`00${Number(f[1])}`.slice(-2)}-${`00${Number(f[0]) + 1}`.slice(-2)}`
    )
  }
  return fecha
}

export function formatDate5(date) {
  // La fecha original en formato largo
  const fechaOriginal = date

  // Convertir a objeto Date
  const fechaObjeto = new Date(fechaOriginal)

  // Extraer año, mes y día
  const año = fechaObjeto.getFullYear()
  const mes = fechaObjeto.getMonth() + 1 // getMonth() devuelve un índice basado en cero, por lo que se suma 1
  const día = fechaObjeto.getDate()

  // Formatear a YYYY-MM-DD, asegurando que el mes y el día sean de dos dígitos
  const fechaFormateada = `${año}-${mes.toString().padStart(2, '0')}-${día
    .toString()
    .padStart(2, '0')}`

  return fechaFormateada
}

export function formatearNumero(numero) {
  try {
    let numeroFormateado = parseFloat(numero)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return numeroFormateado
  } catch (error) {
    return 'Error: Ingresa un número válido.'
  }
}
export function revertirFormatoNumero(numeroFormateado) {
  try {
    // Elimina las comas y convierte a punto flotante
    let numero = parseFloat(numeroFormateado.replace(/,/g, ''))
    return numero
  } catch (error) {
    return 'Error: Ingresa un número válido formateado.'
  }
}

export function numeroALetras(numero) {
  const UNIDADES = [
    'cero',
    'uno',
    'dos',
    'tres',
    'cuatro',
    'cinco',
    'seis',
    'siete',
    'ocho',
    'nueve',
  ]

  const DECENAS = [
    '',
    '',
    'veinte',
    'treinta',
    'cuarenta',
    'cincuenta',
    'sesenta',
    'setenta',
    'ochenta',
    'noventa',
  ]

  const ESPECIALES = [
    'diez',
    'once',
    'doce',
    'trece',
    'catorce',
    'quince',
    'dieciséis',
    'diecisiete',
    'dieciocho',
    'diecinueve',
  ]

  const CENTENAS = [
    '',
    'ciento',
    'doscientos',
    'trescientos',
    'cuatrocientos',
    'quinientos',
    'seiscientos',
    'setecientos',
    'ochocientos',
    'novecientos',
  ]

  function convertirParteEntera(parteEntera) {
    if (parteEntera === 100) {
      return 'cien'
    }
    if (parteEntera === 0) {
      return UNIDADES[0]
    }

    if (parteEntera < 10) {
      return UNIDADES[parteEntera]
    }

    if (parteEntera < 20) {
      return ESPECIALES[parteEntera - 10]
    }

    if (parteEntera === 20) {
      return 'veinte'
    }

    if (parteEntera < 30) {
      if (parteEntera === 21) {
        return 'ventiuno'
      } else if (parteEntera === 22) {
        return 'veintidós'
      } else if (parteEntera === 23) {
        return 'veintitrés'
      } else {
        return 'veinti' + UNIDADES[parteEntera - 20]
      }
    }

    if (parteEntera < 100) {
      const decena = Math.floor(parteEntera / 10)
      const unidad = parteEntera % 10
      return DECENAS[decena] + (unidad > 0 ? ' y ' + UNIDADES[unidad] : '')
    }

    if (parteEntera < 1000) {
      const centena = Math.floor(parteEntera / 100)
      const resto = parteEntera % 100
      if (resto === 0) {
        return CENTENAS[centena]
      }
      return CENTENAS[centena] + ' ' + convertirParteEntera(resto)
    }

    if (parteEntera === 1000) {
      return 'mil'
    }

    if (parteEntera < 2000) {
      return 'mil ' + convertirParteEntera(parteEntera % 1000)
    }

    if (parteEntera < 1000000) {
      return (
        convertirParteEntera(Math.floor(parteEntera / 1000)) +
        (parteEntera % 1000 === 0 ? ' mil' : ' mil ' + convertirParteEntera(parteEntera % 1000))
      )
    }
  }

  function convertirParteDecimal(parteDecimal) {
    const decimales = parteDecimal.padEnd(2, '0')
    return convertirParteEntera(Number(decimales))
  }

  const partes = String(numero).split('.')
  const parteEntera = Number(partes[0])
  const parteDecimal = partes[1] || '0'

  if (parteEntera === 0 && parteDecimal === '0') {
    return 'cero'
  }

  let resultado = ''

  if (parteEntera > 0) {
    resultado += convertirParteEntera(parteEntera) + ' quetzales'
  }

  if (parteDecimal !== '0') {
    resultado += ' con ' + convertirParteDecimal(parteDecimal) + ' centavos'
  } else {
    resultado += ' quetzales exactos'
  }

  return resultado
}

export function formatDate3(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('-')
}
export function formatDate2(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('/')
}
export function formatDateHours(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    datetext = d.getHours() + ':' + d.getMinutes()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-') + ' ' + datetext
}

export function formatDateHours2(fechaInicial) {
  const partesFechaHora = fechaInicial.split(' ')
  const partesFecha = partesFechaHora[0].split('/')
  const partesHora = partesFechaHora[1].split(':')

  const fecha = new Date(
    partesFecha[2], // año
    partesFecha[1] - 1, // mes (0-11)
    partesFecha[0], // día
    partesHora[0], // horas
    partesHora[1] // minutos
  )

  return fecha
}

export function formatQuetzal(num) {
  return 'Q.' + num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function revertirFormatoQuetzal(str) {
  const numStr = str.replace(/Q\.|,/g, '')

  const num = parseFloat(numStr)

  return num
}

export function formatearAEntero(valorDecimal) {
  const valorEntero = Math.round(valorDecimal * 100)
  return valorEntero
}

export function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1])
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  var blob = new Blob([ab], { type: mimeString })

  return blob
}

export function stringToDate(_date, _format, _delimiter) {
  var formatLowerCase = _format?.toLowerCase()
  var formatItems = formatLowerCase?.split(_delimiter)
  var dateItems = _date?.split(_delimiter)
  var monthIndex = formatItems?.indexOf('mm')
  var dayIndex = formatItems?.indexOf('dd')
  var yearIndex = formatItems?.indexOf('yyyy')
  var month = parseInt(dateItems[monthIndex])
  month -= 1
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex])
  return formatedDate
}

export function formatDateLang(date, lang, delimiter) {
  const d = new Date(date)
  let fecha = null
  if (lang === 'en') {
    fecha = `${d.getFullYear()}-${`00${d.getMonth() + 1}`.slice(-2)}-${`00${d.getDate()}`.slice(
      -2
    )}`
  }
  if (lang === 'es') {
    fecha = `${`00${d.getDate()}`.slice(-2)}-${`00${d.getMonth() + 1}`.slice(
      -2
    )}-${d.getFullYear()}`
  }
  if (lang === 'es-en') {
    const f = date.split(delimiter)
    const fIngles = `${`${Number(f[2])}`}-${`00${Number(f[1])}`.slice(-2)}-${`00${Number(f[0]) + 1
      }`.slice(-2)}`
    fecha = new Date(fIngles)
  }
  return fecha
}

const styleClass = (base) => ({
  ...base,
  border: 'solid 1px #fd397a !important',
  boxShadow: 'none',
  borderRadius: '5px',
})

const styleClass2 = (base) => ({
  ...base,
  border: 'solid 1px #fd397a !important',
  boxShadow: 'none',
  borderRadius: '5px',
  zIndex: 9999,
})

export const toDataURL = (url, callback) => {
  var xhr = new XMLHttpRequest()
  xhr.onload = function () {
    var reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export const customStyles = {
  control: styleClass,
}
export const customStyles2 = {
  control: styleClass2,
}
export const meses = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
]

export const validUrl = [
  '/dashboard',
  '/libros-formas/cooperativa/',
  '/libros-formas/catalogos/GestionFormatos',
  '/public/cooperativa',
]

export const dataUrlToFile = async (dataUrl, fileName) => {
  const res = await fetch(dataUrl)
  const blob = await res.blob()
  const file = new File([blob], fileName + '.pdf', { type: 'application/pdf' })
  return file
}

export function returnMonth(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1)

  if (month.length < 2) month = '0' + month

  return month + ''
}

export function returnYear(date) {
  var d = new Date(date),
    year = d.getFullYear()

  return year + ''
}

export function quitarAcentos(cadena) {
  if (typeof cadena !== 'string') {
    // manejar el caso en que cadena no es una cadena
    // por ejemplo, podrías devolver la cadena sin modificar
    return cadena
  }

  const acentos = { á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u', Á: 'A', É: 'E', Í: 'I', Ó: 'O', Ú: 'U' }
  return cadena.normalize('NFD').replace(/[\u0300-\u036f]/g, function (c) {
    return acentos[c] || c
  })
}

export function wrapText(doc, text, x, y, maxWidth, lineHeight) {
  var words = text.split(' ')
  var line = ''

  for (var i = 0; i < words.length; i++) {
    var testLine = line + words[i] + ' '
    var textSize = doc.getTextWidth(testLine)

    if (textSize > maxWidth && i > 0) {
      doc.text(line, x, y)
      line = words[i] + ' '
      y += lineHeight
    } else {
      line = testLine
    }
  }

  doc.text(line, x, y, { align: 'justify' })
}

export function wrapHeight(doc, text, x, y, maxWidth, lineHeight) {
  var words = text.split(' ')
  var line = ''
  var totalHeight = 0

  for (var i = 0; i < words.length; i++) {
    var testLine = line + words[i] + ' '
    var textSize = doc.getTextWidth(testLine)

    if (textSize > maxWidth && i > 0) {
      line = words[i] + ' '
      y += lineHeight
      totalHeight += lineHeight
    } else {
      line = testLine
    }
  }
  totalHeight += lineHeight

  return totalHeight
}

export function capitalizarPrimeraLetra(texto) {
  return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase()
}

export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
}

export function wrapTextCenter(doc, text, x, y, maxWidth, lineHeight) {
  var words = text.split(' ');
  var line = '';

  for (var i = 0; i < words.length; i++) {
    var testLine = line + words[i] + ' ';
    var textSize = doc.getTextWidth(testLine);

    if (textSize > maxWidth && i > 0) {
      // Centrar la línea antes de añadirla
      const lineX = x + (maxWidth - doc.getTextWidth(line)) / 2;
      doc.text(line, lineX, y);
      line = words[i] + ' ';
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  // Asegúrate de centrar también la última línea del texto
  const lineX = x + (maxWidth - doc.getTextWidth(line)) / 2;
  doc.text(line, lineX, y);
}

export function organizarFechasPorMes(fecha_str) {
  const meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const fechas = fecha_str.split(',').map(fecha => new Date(fecha.split('/').reverse().join('-')));
  const fechasPorMes = {};

  fechas.forEach(fecha => {
    const mes = fecha.getMonth();
    const año = fecha.getFullYear();
    const clave = `${meses[mes]} ${año}`;

    if (!fechasPorMes[clave]) {
      fechasPorMes[clave] = [];
    }
    fechasPorMes[clave].push(fecha.getDate());
  });

  return Object.entries(fechasPorMes).map(([clave, dias]) => {
    dias.sort((a, b) => a - b);
    return `${dias.join(',')} de ${clave}`;
  });
}

export function ObtenerFechaActual() {
  const hoy = new Date()
  return `${('00' + hoy.getDate()).slice(
    -2
  )} de ${meses[hoy.getMonth() + 1]} de ${hoy.getFullYear()}`
}

export const ResponseData = (data, type) => {
  let response = [];
  switch (type) {
    case "all":
      response = Array.isArray(data?.data) ? data.data : [];
      break;
    case "one":
      response = Array.isArray(data?.data) ? data.data[0] : [];
      break;
    default:
      break;
  }
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  toDataURL,
  formatDate,
  formatDate2,
  formatDate3,
  formatQuetzal,
  ObtenerFechaActual,
  formatDateHours,
  formatDateLang,
  stringToDate,
  dataURItoBlob,
  customStyles,
  customStyles2,
  dataUrlToFile,
  quitarAcentos,
  formatDate4,
  meses,
  validUrl,
  numeroALetras,
  formatDateHours2,
  revertirFormatoQuetzal,
  formatearAEntero,
  formatearNumero,
  revertirFormatoNumero,
  capitalizarPrimeraLetra,
  wrapText,
  formatDate5,
  wrapHeight,
  toTitleCase,
  wrapTextCenter,
  organizarFechasPorMes,
  ResponseData
}
