/* eslint-disable import/no-anonymous-default-export */
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const handleSwtAlert = (data, nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción ${data.idEstado === 1 ? 'desactivará' : 'activará'} este registro`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction(data)
      Swal.fire(
        `${data.idEstado === 1 ? 'Desactivado' : 'Activado'}!`,
        `El registro ha sido ${data.idEstado === 1 ? 'desactivado' : 'activado'} exitosamente.`,
        'success'
      )
    }
  })
}
export const handleSwtAlertSegNombramiento = (data, nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción anular este nombramiento`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction(data)
      Swal.fire(`Anulado!`, `El registro ha sido anulado exitosamente.`, 'success')
    }
  })
}

export const handleAll = (nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción se llevara acabo`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction()
      Swal.fire(`Completado !`, 'El Proceso Se Realizo Con Éxito!')
    }
  })
}

export const handleErrorCapa = () => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'No puede agregar unidades porque ya decidió que esta capacitación sea para el personal!',
  })
}

export const handleErrorCapa1 = () => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'No puede agregar personas porque ya decidió que esta capacitación sea para las unidades!',
  })
}

export const handleErrorLabReg = () => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'No se pudo registrar, por favor intente nuevamente!',
  }).then((result) => {
    if (result.isConfirmed) {
      window.open('about:blank', '_self')
      window.close()
    }
  })
}

export const handleSuccessLabReg = (nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    icon: 'success',
    title: 'Registrado!',
    text: 'Se ha registrado con éxito',
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction()
    }
  })
}

export const handleSuccessLabReg2 = (nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    icon: 'success',
    title: 'Examen Registrado!',
    text: 'Se ha registrado su examen, por favor prosiga para saber su calificación',
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction()
    }
  })
}

export const handleError = (message) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    icon: 'warning',
    title: 'Oops...',
    text: `${message}`,
  }).then((result) => {
    if (result.isConfirmed) {
      window.close()
    }
  })
}

export const handleErrorToken = () => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'El enlace al que intenta acceder ya no esta disponible o no existe!',
  }).then((result) => {
    if (result.isConfirmed) {
      window.close()
    }
  })
}

export const confirmAutorizarRechazar = (data, nameFunction, estado) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción procesará este registro`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction(data, estado)
      Swal.fire(
        `${estado === 2 ? 'Autorizará' : 'Rechazara'}!`,
        `El registro ha sido ${estado === 2 ? 'desactivado' : 'activado'} exitosamente.`,
        'success'
      )
    }
  })
}

export const handleSwtAlertRecepcion = (data, nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción ${data.idEstado === 1 ? 'Retornara' : 'Pondrá En transito'} este registro`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction(data)
      Swal.fire(
        `${data.idEstado === 1 ? 'Retornado' : 'Puesto en transito'}!`,
        `El registro ha sido ${
          data.idEstado === 1 ? 'Retornado' : 'Puesto en transito'
        } exitosamente.`,
        'success'
      )
    }
  })
}
export const handleSwtAlertPublicar = (data, nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción ${
      data.idEstadoPublicacion === 1 ? 'Retira' : 'Publicará'
    } esta micro cápsula`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction(data)
      Swal.fire(
        `${data.idEstadoPublicacion === 1 ? 'Ya no es publica' : 'Ya es publica'}!`,
        `La micro capsula ha sido ${
          data.idEstadoPublicacion === 0 ? 'Publicada' : 'Retirada del Público'
        } exitosamente.`,
        'success'
      )
    }
  })
}

export const confirmAutorizarRechazarDespachoInspeccion = (data, nameFunction, estado) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción ${estado === 2 ? 'Autorizará' : 'Rechazara'} este registro`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction(data, estado)
      Swal.fire(
        `${estado === 2 ? 'Autorizará' : 'Rechazara'}!`,
        `El registro ha sido ${estado === 0 ? 'desactivado' : 'activado'} exitosamente.`,
        'success'
      )
    }
  })
}

export const completaTrabajoCampoGabinete = (nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción actualizará esta Fiscalización`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction()
      Swal.fire(
        `Actualizado!`,
        `Se completo el trabajo de campo y gabinete exitosamente.`,
        'success'
      )
    }
  })
}
export const CorreccionViaticos = (nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction()
      Swal.fire(`Completado !`, `El registro se mando a corrección de forma exitosa`, 'success')
    }
  })
}
export default {
  handleSwtAlert,
  confirmAutorizarRechazar,
  handleSwtAlertRecepcion,
  handleSwtAlertPublicar,
  completaTrabajoCampoGabinete,
  CorreccionViaticos,
  handleError,
}
