import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Operar = lazy(() => import('../../../../views/OperadorRegional/OperadorMixto/index'))

const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/operar-mixto/solicitud-requerimiento`} component={Operar} />
      <Route path={`${props.match.path}/operar-mixto/solicitud-presupuesto`} component={Operar} />
      <Route path={`${props.match.path}/operar-mixto/solicitud-aprobadas`} component={Operar} />
      <Route path={`${props.match.path}/operar-mixto/operadas-requerimiento`} component={Operar} />
      <Route path={`${props.match.path}/operar-mixto/operadas-presupuesto`} component={Operar} />
      <Route path={`${props.match.path}/operar-mixto/autorizadas-aprobadas`} component={Operar} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
