/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'

const Demanda = () => {
  return (
    <AsideMenuItemWithSub
      to='/juridico/tipo-demanda'
      title={'Demanda'}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
      <AsideMenuItem 
       to='/juridico/tipo-demanda' 
       title={'Tipo demanda'}  
       icon='/media/icons/duotune/general/gen022.svg' />

      <AsideMenuItem 
       to='/juridico/demanda' 
       title={'Procesos Legales'} 
       icon='/media/icons/duotune/general/gen022.svg' />

       <AsideMenuItem 
       to='/juridico/calendario-demanda' 
       title={'Calendario Procesos Legales'} 
       biIcon='bi bi-calendar' />

    </AsideMenuItemWithSub>
  )
}

export default Demanda
