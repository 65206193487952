import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const RequermientoTraslado = lazy(() => import('../../../views/RequerimientoTraslado/index'))

const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`/rtraslado/requerimientot`} component={RequermientoTraslado} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
