import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Manuales = lazy(() => import('../../../views/Planificacion/ManualesInformes')),
  DetalleManuales = lazy(
    () => import('../../../views/Planificacion/ManualesInformes/DetalleManual')
  ),
  Programa = lazy(() => import('../../../views/Planificacion/Programa')),
  Actividades = lazy(() => import('../../../views/Planificacion/Actividad')),
  Producto = lazy(() => import('../../../views/Planificacion/Producto')),
  SubProducto = lazy(() => import('../../../views/Planificacion/SubProducto')),
  UnidadMedida = lazy(() => import('../../../views/Planificacion/UnidadMedida')),
  Meta = lazy(() => import('../../../views/Planificacion/Metas')),
  AvanceMetas = lazy(() => import('../../../views/Planificacion/Metas/AvanceMetas')),
  InformeSeciones = lazy(() => import('../../../views/Planificacion/SeccionesInforme')),
  TipoInforme = lazy(() => import('../../../views/Planificacion/TipoInforme')),
  SeccionOficios = lazy(() => import('../../../views/Planificacion/Oficios/index')),
  Fiscalizacion = lazy(() => import('../../../views/Planificacion/Fiscalizacion/ReporteMetas/index')),
  Vigilancia = lazy(() => import('../../../views/vigilanciaAlertaTemprana/PlanAnual/index')),
  Seguimiento = lazy(() => import('../../../views/tablero/Seguimiento/index')),
  Liquidacion = lazy(() => import('../../../views/Transportes2/Liquidacion/index')),
  AdministrarSolicitudesViaticos = lazy(() => import('../../../views/Viaticos/reporteViaticos/index')),
  PlanAnual = lazy(() => import('../../../views/Planificacion/Fiscalizacion/PlanAnual')),
  Vat = lazy(() => import('../../../views/tablero/VigilanciaAlerta/index')),
  Transportes = lazy(() => import('../../../views/tablero/Transporte/index')),
  Viaticos = lazy(() => import('../../../views/tablero/viaticos/index')),

  ControlManuales = lazy(() => import('../../../views/Planificacion/ControlManuales/index')),
  Cooperativas  = lazy(() => import('../../../views/Planificacion/Cooperativas/index')),
  Sede = lazy(() => import('../../../views/Planificacion/Sede/index'))

const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/manuales/:code`} component={DetalleManuales} />
      <Route path={`${props.match.path}/cambios-manual`} component={ControlManuales} />
      <Route path={`${props.match.path}/manuales`} component={Manuales} />
      <Route path={`${props.match.path}/programa`} component={Programa} />
      <Route path={`${props.match.path}/actividades`} component={Actividades} />
      <Route path={`${props.match.path}/producto`} component={Producto} />
      <Route path={`${props.match.path}/sub-producto`} component={SubProducto} />
      <Route path={`${props.match.path}/unidad-medida`} component={UnidadMedida} />
      <Route path={`${props.match.path}/tipo-seccion`} component={InformeSeciones} />
      <Route path={`${props.match.path}/meta/:code`} component={AvanceMetas} />
      <Route path={`${props.match.path}/meta`} component={Meta} />
      <Route path={`${props.match.path}/tipo-informe`} component={TipoInforme} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Route path={`${props.match.path}/reporte-de-metas`} component={Fiscalizacion} />
      <Route path={`${props.match.path}/seguimiento-de-metas`} component={Seguimiento} />
      <Route path={`${props.match.path}/vat-de-metas`} component={Vigilancia} />
      <Route path={`${props.match.path}/plan-anualfisca`} component={PlanAnual} />
      <Route path={`${props.match.path}/est-vat`} component={Vat} />
      <Route path={`${props.match.path}/est-transportes`} component={Transportes} />
      <Route path={`${props.match.path}/est-viaticos`} component={Viaticos} />
      <Route path={`${props.match.path}/liquidacion`} component={Liquidacion} />
      <Route path={`${props.match.path}/administrar-solicitudes-viaticos`} component={AdministrarSolicitudesViaticos} />
      <Route exact path={`${props.match.path}/cooperativa/:codigo`} component={Sede} />
      <Route path={`${props.match.path}/cooperativas`} component={Cooperativas} />


      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
