import {RouteBaseVFour,RouteBaseVSix} from './BaseUrlServiceLog'

const type = {
    vFour: {
      httpsUrl: RouteBaseVFour
    },
    vSix: {
      httpsUrl: RouteBaseVSix
    }
  };
  
  const CaptureServiceLog = async ( version) => {
    const data = type[version];
    try {
      const response = await fetch(data.httpsUrl);
      const service = response.text();
      return service;
    } catch (e) {
      return null;
    }
  };
  
  export function ServiceLogVFour() {
    return CaptureServiceLog("vFour");
  }
  
  export function ServiceLogVSix() {
    return CaptureServiceLog("vSix");
  }
