/* eslint-disable react/jsx-no-target-blank */

import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'

const Auditoria = (Props: any) => {


  return (
    <AsideMenuItemWithSub
      to='/auditoria'
      title={"Auditoria Interna"}
      featherIcon={<i className="las la-pen-alt fs-2"></i>}
    >
      <AsideMenuItemWithSub
        to='/catalogos'
        title={"Catálogos"}
        featherIcon={<i className="las la-list-ul fs-2"></i>}
      >
        <AsideMenuItem
          to='/auditoria/catalogos/secciones-informe'
          title={"Secciones de Informe"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/auditoria/catalogos/tipo-informe'
          title={"Tipo Informe"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/auditoria/nombramiento'
        title={"Nombramiento"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      {/* <AsideMenuItem
        to='/auditoria/requerimiento'
        title={"Nuevo Requerimiento"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      <AsideMenuItem
        to='/auditoria/informes'
        title={"Informes"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
       {/* <AsideMenuItem
          to='/auditoria/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
      />  */}
    </AsideMenuItemWithSub>
  )
}

export default Auditoria