import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const Deposito = lazy(() => import('../../../views/Caja/deposito/index')),
  BaseActualizada = lazy(() => import('../../../views/Caja/baseActualizada/index')),
  MetodoPago = lazy(() => import('../../../views/Caja/Catalogos/metodoPago/index')),
  TipoCobro = lazy(() => import('../../../views/Caja/Catalogos/tipoCobro/index')),
  Plazo = lazy(() => import('../../../views/Caja/Catalogos/plazo/index')),
  TipoEnvio = lazy(() => import('../../../views/Caja/Catalogos/tipoEnvio/index')),
  TipoMulta = lazy(() => import('../../../views/Caja/Catalogos/tipoMulta/index')),
  MultaIncumplimiento = lazy(() => import('../../../views/Caja/MultaIncumplimiento/index')),
  Formato632A2 = lazy(() => import('../../../views/Caja/Formato632A2/index')),
  OficioCuentas = lazy(() => import('../../../views/Caja/oficioCuentas/index')),
  CargaHistorica = lazy(() => import('../../../views/Caja/CargaHistorica/index')),
  IntegracionContabilidad = lazy(() => import('../../../views/Caja/FormatoIntegracionContabilidad/index')),
  AporteGobiernoCentral = lazy(() => import('../../../views/Caja/FormatoGobiernoCentral/index')),
  Fechas = lazy(() => import('../../../views/Caja/Fechas/index')),
  ModalidadCompra = lazy(() => import('../../../views/Compras/ProcesoDeEventos/index')),
  SeccionOficios = lazy(() => import('../../../views/Caja/Oficios/index'))


const Index = (props: any) => {

  return (
    <Switch>
      <Route path={`${props.match.path}/formato-63-A2`} component={Formato632A2} />
      <Route path={`${props.match.path}/formato-aporte-gobierno-central`} component={AporteGobiernoCentral} />
      <Route path={`${props.match.path}/carga-historica`} component={CargaHistorica} />
      <Route path={`${props.match.path}/formato-integracion-contabilidad`} component={IntegracionContabilidad} />
      <Route path={`${props.match.path}/fechas-corto-plazo`} component={Fechas} />
      <Route path={`${props.match.path}/modalidad-compra`} component={ModalidadCompra} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      {/* <Route path={`${props.match.path}/depositos`} component={Deposito} />
      <Route path={`${props.match.path}/base-actualizada`} component={BaseActualizada} />
      <Route path={`${props.match.path}/multa-incumplimiento`} component={MultaIncumplimiento} />
      <Route path={`${props.match.path}/catalogos/metodo-pago`} component={MetodoPago} />
      <Route path={`${props.match.path}/catalogos/tipo-cobro`} component={TipoCobro} />
      <Route path={`${props.match.path}/catalogos/plazo`} component={Plazo} />
      <Route path={`${props.match.path}/catalogos/tipo-envio`} component={TipoEnvio} />
      <Route path={`${props.match.path}/catalogos/tipo-multa`} component={TipoMulta} /> */}
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
