/* eslint-disable react/jsx-no-target-blank */

import {useContext} from 'react'
import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'
import {UserContext} from '../../../../../../../app/utility/context/userContext'

const Operar = () => {
  const {Roles, userRoles} = useContext(UserContext)
  return (
    <AsideMenuItemWithSub
      to='/compras/operar'
      title={'Solicitud de Requerimientos'}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
      {userRoles.includes(Roles.op_reg) ? (
        <AsideMenuItem
          to='/compras/operar/solicitud-requerimiento'
          title={'Operador Compras'}
          biIcon='bi bi-file-earmark-person-fill'
        />
      ) : (
        ''
      )}
      {userRoles.includes(Roles.op_reg) ? (
        <AsideMenuItem
          to='/compras/operar/solicitud-presupuesto'
          title={'Operador Presupuesto'}
          biIcon='bi bi-currency-dollar'
        />
      ) : (
        ''
      )}
      {userRoles.includes(Roles.op_reg) ? (
        <AsideMenuItem
          to='/compras/operar/solicitud-aprobadas'
          title={'Operador Aprobadas'}
          biIcon='bi bi-truck'
        />
      ) : (
        ''
      )}
    </AsideMenuItemWithSub>
  )
}

export default Operar
