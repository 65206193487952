import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const TipoDemanda = lazy(() => import('../../../views/Juridico/TipoDemanda/index')),
  Demanda = lazy(() => import('../../../views/Juridico/Demanda/index')),
  // AudienciaDemanda = lazy(() => import('../../../views/Juridico/AudienciaDemanda/index')),
  HistorialPublicacion = lazy(() => import('../../../views/Juridico/HistorialPublicacion/index')),
  BitacoraContrato = lazy(() => import('../../../views/Juridico/BitacoraContrato/index')),
  CalendarioDemanda = lazy(() => import('../../../views/Juridico/Calendario')),
  SeccionOficios = lazy(() => import('../../../views/Juridico/Oficios/index'))

const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/tipo-demanda`} component={TipoDemanda} />
      <Route path={`${props.match.path}/demanda`} component={Demanda} />
      {/* <Route path={`${props.match.path}/audiencia-demanda`} component={AudienciaDemanda} /> */}
      <Route path={`${props.match.path}/historial-publicaciones`} component={HistorialPublicacion} />
      <Route path={`${props.match.path}/bitacora-contrato`} component={BitacoraContrato} />
      <Route path={`${props.match.path}/calendario-demanda`} component={CalendarioDemanda} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
