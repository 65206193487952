import {useState, useEffect, useContext} from 'react'
import {Row, Col, Form, Button} from 'react-bootstrap-v5'
import {ContentContext} from './context'
import Select from 'react-select'
import {handleErrorToken} from '../../../utility/sweetAlert'
import {useForm, Controller} from 'react-hook-form'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import CustomLabel from '../../../utility/customLabel'
import {Link} from 'react-router-dom'
import {customStyles} from '../../../utility/Globales/index'
import {UserContext} from '../../../utility/context/userContext'

const RegistroInterno = ({codigo}) => {
  const {userAuth} = useContext(UserContext)
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset,
  } = useForm()
  const {allData, labelPersonas, labelEdades, errorToken} = useContext(ContentContext)

  const onSubmit = (data: any) => {
    const filtrado = allData.find((reg) => reg.token === codigo)

    window.location.href = `/examen/${codigo}/${filtrado.idGrupoCapacitaciones}`
  }

  return (
    <>
      {errorToken ? (
        handleErrorToken()
      ) : (
        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'nowrap'}}>
          <div
            style={{
              background: '#FFD54F',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
            }}
          >
            <div className='d-flex justify-content-center'>
              <img src={toAbsoluteUrl('/media/logos/Logo.png')} alt='logo' />
            </div>
            <div className='d-flex justify-content-center'>
              <h1>{`Bienvenido al Registro de la capacitación ${codigo} !`}</h1>
            </div>
          </div>
          <div
            style={{
              background: '#E0E0E0',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
            }}
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className='d-flex justify-content-center mt-4'>
                <Col></Col>
                <Col className='col-sm-8'>
                  <Form.Group className='mb-3'>
                    <CustomLabel
                      required={true}
                      text={`Su persona es: ${userAuth.nombreCompleto}`}
                      type='danger'
                    />
                    <CustomLabel
                      required={true}
                      text={`Correo Electronico: ${userAuth.emailPersona}`}
                      type='danger'
                    />
                    <CustomLabel required={true} text={`Sede: ${userAuth.sede}`} type='danger' />
                    <CustomLabel
                      required={true}
                      text={`Area: ${userAuth.ubicacion}`}
                      type='danger'
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
              </div>

              <div className='d-flex justify-content-center mt-20'>
                <Button
                  className='btn btn-success btn-lg'
                  style={{width: '50%', marginBottom: '340px'}}
                  type='submit'
                >
                  Continuar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  )
}

export default RegistroInterno
