/* eslint-disable react/jsx-no-target-blank */

import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'
import Ejecucion from './Ejecucion/index'

const IT = () => {

  return (
    <AsideMenuItemWithSub
      to='/vigilancia-alerta-temprana'
      title="Vigilancia y Alerta Temp."
      biIcon="bi-camera-video"
    >
      {
      //  (userRoles.includes(Roles.j_seg || Roles.asist_seg) || userRoles.includes(Roles.an_seg|| Roles.agl_seg)) &&
        <Ejecucion />
      }
      { 
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/plananual' 
          title={"Planificación Anual"}
          biIcon='bi bi-calendar'
        />
        }
         { 
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/vigilanciaAlertaTemprana' 
          title={"Historico Vigilancia y Alerta"}
          biIcon='bi bi-list'
        />
        }
        { 
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/nombramiento' 
          title={"Nombramiento"}
          biIcon='bi bi-person-check-fill'
        />
        }
      <AsideMenuItem
        to='/vigilancia-alerta-temprana/cooperativas'
        title="Cooperativas"
        biIcon="bi-circle-fill"
      />
      <AsideMenuItemWithSub
        to='/catalogos'
        title={"Catálogos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/catalogos/herramienta'
          title={"Herramientas"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/catalogos/segmentador'
          title={"Segmentadores para Cooperativa"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/catalogos/nivel'
          title={"Niveles para Cooperativa"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/catalogos/tipo-nombramiento'
          title={"Tipo de Nombramiento"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/catalogos/color'
          title={"Segmento Color"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />

      </AsideMenuItemWithSub>

      {/* <AsideMenuItem
          to='/vigilancia-alerta-temprana/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/revision-autorizacion-informes'
          title='Revisión y Autorización de Informes'
          icon='/media/icons/duotune/general/gen022.svg'
        /> 
         <AsideMenuItem
          to='/vigilancia-alerta-temprana/informe-analista'
          title='Informes de Analista'
          icon='/media/icons/duotune/general/gen022.svg'
        /> 

        <AsideMenuItem
          to='/vigilancia-alerta-temprana/reporte-segmentacion'
          title='Reporte Segmentacion'
          icon='/media/icons/duotune/general/gen022.svg'
        /> 
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/reporte-financiero'
          title='Reporte Financiero'
          icon='/media/icons/duotune/general/gen022.svg'
        /> 

         <AsideMenuItem
          to='/vigilancia-alerta-temprana/ListaMemoriasAlertas'
          title='Lista Memorias Alertas'
          icon='/media/icons/duotune/general/gen022.svg'
        /> 
    </AsideMenuItemWithSub>
  )
}

export default IT