/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'
import { useContext } from 'react'
import { UserContext } from '../../../../../../../app/utility/context/userContext'

const Expendiente = () => {
  
  const { Roles, userRoles } = useContext(UserContext)

  return (    

        <AsideMenuItemWithSub
          to='/seguimiento/expediente'
          title={"Expendiente"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          {
           ((userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ) || userRoles.includes(Roles.sec_seg)  ) && 
          <AsideMenuItem
            to='/seguimiento/expediente/nombramiento' 
            title={"Nombramiento"}
            biIcon='bi bi-person-check-fill'
          />}
          { 
           ((userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ) || userRoles.includes(Roles.sec_seg)  ) &&
          <AsideMenuItem
            to='/seguimiento/expediente/inventario'
            title={"Inventario Expedientes Codificados"}
            biIcon='bi-card-list'
          />}
          

          {
          ((userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ) || userRoles.includes(Roles.sec_seg)  ) &&
          <AsideMenuItem
            to='/seguimiento/expediente/visorgeneral'
            title={"Inventario General"}
            biIcon='bi-card-checklist'
          />}
          
          {
           userRoles.includes(Roles.sec_seg) && 
          <AsideMenuItem
            to='/seguimiento/expediente/codificacion'
            title={"Codificacion de Expedientes"}
            biIcon='bi-file-earmark-check'
          />}
        </AsideMenuItemWithSub>
  )
}


export default Expendiente
