import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Nombramiento = lazy(() => import('../../../views/Auditoria/Nombramiento')),
  Requerimiento = lazy(() => import('../../../views/Auditoria/NuevoRequerimiento')),
  TipoInforme = lazy(() => import('../../../views/Auditoria/Catalogos/TipoInforme')),
  SeccionesInforme = lazy(() => import('../../../views/Auditoria/Catalogos/SeccionesInforme')),
  Informes = lazy(() => import('../../../views/Auditoria/ManualesInformes')),
  DetalleInformes = lazy(() => import('../../../views/Auditoria/ManualesInformes/DetalleManual')),
  SeccionOficios = lazy(() => import('../../../views/Auditoria/Oficios/index'))


const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/nombramiento`} component={Nombramiento} />
      <Route path={`${props.match.path}/requerimiento`} component={Requerimiento} />
      <Route path={`${props.match.path}/catalogos/tipo-informe`} component={TipoInforme} />
      <Route path={`${props.match.path}/catalogos/secciones-informe`} component={SeccionesInforme} />
      <Route path={`${props.match.path}/informes/:code`} component={DetalleInformes} />
      <Route path={`${props.match.path}/informes`} component={Informes} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
