/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { PostRoute } from '../../../services/Private'
import { Notification } from '../../../utility/Notify/index'
import clsx from 'clsx'
import { Form } from "react-bootstrap-v5"

const ChangePass: React.FC = () => {
  const [dataStorage, setDataStorage] = useState<any>([])
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [validInput] = useState<boolean>(false)
  const logout = () => {
    localStorage.removeItem('authUserI');
    // history.push('/auth');
    window.location.href = "/auth";
  },


    onSubmit = (data: any) => {
      let jsData = {
        password0: data.uno + data.dos + data.tres + data.cuatro + data.cinco + data.seis,
        password: data.password,
      }
      submitForm(jsData)
    },

    submitForm = async (data: any) => {
      const response = await PostRoute(`usuario/cambiarpass`, {
        id: JSON.parse(dataStorage).i,
        password0: data.password0,
        password: data.password
      });
      Notification(response.message, 1)
      setTimeout(() => { logout() }, 5000);

    },
    handleEnter = (event: any) => {
      if (event.which === 96 || event.which === 97 || event.which === 98 || event.which === 99 || event.which === 100 || event.which === 101 || event.which === 102 || event.which === 103 || event.which === 104 || event.which === 105) {
        const form = event.target.form;
        const index = [...form].indexOf(event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    };
  useEffect(
    () => {
      setDataStorage(localStorage.getItem('authUserI'))

    }, [])

  return (
    <div className="d-flex flex-column flex-root">

      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"  >

        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">



          <div className="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">

            <form className="form w-100 mb-10" id="kt_sing_in_two_steps_form" onSubmit={handleSubmit(onSubmit)}>



              <div className="text-center mb-10">

                <h1 className="text-dark mb-3">Cambio de Contraseña</h1>

                <div className="text-muted fw-bold fs-5 mb-5">Ingresa el PIN que se te envió a tu correo electrónico</div>

                {/* <div className="fw-bolder text-dark fs-3">******7859</div> */}

              </div>

              <div className="mb-10 px-md-10">

                <div className="fw-bolder text-start text-dark fs-6 mb-1 ms-1">Escribe los 6 caracteres del PIN de seguridad</div>

                <div className="d-flex flex-wrap flex-stack">
                  <input maxLength={1} id="1" type="text" data-inputmask="'mask': '9', 'placeholder': 0" placeholder='0' autoComplete="off"

                    onKeyPress={(event) => {
                      if (!/^[0-9]$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} className={
                      clsx(
                        'form-control text-center form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2',
                        {
                          'is-valid': validInput
                        },
                        {
                          'is-invalid': errors.uno && errors.uno.type === "required"
                        }
                      )
                    }
                    {...register('uno',
                      {
                        required: "Este campo es requerido",
                      }
                    )
                    }
                    onKeyUp={handleEnter}
                  />
                  <p className="text-danger fw-danger">{!!errors.uno && errors.uno.message}</p>
                  <input maxLength={1} type="text" data-inputmask="'mask': '9', 'placeholder': ''" placeholder="0" autoComplete="off" onKeyPress={(event) => {
                    if (!/^[0-9]$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} className={
                    clsx(
                      'form-control text-center form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2',
                      {
                        'is-valid': validInput
                      },
                      {
                        'is-invalid': errors.dos && errors.dos.type === "required"
                      }
                    )
                  }
                    {...register('dos',
                      {
                        required: "Este campo es requerido",
                      }
                    )
                    }
                    onKeyUp={handleEnter}
                  />
                  <p className="text-danger fw-danger">{!!errors.dos && errors.dos.message}</p>
                  <input maxLength={1} type="text" data-inputmask="'mask': '9', 'placeholder': ''" placeholder="0" autoComplete="off" onKeyPress={(event) => {
                    if (!/^[0-9]$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} className={
                    clsx(
                      'form-control text-center form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2',
                      {
                        'is-valid': validInput
                      },
                      {
                        'is-invalid': errors.tres && errors.tres.type === "required"
                      }
                    )
                  }
                    {...register('tres',
                      {
                        required: "Este campo es requerido",
                      }
                    )
                    }
                    onKeyUp={handleEnter}
                  />
                  <p className="text-danger fw-danger">{!!errors.tres && errors.tres.message}</p>
                  <input maxLength={1} type="text" data-inputmask="'mask': '9', 'placeholder': ''" placeholder="" autoComplete="off" onKeyPress={(event) => {
                    if (!/^[0-9]$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} className={
                    clsx(
                      'form-control text-center form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2',
                      {
                        'is-valid': validInput
                      },
                      {
                        'is-invalid': errors.cuatro && errors.cuatro.type === "required"
                      }
                    )
                  }
                    {...register('cuatro',
                      {
                        required: "Este campo es requerido",
                      }
                    )
                    }
                    onKeyUp={handleEnter}
                  />
                  <p className="text-danger fw-danger">{!!errors.cuatro && errors.cuatro.message}</p>
                  <input maxLength={1} type="text" data-inputmask="'mask': '9', 'placeholder': ''"
                    // className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                    placeholder="" autoComplete="off" onKeyPress={(event) => {
                      if (!/^[0-9]$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} className={
                      clsx(
                        'form-control text-center form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2',
                        {
                          'is-valid': validInput
                        },
                        {
                          'is-invalid': errors.cinco && errors.cinco.type === "required"
                        }
                      )
                    }
                    {...register('cinco',
                      {
                        required: "Este campo es requerido",
                      }
                    )
                    }
                    onKeyUp={handleEnter}
                  />
                  <p className="text-danger fw-danger">{!!errors.cinco && errors.cinco.message}</p>
                  <input
                    type="text"
                    maxLength={1}
                    autoComplete="none"
                    // className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" placeholder=""
                    onKeyPress={(event) => {
                      if (!/[0-9]$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className={
                      clsx(
                        'form-control text-center form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2',
                        {
                          'is-valid': validInput
                        },
                        {
                          'is-invalid': errors.seis && errors.seis.type === "required"
                        }
                      )
                    }
                    {...register('seis',
                      {
                        required: "Este campo es requerido",
                      }
                    )
                    }
                    onKeyUp={handleEnter}
                  />
                  <p className="text-danger fw-danger">{!!errors.seis && errors.seis.message}</p>
                </div>

                <Form.Label column >
                  Nueva Contraseña
                </Form.Label>
                <div className="form-group fv-plugins-icon-container mb-5">
                  <input
                    autoComplete="new-password"
                    type="password"
                    className={
                      clsx(
                        'form-control form-control-solid h-auto py-5 px-5',
                        {
                          'is-valid': validInput
                        },
                        {
                          'is-invalid': errors.password && errors.password.type === "required"
                        }
                      )
                    }
                    {...register("password",
                      {
                        required: "Este campo es requerido",
                      }
                    )
                    }
                  />
                  {errors.password && errors.password.type === "required" && (
                    <div className="fv-plugins-message-container text-danger text-center">
                      <div className="fv-help-block">
                        {errors.password.message}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex flex-center">
                <button type="submit" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder">
                  <span className="indicator-label">Cambiar</span>
                  <span className="indicator-progress">Cargando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </div>

            </form>

            {/* <div className="text-center fw-bold fs-5">
              <span className="text-muted me-1">Didn’t get the code ?</span>
              <a href="#" className="link-primary fw-bolder fs-5 me-1">Resend</a>
              <span className="text-muted me-1">or</span>
              <a href="#" className="link-primary fw-bolder fs-5">Call Us</a>
            </div> */}

          </div>

        </div>
      </div>
    </div>
  )
}

export { ChangePass }
