import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Bien = lazy(() => import('../../../views/InventarioFisico/Bien/index'))
const SeccionOficios = lazy(() => import('../../../views/InventarioFisico/Oficios/index'))


const Index = (props: any) =>{
  
  return (
    <Switch>
      <Route path={`${props.match.path}/bien`} component={Bien} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />

      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
