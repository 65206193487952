import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const MisNotificaciones = lazy(() => import('../../../views/MisNotificaciones/Notificaciones/index'))

const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/notificacion`} component={MisNotificaciones} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
