import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const TipoArchivo = lazy(() => import('../../../views/Archivo/TipoArchivo/index')), 
      TipoResguardo = lazy(() => import('../../../views/Archivo/TipoResguardo/index')),
      ControlBodega = lazy(() => import('../../../views/Archivo/ControlBodega/index')),
      Archivo = lazy(() => import('../../../views/Archivo/Archivo/index')),
      DespachoArchivo = lazy(() => import('../../../views/Archivo/DespachoArchivo/index')),
      BusquedaArchivo = lazy(()=> import('../../../views/Archivo/BusquedaArchivo/index')),
      RecepcionArchivo = lazy(()=> import('../../../views/Archivo/RecepcionArchivo/index')),
      SeccionOficios = lazy(()=> import('../../../views/Archivo/Oficios/index'))

const Index = (props: any) =>{
  return (
    <Switch>
     <Route path={`${props.match.path}/tipoArchivo`} component={TipoArchivo} />
     <Route path={`${props.match.path}/tipoResguardo`} component={TipoResguardo} />
     <Route path={`${props.match.path}/controlBodega`} component={ControlBodega} />
     <Route path={`${props.match.path}/archivo`} component={Archivo} />
     <Route path={`${props.match.path}/despachoArchivo`} component={DespachoArchivo} />
     <Route path={`${props.match.path}/busquedaArchivo`} component={BusquedaArchivo} />
     <Route path={`${props.match.path}/recepcionArchivo`} component={RecepcionArchivo} />
     <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
