
import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'
import Admin from './Admin/index'
import Operador from './Operador/index'
import Reportes from './Reportes/index'

const Almacen = (Props: any) => {

    return (
        <AsideMenuItemWithSub
            to='/almacen'
            title={"Almacén"}
            biIcon="bi-box-seam"
        >
            {
                <Admin />
            }

            {
                <Operador />
            }

            {
                <Reportes />
            }
            <AsideMenuItem
            to='/almacen/razonamiento-factura-compras'
            title="Razonamiento de Factura de Compras"
            biIcon="bi-file-earmark-text"
            />
            <AsideMenuItem
                  to='/almacen/modalidad-compra' 
                  title={"Modalidad de Compra"}
                  biIcon='bi-currency-exchange'
                />
             {/* <AsideMenuItem
                    to='/almacen/seccion-oficios-capacitacion'
                    title='Sección de Oficios con Capacitación'
                    icon='/media/icons/duotune/general/gen022.svg'
                />  */}

        </AsideMenuItemWithSub>
    )
}

export default Almacen