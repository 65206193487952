import Blowfish from 'javascript-blowfish'

const DataDecrpt = () => {
  // const dispatch = useDispatch()

  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY)
  const storage = localStorage.getItem('authUserI')
  let adecryptData =''
    if (storage) {
      const response = JSON.parse(storage)
      const Decript = bf.decrypt(bf.base64Decode(response.data))
      if (Decript) {
        adecryptData = JSON.parse(Decript.replace(/\0/g, ''))
        // dispatch(setAuthUser({estado: state[1], login: appLog[1]}))

      }
    } 
    return adecryptData

}

export default DataDecrpt
