/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'
import {UserContext} from '../../../../../../app/utility/context/userContext'
import {useContext} from 'react'
const IT = () => {
  const {userAuth} = useContext(UserContext)
  return (
    <AsideMenuItemWithSub to='/gestiones' title='Gestiones' biIcon='bi-ui-checks'>
      <AsideMenuItem
        to={'/catalogo-insumos'}
        title='Catálogo de Insumos'
        biIcon='bi-file-earmark-text-fill'
      />
      <AsideMenuItem
        to={'/gestiones/control-manuales'}
        title='Solicitud Cambio de Manuales'
        biIcon='bi-file-earmark-text-fill'
      />
      <AsideMenuItem
        to='/gestiones/solicitud-almacen'
        title='Requisición de Almacen'
        biIcon='bi-file-earmark-text-fill'
      />

      {/* <AsideMenuItem
        to='/gestiones/solicitud-compras/creadas'
        title='Solicitud de Compras'
        biIcon='bi-file-earmark-text-fill'
      /> */}
      <AsideMenuItem
        to='/gestiones/solicitudMixta-compras/creadas'
        title='Solicitud Mixta de Compras'
        biIcon='bi-file-earmark-text-fill'
      />

      <AsideMenuItem
        to='/gestiones/gestion-transporte2/solicitud-vehiculo'
        title='Solicitud de Transporte'
        biIcon='bi-file-earmark-text-fill'
      />

      <AsideMenuItem
        to='/gestiones/solicitud-tarjeta-responsabilidad'
        title='Tarjeta de Responsabilidad'
        biIcon='bi-file-earmark-text-fill'
      />

      <AsideMenuItem
        to='/gestiones/solicitud-traslados'
        title='Solicitud de Traslados'
        biIcon='bi-file-earmark-text-fill'
      />

      {/* <AsideMenuItem
        to='/gestiones/constancia-laboral'
        title='Solicitud de Constancia Laboral'
        biIcon='bi-file-earmark-text-fill'
      />

      <AsideMenuItem 
        to='/gestiones/constancia-vacaciones'
        title='Solicitud de Vacaciones'
        biIcon='bi-file-earmark-text-fill'
      /> */}

      {userAuth.r === '011' && (
        <>
        <AsideMenuItem
            to='/gestiones/constancia-laboral'
            title='Solicitud de Constancia Laboral'
            biIcon='bi-file-earmark-text-fill'
          />

          <AsideMenuItem 
            to='/gestiones/constancia-vacaciones'
            title='Solicitud de Vacaciones'
            biIcon='bi-file-earmark-text-fill'
          />      
        </>
      )}


      <AsideMenuItem
        to='/gestiones/reconocimiento-gastos-anticipo'
        title={userAuth.r !== '011' ? 'Tramites De Reconocimiento De Gastos' : 'Tramite Viáticos'}
        biIcon='bi-file-earmark-text-fill'
      />
    </AsideMenuItemWithSub>
  )
}

export default IT
