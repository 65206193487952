/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const Capacitaciones = () => {
  return (
    <AsideMenuItemWithSub
      to='/capacitaciones'
      title={'Capacitaciones'}
      featherIcon={<i className='fab fa-chromecast fs-3'></i>}
    >
      <AsideMenuItemWithSub
        to='/catalogos'
        title={'Catálogos'}
        featherIcon={<i className='las la-list-ul fs-2'></i>}
      >
        <AsideMenuItem
          to='/capacitaciones/categoria'
          title={'Categoría de Capacitaciones'}
          biIcon='bi bi-ui-radios'
        />
        <AsideMenuItem
          to='/capacitaciones/grupo-capacitaciones'
          title={'Grupo de Capacitaciones'}
          biIcon='bi bi-newspaper'
        />
        <AsideMenuItem
          to='/capacitaciones/tipo-participantes'
          title={'Tipo de Participantes'}
          biIcon='bi bi-person-badge-fill'
        />
        <AsideMenuItem
          to='/capacitaciones/tipo-capacitadores'
          title={'Tipo de Capacitadores'}
          biIcon='bi bi-person-workspace'
        />
        <AsideMenuItem
          to='/capacitaciones/tipo-edades'
          title={'Tipo de Edades'}
          biIcon='bi bi-calendar-date-fill'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/capacitaciones/calendario'
        title={'Calendario de Capacitaciones'}
        biIcon='bi bi-calendar-week'
      />
      {/* <AsideMenuItem
        to='/capacitaciones/oficios'
        title={'Sección de Oficios'}
        biIcon='bi bi-file-text-fill'
      /> */}
      {/* <AsideMenuItem
          to='/capacitaciones/diplomas'
          title={"Invitaciones y Diplomas"}
          biIcon="bi bi-award"
        /> */}
      <AsideMenuItemWithSub
        to='/clasificador-tematico-enfoque-genero'
        title={'Plantilla de Clasificador Temático Enfoque de Género'}
        biIcon='bi bi-file-earmark-medical-fill'
      >
        <AsideMenuItem
          to='/capacitaciones/enfoque-genero-capacitacion'
          title={'Por Capacitación'}
          biIcon='bi bi-bookmark-star-fill'
        />
        <AsideMenuItem
          to='/capacitaciones/enfoque-genero-mensual'
          title={'Por Mes'}
          biIcon='bi bi-calendar-month-fill'
        />
        <AsideMenuItem
          to='/capacitaciones/enfoque-genero-semestral'
          title={'Por Semestre'}
          biIcon='bi bi-calendar-range-fill'
        />
        <AsideMenuItem
          to='/capacitaciones/enfoque-genero-anual'
          title={'Por Año'}
          biIcon='bi bi-calendar2-check-fill'
        />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItem
          to='/capacitaciones/informes'
          title={"Informes"}
          biIcon="bi bi-list-columns"
        /> */}
      {/* <AsideMenuItem
          to='/capacitaciones/micro-capsulas'
          title={"Micro Capsulas"}
          biIcon="bi bi-grid-1x2"
        /> */}
        <AsideMenuItem
          to='/capacitaciones/metas'
          title={"Metas"}
          icon='/media/icons/duotune/general/gen022.svg'
        />
    </AsideMenuItemWithSub>
  )
}

export default Capacitaciones
