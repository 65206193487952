import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import * as Icon from 'react-feather'

export const ModalPdf = ({toggle, modal, titulo, pdf}) => {
  const [type, setType] = useState('')
  useEffect(() => {
    if (pdf?.length !== 0 ) {
      const tipo = pdf?.split(';')[0]
       setType(tipo?.split('/')[1])
    }
  }, [pdf])
  return (  
    <Modal
      show={modal}
      onHide={toggle}
      backdrop='static'
      centered
      keyboard={false}
      dialogClassName='modal-70w'
    >
      <Modal.Header closeButton>
        <span className='h1 fw-bolder'>{titulo && titulo}</span>
      </Modal.Header>
      <Modal.Body>
         <div className='d-flex flex-column'>
              <div className='d-flex justify-content-center'><img src={toAbsoluteUrl("/media/logos/Logo.png")}  alt="logo" /></div>
              <div className='d-flex justify-content-center'><h1>Enhorabuena, ahora puedes descargar tu diploma!</h1></div>
              <div className='d-flex justify-content-center'><h3>Felicitaciones!</h3></div>
         </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' size='sm' onClick={() => {toggle(false);  window.open('about:blank','_self')}}>
          <Icon.ArrowLeft size={16} /> {'Salir'}
        </Button>
        <a className='btn btn-danger btn-sm' download={`document.${type}`} href={`${pdf}`}>
          <Icon.Download size={16} /> {'Descargar'}
        </a>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalPdf
