import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Solicitud = lazy(() => import('../../../views/Compras/Solicitud/index'))
const SolicitudMixta = lazy(() => import('../../../views/Compras/SolicitudMixta/index'))
const Solvencia = lazy(() => import('../../../views/gestiones/inventarios/Solvencia/index'))
const Tarjeta = lazy(() => import('../../../views/gestiones/inventarios/TarjetaResponsabilidad/index'))
const Traslados = lazy(() => import('../../../views/gestiones/inventarios/Traslados/index'))
const Almacen = lazy(() => import('../../../views/gestiones/bodega/SolicitudDespacho/index'))
const Planificacion = lazy(() => import('../../../views/gestiones/planificacion/index'))
const ConstanciaLabores = lazy(() => import('../../../views/gestiones/rrhh/constanciaLabores/index'))
const ConstanciaVacaciones = lazy(() => import('../../../views/gestiones/rrhh/constanciaVacaciones/index'))
const SolicitarVehiculo = lazy(() => import('../../../views/gestiones/transporte/vehiculo/index'))
const SolicitarTransporte = lazy(() => import('../../../views/gestiones/transporte2/index'))
const GestionCupones = lazy(() => import('../../../views/gestiones/transporte/cupones/index'))
const LiquidacionCupones = lazy(() => import('../../../views/gestiones/transporte/Liquidacion/index'))
const ControlKilometraje = lazy(() => import('../../../views/gestiones/transporte/kilometraje/index'))
const ViaticoAnticipo = lazy(() => import('../../../views/gestiones/ViaticoAnticipo/index'))
const Viaticos = lazy(() => import('../../../views/gestiones/Viaticos/index'))
const ReconocimientoGastosAnticipo = lazy(() => import('../../../views/gestiones/ReconocimientoGastosAnticipo/index'))
const ControlManuales = lazy(() => import('../../../views/gestiones/ControlManuales/index'))

const Index = (props: any) =>{
  
  return (
    <Switch>
      <Route path={`${props.match.path}/control-manuales`} component={ControlManuales} />
      <Route path={`${props.match.path}/solicitud-transporte`} component={SolicitarVehiculo} />
      <Route path={`${props.match.path}/gestion-transporte2`} component={SolicitarTransporte} />
      <Route path={`${props.match.path}/solicitud-compras`} component={Solicitud} />
      <Route path={`${props.match.path}/solicitudMixta-compras`} component={SolicitudMixta} />
      <Route path={`${props.match.path}/liquidacion`} component={LiquidacionCupones} />
      <Route path={`${props.match.path}/solicitud-insumos`} component={Planificacion} />

      <Route path={`${props.match.path}/anticipo`} component={ViaticoAnticipo} />
      <Route path={`${props.match.path}/viaticos`} component={Viaticos} />

      <Route path={`${props.match.path}/solicitud-solvencia`} component={Solvencia} />
      <Route path={`${props.match.path}/solicitud-tarjeta-responsabilidad`} component={Tarjeta} />
      <Route path={`${props.match.path}/solicitud-traslados`} component={Traslados} />

      <Route path={`${props.match.path}/reconocimiento-gastos-anticipo`} component={ReconocimientoGastosAnticipo} />
      <Route path={`${props.match.path}/solicitud-almacen`} component={Almacen} />
      <Route path={`${props.match.path}/constancia-laboral`} component={ConstanciaLabores} />
      <Route path={`${props.match.path}/constancia-vacaciones`} component={ConstanciaVacaciones} />
      <Route path={`${props.match.path}/gestion-cupones`} component={GestionCupones} />
      <Route path={`${props.match.path}/control-kilometraje`} component={ControlKilometraje} />


      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
