/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext } from 'react'
import {Link} from 'react-router-dom'
import { UserContext } from '../../../../app/utility/context/userContext'
import { setAuthUser } from '../../../../app/utility/redux/actions/user';
import { useDispatch } from 'react-redux'
import { ServiceLogVSix, ServiceLogVFour } from "../../../../app/views/auth/GetServiceLog"
import { browserName, osName, deviceType, getUA, deviceDetect, isWindows } from 'react-device-detect'
import {PostRoute} from '../../../../app/services/Public';

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch();

  const { userAuth } = useContext(UserContext)
  // const dispatch = useDispatch()
  const logout = async () => {
    
    let serviceIp = ''
    const serviceFour = await ServiceLogVFour() || "";
    const serviceSix = await ServiceLogVSix() || "";

    if( serviceSix === ""  && serviceFour !=="" )
    {
      serviceIp=serviceFour
    }
    else if(serviceSix !== "" && serviceFour ==="")
    {
      serviceIp=serviceSix
    }
    else if(serviceSix !== "" && serviceFour !=="")
    {
      serviceIp=serviceSix
    }

    const divice =  deviceDetect(window.navigator.userAgent)
    
    // const loc :any|null = localStorage.getItem('authUserI') 

      PostRoute(`dv/out`, {
      "navegador": browserName,
      "sistema": osName,
      "descripcion": divice.userAgent === undefined ? getUA :divice.userAgent,
      "modelo": divice.model === undefined ? isWindows ?'PC-windows':deviceType ? deviceType:'PC-mac':divice.model,
      "version":divice.osVersion,
      "proveedor":divice.vendor  === undefined ? isWindows ?'PC-windows':'PC-mac':divice.vendor,
      "ip": serviceIp,
      "usuario":userAuth.username
      }
    )
    
    localStorage.removeItem('authUserI');
    dispatch(setAuthUser(null))
    window.location.href = "/auth";

  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={userAuth && userAuth.foto} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              { (userAuth) && userAuth.nombreCompleto }
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              { (userAuth) && userAuth.username }
            </a>
          </div>
        </div>
      </div>
    
        <div>
          <div className='separator my-2'></div>
            <div className='menu-item px-5'>
              <Link to={'/perfil'} className='menu-link px-5'>
              {"Mi Perfil"}

              </Link>
            </div>
          {/* <div className='separator my-2'></div> */}
        </div>


      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {"Cerrar Sesión"}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
