import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Admin = lazy(() => import('./admin/index')),
      Operador = lazy(() => import('./operador/index')),
      Reportes = lazy(() => import('./reportes/index')),
      Razonamiento = lazy(() => import('../../../views/almacen/Razonamiento/index')),
      ModalidadCompra = lazy(() => import('../../../views/Compras/ProcesoDeEventos/index')),
      SeccionOficios = lazy(() => import('../../../views/almacen/Oficios/index'))

const Index = (props: any) =>{

  return (
    <Switch>
      <Route path={`${props.match.path}/admin`} component={Admin} />
      <Route path={`${props.match.path}/operador`} component={Operador} />
      <Route path={`${props.match.path}/reportes`} component={Reportes} />
      <Route path={`${props.match.path}/razonamiento-factura-compras`} component={Razonamiento} />
      <Route path={`${props.match.path}/modalidad-compra`} component={ModalidadCompra} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
