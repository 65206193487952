/* eslint-disable react/jsx-no-target-blank */

import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'

const IT = () => {

  return (
    <AsideMenuItemWithSub
      to='/inventario-fisico'
      title="Inventario Fijos"
      biIcon="bi-clipboard-plus"
    >

      <AsideMenuItem
        to='/inventario-fisico/bien'
        title="Bien"
        biIcon="bi-circle-fill"
      />

      {/* <AsideMenuItem
        to='/inventario-fisico/seccion-oficios-capacitacion'
        title='Sección de Oficios con Capacitación'
        icon='/media/icons/duotune/general/gen022.svg'
      />  */}

    </AsideMenuItemWithSub>
  )
}

export default IT