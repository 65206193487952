/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'
import * as Icon from 'react-feather'
const IT = () => {
  return (
    <AsideMenuItemWithSub
      to='/libros-formas'
      title='Libros y Formas'
      featherIcon={<Icon.Book size={18} />}
    >
      {/* <AsideMenuItem
        to='/libros-formas/usuario-externo'
        title='Usuario Externo'
        biIcon='bi-person'
      /> */}
      <AsideMenuItem to='/libros-formas/cooperativa' title='Cooperativas' biIcon='bi-house-fill' />
      <AsideMenuItem
        to='/libros-formas/lbfexterno/gestion-formatos-cooperativas'
        title={'Gestion Formularios Usuario Externo'}
        fontIcon='bi-archive'
        biIcon='bi-person-fill'
      />
      <AsideMenuItem
        to='/libros-formas/fisca-nombramiento'
        title={'Nombramientos de Fiscalización'}
        fontIcon='bi-archive'
        biIcon='bi-person-fill'
      />
      <AsideMenuItem
        to='/libros-formas/control-inactivas'
        title={'Control de Inactivas, Memorias y PT '}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      />
      <AsideMenuItemWithSub
        to='/catalogos'
        title={'Catálogos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/libros-formas/clasificacion'
          title={'Clasificación'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/tipoautorizacion'
          title={'Tipo de Autorizacion'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/formas'
          title={'Libros o Formas'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/tipomanejo'
          title={'Tipo de Manejo'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/estadocooper'
          title={'Estado de Cooperativa'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/Cargo'
          title={'Cargo'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/tipocooperativa'
          title={'Tipo Cooperativas'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/tipobaja'
          title={'Tipo de Baja'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/claseasociacion'
          title={'Clase Asociacion'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/TipoDetalleSolvencia'
          title={'Tipo Detalle Solvencia'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/FormaEntrega'
          title={'Forma de Entrega'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />

        <AsideMenuItem
          to='/libros-formas/catalogos/tipoContador'
          title={'Tipo de Contador'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/tipoHoja'
          title={'Tipo de Hoja'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/tipocontabilidad'
          title={'Tipo de Contabilidad'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/federacion'
          title={'Federacion'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/catalogos/GestionFormatos'
          title={'Gestion Formularios'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />

        <AsideMenuItem
          to='/libros-formas/categoria'
          title='Categoría'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        {/* <AsideMenuItem
          to='/libros-formas/tipoapartado'
          title='Tipo de Apartado'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/libros-formas/tiposecciondocumento'
          title='Tipo de Seccion para Documento'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        /> */}

        <AsideMenuItem
          to='/libros-formas/actividad'
          title='Actividad'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/libros-formas/'
        title={'Archivo'}
        featherIcon={<i className='bi bi-archive fs-2'></i>}
      >
        <AsideMenuItem
          to='/libros-formas/despachoArchivo'
          title='Despacho Archivo'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/libros-formas/recepcionArchivo'
          title='Recepción Archivo'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/libros-formas/buzon'
          title='Buzon de Archivos'
          icon='/media/icons/duotune/general/gen022.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/libros-formas/autorizacion-corteFormas'
        title='Autorización Corte de Formas'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/libros-formas/meta'
        title={'Metas'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />

      {/* <AsideMenuItem
          to='/libros-formas/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}
    </AsideMenuItemWithSub>
  )
}

export default IT
