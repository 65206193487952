/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'

const Contrato = () => {
  return (
    <AsideMenuItemWithSub
    to='/juridico/bitacora-contrato'
      title={'Contratos'}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
        <AsideMenuItem
          to='/juridico/bitacora-contrato'
          title={"Bitacora Contrato"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />

    </AsideMenuItemWithSub>
  )
}

export default Contrato
