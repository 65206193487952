import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'


const Sede = lazy(() => import('../../../views/Public/Sede/index'))
const Capacitaciones = lazy(() => import('../../../views/Public/Capacitaciones/index'))

const Index = (props: any) =>{
  return (
    <Switch>
      <Route exact path={`${props.match.path}/cooperativa/:code`} component={Sede} />
      <Route exact path={`${props.match.path}/cooperativa`} component={Sede} />
      <Route exact path={`${props.match.path}/mis-capacitaciones/capa-coope`} component={Capacitaciones} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
