/* eslint-disable react-hooks/rules-of-hooks */
import useSWR from 'swr'
import {useContext } from 'react'
import useSWRImmutable from 'swr/immutable'
import { GetRoute, GetRouteSWR, PostRoute, PostRouteSWR, PostRouteFD } from '../../services/Private'
import { UserContext } from '../../utility/context/userContext'




export const useRequest = (path, method, form, config) => {
    const { userAuth } = useContext(UserContext)

    if (method === 'GETSEDE') {
        const { data, error } = useSWR(path, GetRoute, config);
            // Asegurarse de que 'dataArray.data' es un array
            const dataArray = data?.data || [];

            // Verificar si 'idSede' existe en al menos un objeto dentro del array 'dataArray'
            const idSedeExists = Array?.isArray(dataArray) && dataArray?.some(item => userAuth?.idSede === item?.idSede);

            // Si 'idSede' existe, entonces filtra los datos
            let sedeFilter = [];
            if (idSedeExists) {
            sedeFilter = dataArray?.filter(item => userAuth?.idSede === item?.idSede);
            }
            return {
            data: sedeFilter.length > 0 ? sedeFilter : [],  // Asigna 'sedeFilter' si 'idSede' existe, de lo contrario asigna 'dataArray'
            isLoading: !data,
            isError: error
            };
        }
    if (method === 'GET') {
        const { data, error } = useSWR(path, GetRoute, config);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'GET1000') {
        const { data, error } = useSWR(path, GetRoute, { refreshInterval: 5000 });
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }

    if (method === 'GETSWR') {
        let { data, error } = useSWR(path, GetRouteSWR, config);
        return {
            data: data?.data ?? [],
            response: data ?? [],
            isLoading: !data?.data,
            isError: error
        }
    }
    if (method === 'getInmutable') {
        let { data, error } = useSWRImmutable(path, GetRouteSWR);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'POST') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' || (Array.isArray(value) && !value.length));


        const { data, error } = useSWR(isValid === true ? [path, form] : null, PostRoute, config);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'POSTSWR') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' || (Array.isArray(value) && !value.length));

        // //console.log(isValid)
        let { data, error } = useSWR(isValid === true ? [path, form] : null, PostRouteSWR, config);
        return {
            data: data?.data ?? [],
            response: data ?? [],
            isLoading: !data?.data,
            isError: error
        }
    }
    if (method === 'postInmutable') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' || (Array.isArray(value) && !value.length));


        let { data, error } = useSWRImmutable(isValid === true ? [path, form] : null, PostRouteSWR);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'FORMDATA') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' || (Array.isArray(value) && !value.length));


        const { data, error } = useSWR(isValid === true ? [path, form] : null, PostRouteFD(), config);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
}