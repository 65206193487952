import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const CategoriaCapacitaciones = lazy(() => import('../../../views/Capacitaciones/CategoriaCapacitaciones')),
      TipoParticipante = lazy(() => import('../../../views/Capacitaciones/TipoParticipantes')),
      TipoCapacitadores = lazy(() => import('../../../views/Capacitaciones/TipoCapacitador')),
      TipoEdades = lazy(() => import('../../../views/Capacitaciones/TipoEdades')),
      GrupoCapacitaciones = lazy(() => import('../../../views/Capacitaciones/GrupoCapacitaciones')),
      Diplomas = lazy(() => import('../../../views/Capacitaciones/Diplomas')),
      Oficios =  lazy(() => import('../../../views/Capacitaciones/Oficios')),
      Informes = lazy(() => import('../../../views/Capacitaciones/Informes')),
      Calendario = lazy(() => import('../../../views/Capacitaciones/Calendario')),
      MicroCapsulas = lazy(() => import('../../../views/Capacitaciones/Microcapsulas')),
      ReporteGenero = lazy(() => import('../../../views/Capacitaciones/ReporteGenero')),
      ReporteGeneroMes = lazy(() => import('../../../views/Capacitaciones/ReporteGeneroMes')),
      ReporteGeneroSemestral = lazy(() => import('../../../views/Capacitaciones/ReporteGeneroSemestral')),
      ReporteGeneroAnual = lazy(() => import('../../../views/Capacitaciones/ReporteGeneroAnual')),
      DetalleMicroCapsulas = lazy(() => import('../../../views/Capacitaciones/Microcapsulas/DetalleMicroCapsulas')),
      Metas = lazy(() => import('../../../views/Capacitaciones/Metas/index'))


const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/calendario`} component={Calendario} />
      <Route path={`${props.match.path}/categoria`} component={CategoriaCapacitaciones} />
      <Route path={`${props.match.path}/grupo-capacitaciones`} component={GrupoCapacitaciones} />
      <Route path={`${props.match.path}/tipo-participantes`} component={TipoParticipante} />
      <Route path={`${props.match.path}/tipo-capacitadores`} component={TipoCapacitadores} />
      <Route path={`${props.match.path}/tipo-edades`} component={TipoEdades} />
      <Route path={`${props.match.path}/oficios`} component={Oficios} />
      <Route path={`${props.match.path}/diplomas`} component={Diplomas} />
      <Route path={`${props.match.path}/informes`} component={Informes} />
      <Route path={`${props.match.path}/enfoque-genero-capacitacion`} component={ReporteGenero} />
      <Route path={`${props.match.path}/enfoque-genero-mensual`} component={ReporteGeneroMes} />
      <Route path={`${props.match.path}/enfoque-genero-semestral`} component={ReporteGeneroSemestral} />
      <Route path={`${props.match.path}/enfoque-genero-anual`} component={ReporteGeneroAnual} />
      <Route path={`${props.match.path}/micro-capsulas/:code`} component={DetalleMicroCapsulas} />
      <Route path={`${props.match.path}/micro-capsulas`} component={MicroCapsulas} />
      <Route path={`${props.match.path}/metas`} component={Metas} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
