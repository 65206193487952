
// import { useContext } from 'react'
import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'
// import { UserContext } from '../../../../../../app/utility/context/userContext'

const Seguimiento = (Props: any) => {
  // const { Roles, userRoles } = useContext(UserContext)
  return (
    <AsideMenuItemWithSub
      to='/informacion-publica'
      title={"Información Pública"}
      biIcon=" bi-layout-text-window-reverse"
    >
      <AsideMenuItem
        to='/informacion-publica/info'
        title={"Catátalogo de Información Pública"}
        biIcon='bi-layout-sidebar'
      />
      <AsideMenuItem
        to='/informacion-publica/peticionInformacion'
        title={"Catátalogo de Solicitud Informacion"}
        biIcon='bi-layout-sidebar'
      />
      <AsideMenuItemWithSub
        to='/catalogos'
        title={"Catálogos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/informacion-publica/catalogos/tipo-gestion'
          title={"Tipo de Gestión"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/informacion-publica/catalogos/tipo-medio'
          title={"Tipo Medio"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItem
          to='/informacion-publica/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}
    </AsideMenuItemWithSub>
  )
}

export default Seguimiento