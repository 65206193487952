/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const Fiscalizacion = (props: any) => {
  return (
    <>
      {(props.userRoles.includes(props.Roles.jefe_fisc) ||
        props.userRoles.includes(props.Roles.sup_fisc) ||
        props.userRoles.includes(props.Roles.insp_fisc) ||
        props.userRoles.includes(props.Roles.jef_reg_fisc) ||
        props.userRoles.includes(props.Roles.insp_reg_fisc) ||
        props.userRoles.includes(props.Roles.sup_reg_fisc) ||
        props.userRoles.includes(props.Roles.sec_jef_fisc) ||
        props.userRoles.includes(props.Roles.sec_sup_fisc) ||
        props.userRoles.includes(props.Roles.sec_jef_reg_fisc) ||
        props.userRoles.includes(props.Roles.sec_sup_reg_fisc) ||
        props.userRoles.includes(props.Roles.user_ext_fisc)) && (
        <AsideMenuItemWithSub
          to='/fiscalizacion'
          title={'Fiscalización'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil024.svg'
        >
          {(props.userRoles.includes(props.Roles.jefe_fisc) ||
            props.userRoles.includes(props.Roles.jef_reg_fisc) ||
            props.userRoles.includes(props.Roles.sec_jef_fisc) ||
            props.userRoles.includes(props.Roles.sec_jef_reg_fisc)) && (
            <AsideMenuItemWithSub
              to='/planificación'
              title={'Planificación'}
              fontIcon='bi-archive'
              icon='/media/icons/duotune/general/gen022.svg'
            >
              <>
                <AsideMenuItem
                  to='/fiscalizacion/plan-anual'
                  title={'Plan Anual'}
                  fontIcon='bi-archive'
                  biIcon='bi-card-checklist'
                />
                <AsideMenuItem
                  to='/fiscalizacion/horas-efectivas'
                  title={'Horas Efectivas'}
                  fontIcon='bi-archive'
                  biIcon='bi-clock-history'
                />
              </>

              {(props.userRoles.includes(props.Roles.jefe_fisc) ||
                props.userRoles.includes(props.Roles.jef_reg_fisc) ||
                props.userRoles.includes(props.Roles.sec_jef_fisc) ||
                props.userRoles.includes(props.Roles.sec_jef_reg_fisc)) && (
                <AsideMenuItem
                  to='/fiscalizacion/nombramientos'
                  title={'Nombramientos'}
                  fontIcon='bi-archive'
                  biIcon='bi-person-check-fill'
                />
              )}
            </AsideMenuItemWithSub>
          )}
          {(props.userRoles.includes(props.Roles.jefe_fisc) ||
            props.userRoles.includes(props.Roles.jef_reg_fisc) ||
            props.userRoles.includes(props.Roles.sup_fisc) ||
            props.userRoles.includes(props.Roles.insp_fisc) ||
            props.userRoles.includes(props.Roles.insp_reg_fisc) ||
            props.userRoles.includes(props.Roles.sup_reg_fisc) ||
            props.userRoles.includes(props.Roles.sec_sup_fisc) ||
            props.userRoles.includes(props.Roles.sec_jef_reg_fisc) ||
            props.userRoles.includes(props.Roles.sec_sup_reg_fisc)) && (
            <>
              <AsideMenuItemWithSub
                to='/ejecucion'
                title={'Ejecución'}
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen022.svg'
              >
                <AsideMenuItem
                  to='/fiscalizacion/asignaciones'
                  title={'Asignaciones'}
                  fontIcon='bi-archive'
                  biIcon='bi-person-lines-fill'
                />

                <AsideMenuItem
                  to='/fiscalizacion/nomb-inspectores'
                  title={'Nombramientos'}
                  fontIcon='bi-archive'
                  biIcon='bi-person-lines-fill'
                />
              </AsideMenuItemWithSub>
            </>
          )}
          {(props.userRoles.includes(props.Roles.jefe_fisc) ||
            props.userRoles.includes(props.Roles.jef_reg_fisc) ||
            props.userRoles.includes(props.Roles.sec_jef_fisc) ||
            props.userRoles.includes(props.Roles.sec_jef_reg_fisc)) && (
            <>
              <AsideMenuItemWithSub
                to='/reportes'
                title={'Reportes'}
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen022.svg'
              >
                <AsideMenuItem
                  to='/fiscalizacion/reporte-de-metas'
                  title={'Reporte de Metas'}
                  fontIcon='bi-archive'
                  biIcon='bi-bar-chart-line-fill'
                />
                <AsideMenuItem
                  to='/fiscalizacion/reporte-por-genero'
                  title={'Reporte por género'}
                  fontIcon='bi-archive'
                  biIcon='bi-people-fill'
                />
                <AsideMenuItem
                  to='/fiscalizacion/reporte-general'
                  title={'Reporte General'}
                  fontIcon='bi-archive'
                  biIcon='bi-bar-chart-line-fill'
                />
              </AsideMenuItemWithSub>
            </>
          )}
          {(props.userRoles.includes(props.Roles.jefe_fisc) ||
            props.userRoles.includes(props.Roles.jef_reg_fisc)) && (
            <>
              <AsideMenuItemWithSub
                to='/catalogos'
                title={'Catálogos'}
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen022.svg'
              >
                <AsideMenuItem
                  to='/fiscalizacion/catalogos/ObservacionesRecomendaciones'
                  title={'Observaciones y Recomendaciones'}
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/files/fil016.svg'
                />
                <AsideMenuItem
                  to='/fiscalizacion/catalogos/secciones-informe'
                  title={'Secciones de Informe'}
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/files/fil016.svg'
                />
                <AsideMenuItem
                  to='/fiscalizacion/catalogos/tipo-informe'
                  title={'Tipo Informe'}
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/files/fil016.svg'
                />
                <AsideMenuItem
                  to='/fiscalizacion/catalogos/tipo-permiso'
                  title={'Tipo Permiso'}
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/files/fil016.svg'
                />
                <AsideMenuItem
                  to='/fiscalizacion/catalogos/tipo-fiscalizacion'
                  title={'Tipo Fiscalización'}
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/files/fil016.svg'
                />
                <AsideMenuItem
                  to='/fiscalizacion/catalogos/tipo-viatico'
                  title={'Tipo Viático'}
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/files/fil016.svg'
                />

              </AsideMenuItemWithSub>
              <AsideMenuItem
                to='/fiscalizacion/revision-autorizacion-informes'
                title='Revision Autorización Informes VAT'
                icon='/media/icons/duotune/general/gen022.svg'
              />
              <AsideMenuItem
                to='/fiscalizacion/informe-inspectores-revision'
                title='Revision Autorización Informes Inspectores'
                icon='/media/icons/duotune/general/gen022.svg'
              />
            </>
          )}

          <AsideMenuItemWithSub
            to='/adm_oficios'
            title={'Cuota De Fiscalización Y Sanciones'}
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen022.svg'
          >
            <AsideMenuItem
              to='/fiscalizacion/adm_oficios/oficios'
              title={'Oficios'}
              fontIcon='bi-archive'
              icon='/media/icons/duotune/files/fil016.svg'
            />
          </AsideMenuItemWithSub>
            <AsideMenuItem
              to='/fiscalizacion/corteFormas'
              title='Solicitar Corte de Formas'
              icon='/media/icons/duotune/general/gen022.svg'
            />
          {(props.userRoles.includes(props.Roles.jefe_fisc) ||
            props.userRoles.includes(props.Roles.jef_reg_fisc)) && (
            <>
              <AsideMenuItem
                to='/fiscalizacion/cooperativas'
                title='Cooperativas'
                icon='/media/icons/duotune/general/gen022.svg'
              />
              <AsideMenuItem
                to='/fiscalizacion/clasificacion-por-usuario'
                title={'Monitor de Fiscalización'}
                fontIcon='bi-archive'
                biIcon='bi-graph-up-arrow'
              />
              <AsideMenuItem
                to='/fiscalizacion/CedulaDeNotificacion'
                title={'Cedula De Notificacion'}
                fontIcon='bi-archive'
                biIcon='bi-graph-up-arrow'
              />
            </>
          )}
          <AsideMenuItem
            to='/fiscalizacion/metas'
            title={'Metas'}
            icon='/media/icons/duotune/general/gen022.svg'
          />
        </AsideMenuItemWithSub>
      )}
    </>
  )
}

export default Fiscalizacion
