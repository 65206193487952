/* eslint-disable react/jsx-no-target-blank */

import { AsideMenuItemWithSub } from '../../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../../AsideMenuItem'

const Operador = () => {
  

  return (
    <AsideMenuItemWithSub
      to='/almacen/operador'
      title={"Operador"}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
      <AsideMenuItem
        to='/almacen/operador/ingreso'
        title={"Ingreso"}

        biIcon='bi-file-earmark-text-fill'
      />

      <AsideMenuItem
        to='/almacen/operador/requisicion'
        title={"Requisición"}
        biIcon='bi-file-earmark-text'
      />
      <AsideMenuItem
        to='/almacen/operador/existencias'
        title={"Existencias"}
        biIcon='bi-file-earmark-text'
      />

    </AsideMenuItemWithSub>
  )
}

export default Operador