/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import Demanda from './Demanda/index'
import Publicaciones from './Publicaciones'
import Contrato from './Contrato'

const Juridico = () => {
  

  return (
      <AsideMenuItemWithSub
        to='/juridico'
        title= {"Jurídico"}
        featherIcon={<i className="bi bi-briefcase fs-2"></i>}
      >
        <Demanda/>
        <Publicaciones/>
        <Contrato/>
        {/* <AsideMenuItem
          to='/juridico/audiencia-demanda'
          title={"Audiencia Demanda"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        /> */}

        {/* <AsideMenuItem
          to='/juridico/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}

      </AsideMenuItemWithSub>
  )
}

export default Juridico