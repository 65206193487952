/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const FondosRotativos = () => {
  return (
    <AsideMenuItemWithSub to='/fondos-rotativos' title='Fondos Rotativos' biIcon='bi-book'>
      <AsideMenuItem to='/fondos-rotativos/libro-bancos' title='Libro Bancos' biIcon='bi-circle' />
    </AsideMenuItemWithSub>
  )
}

export default FondosRotativos
