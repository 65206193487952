import React, {createContext, useContext, useState} from 'react'
import {UserContext} from './userContext'
import {PostRoute} from '../../services/Private'
import {useRequest} from '../../utility/Hooks/useRequestSWR'
import {useSWRConfig} from 'swr'
import {Notification} from '../../utility/Notify/index'

export const NotificationsContext = createContext()

export const NotificationsProvider = ({children}) => {
  const {userAuth} = useContext(UserContext)
   
  let allNotificaciones = []
  // let numNotificaciones= []
  let misNotificaciones= []
  // const [notifications, setNotifications] = useState([]);
  // const {data: allNotificaciones} = useRequest(`NorificacionN/all`, 'GETSWR')
    const {data: numNotificaciones} = useRequest(`NorificacionN/num-notificaciones`, 'POSTSWR', {})
    const {data: enviadasNotificaciones} = useRequest(`NorificacionN/enviadas-notificaciones`, 'POSTSWR', {})
    const {data: recibidosNotificaciones} = useRequest(`NorificacionN/recibidos-notificaciones`, 'POSTSWR', {})
    const notificaciones = numNotificaciones
    
  // Filtrar para excluir el código de persona específico y luego sumar los valores de 'sinLeer'

  const sumaSinLeer = notificaciones
      .filter(notificacion => notificacion.value !== userAuth.id) // Excluir por código de persona
      .reduce((acumulado, notificacion) => acumulado + notificacion.cantidadMensajesSinLeer, 0); // Sumar 'sinLeer'
  
  const personasSinLeer = notificaciones
  .filter(notificacion => notificacion.value !== userAuth.id)
  


  //   ubicacion: userAuth.idUbicacion,
  // })
  // const {data: misNotificaciones} = useRequest(`NorificacionN/allNotificacion`, 'POSTSWR', {
  //   ubicacion: userAuth.idUbicacion,
  // })
  const AgregarNotificacion = async (title, message, paraJefe) => {
    const content = {
      titulo: title,
      mensaje: message,
      persona: paraJefe === true ? userAuth.idJefe : 0,
      visto: 0,
      ubicacion: userAuth.idUbicacion,
    }
    // await PostRoute(`NorificacionN/Create`, content)
    // await mutate(`NorificacionN/all`)
    // await mutate([`NorificacionN/num-notificaciones`, {ubicacion: userAuth.idUbicacion}])
    // Notification('se ha notificado con exito', 1)
    //console.log(content)
  }

  const AgregarNotificacion2 = async (title, message, paraJefe) => {
    const content = {
      titulo: title,
      mensaje: message,
      persona: paraJefe,
      visto: 0,
      ubicacion: userAuth.idUbicacion,
    }
    // await PostRoute(`NorificacionN/Create`, content)
    // await mutate(`NorificacionN/all`)
    // await mutate([`NorificacionN/num-notificaciones`, {ubicacion: userAuth.idUbicacion}])
    // Notification('se ha notificado con exito', 1)
    //console.log(content)
  }

  const AgregarNotificacionExterna = async (title, message, referenciaUnidad) => {
    // const response = await PostRoute(`JefeUbicacion/jefexreferencia`, {referencia: referenciaUnidad})
    // if(response.data !== null ) {
    //   const content = {
    //     titulo: title,
    //     mensaje: message,
    //     persona:  response.data[0].idJefe ,
    //     visto: 0,
    //     ubicacion: userAuth.idUbicacion,
    //   }
  
    //   await PostRoute(`NorificacionN/Create`, content)
    //   await mutate(`NorificacionN/all`)
    //   await mutate([`NorificacionN/num-notificaciones`, {ubicacion: userAuth.idUbicacion}])

    //   Notification('se ha notificado con exito', 1)

    // }
    // else
    // {
    //   Notification('Jefe no encontrado no se a podido notificar', 2)
    // }

  }

  const AgregarNotificacionDirecta = async (title, message, idJefe = 0, idUbicacion) => {
    // const content = {
    //   titulo: title,
    //   mensaje: message,
    //   persona: idJefe || 0,
    //   visto: 0,
    //   ubicacion: idUbicacion,
    // }
    // await PostRoute(`NorificacionN/Create`, content)
    // await mutate(`NorificacionN/all`)
    // await mutate(`NorificacionN/num-notificaciones`)
    // Notification('se ha notificado con exito', 1)
  }
  const [showNotificacion, setShowNotificacion] = useState(false)

  const toggleModalNotificacion = () => {
    // setShowNotificacion(!showNotificacion)
  }

  const NotificacionVista = async (notificationId) => {
    // await PostRoute(`NorificacionN/status`, {id: notificationId, estado: 1})
    // await mutate(`NorificacionN/all`)
    // await mutate([`NorificacionN/num-notificaciones`,{ubicacion: userAuth.idUbicacion}])
  }

  const 
    {mutate} = useSWRConfig(),
    
    [show, setShow] = useState(false),
    [oneData, setOneData] = useState([]),
    [allData, setAllData] = useState([]),
    [opcion, setOpcion] = useState(0),
    [tab, setTab] = useState(2),
    nameController = 'NorificacionN',
    nameFileExport = 'Reporte Rol',
    toggleModal = (data) => {
      setOpcion(data)
      if (data === 1) {
        setOneData([])
      }
      setShow(!show)
    },
    tabActive = (No) => {
      
      setTab(No)
    },
    {data: DataAll} = useRequest(`${nameController}/all`, 'GET'),
    
    {data: Personal} = useRequest(`${nameController}/personal-notificacion`, 'GETSWR'),
    One = async (data, opcion) => {
      const response = await PostRoute(`${nameController}/one`, {id: data.id})
      setOneData(response.data.length ? response.data[0] : [])
      toggleModal(opcion)
    },
    StoreUpdate = async (data) => {
      let response = []
      response = await PostRoute(`${nameController}/${!data.id ? 'create' : 'update'}`, data)
      Notification(response.message, 1)
      toggleModal(0)
      mutate(`${nameController}/all`)
      if (!data.id) {
        AgregarNotificacion('Rol Creado! ', ` Se ha creado un nuevo rol!`, false)
      }
    },
    Actions = async (persona) => {

      const response = await PostRoute(`${nameController}/status`, {
        persona: persona,
      })
      
      await mutate([`NorificacionN/num-notificaciones`,{}])
      await mutate([`NorificacionN/enviadas-notificaciones`,{}])
      Notification(response.message, 1)
    },
    values = {
      recibidosNotificaciones,
      enviadasNotificaciones,
      tab,
      tabActive,
      personasSinLeer,
      sumaSinLeer,
      toggleModalNotificacion,
    showNotificacion,
    // notifications,
    AgregarNotificacion,
    AgregarNotificacion2,
    AgregarNotificacionDirecta,
    NotificacionVista,
    allNotificaciones,
    numNotificaciones,
    misNotificaciones,
    AgregarNotificacionExterna,

    One,
    Actions,
    StoreUpdate,
    toggleModal,
    setOpcion,
    nameFileExport,
    allData,
    show,
    oneData,
    opcion,

    Personal
    }

  // useEffect(() => {
  //   if (DataAll && DataAll.value === 1) {
  //     setAllData(DataAll.data)
  //   }
  // }, [DataAll])

  return <NotificationsContext.Provider value={values}>{children}</NotificationsContext.Provider>
}

// export function NotificationsProvider({children}) {
//   const {userAuth} = useContext(UserContext)
//   const {mutate} = useSWRConfig()
//   let allNotificaciones = []
//   let numNotificaciones= []
//   let misNotificaciones= []
//   // const [notifications, setNotifications] = useState([]);
//   // const {data: allNotificaciones} = useRequest(`NorificacionN/all`, 'GETSWR')
//   // const {data: numNotificaciones} = useRequest(`NorificacionN/num-notificaciones`, 'POSTSWR', {
//   //   ubicacion: userAuth.idUbicacion,
//   // })
//   // const {data: misNotificaciones} = useRequest(`NorificacionN/allNotificacion`, 'POSTSWR', {
//   //   ubicacion: userAuth.idUbicacion,
//   // })
//   const AgregarNotificacion = async (title, message, paraJefe) => {
//     const content = {
//       titulo: title,
//       mensaje: message,
//       persona: paraJefe === true ? userAuth.idJefe : 0,
//       visto: 0,
//       ubicacion: userAuth.idUbicacion,
//     }
//     // await PostRoute(`NorificacionN/Create`, content)
//     // await mutate(`NorificacionN/all`)
//     // await mutate([`NorificacionN/num-notificaciones`, {ubicacion: userAuth.idUbicacion}])
//     // Notification('se ha notificado con exito', 1)
//     //console.log(content)
//   }

//   const AgregarNotificacion2 = async (title, message, paraJefe) => {
//     const content = {
//       titulo: title,
//       mensaje: message,
//       persona: paraJefe,
//       visto: 0,
//       ubicacion: userAuth.idUbicacion,
//     }
//     // await PostRoute(`NorificacionN/Create`, content)
//     // await mutate(`NorificacionN/all`)
//     // await mutate([`NorificacionN/num-notificaciones`, {ubicacion: userAuth.idUbicacion}])
//     // Notification('se ha notificado con exito', 1)
//     //console.log(content)
//   }

//   const AgregarNotificacionExterna = async (title, message, referenciaUnidad) => {
//     // const response = await PostRoute(`JefeUbicacion/jefexreferencia`, {referencia: referenciaUnidad})
//     // if(response.data !== null ) {
//     //   const content = {
//     //     titulo: title,
//     //     mensaje: message,
//     //     persona:  response.data[0].idJefe ,
//     //     visto: 0,
//     //     ubicacion: userAuth.idUbicacion,
//     //   }
  
//     //   await PostRoute(`NorificacionN/Create`, content)
//     //   await mutate(`NorificacionN/all`)
//     //   await mutate([`NorificacionN/num-notificaciones`, {ubicacion: userAuth.idUbicacion}])

//     //   Notification('se ha notificado con exito', 1)

//     // }
//     // else
//     // {
//     //   Notification('Jefe no encontrado no se a podido notificar', 2)
//     // }

//   }

//   const AgregarNotificacionDirecta = async (title, message, idJefe = 0, idUbicacion) => {
//     // const content = {
//     //   titulo: title,
//     //   mensaje: message,
//     //   persona: idJefe || 0,
//     //   visto: 0,
//     //   ubicacion: idUbicacion,
//     // }
//     // await PostRoute(`NorificacionN/Create`, content)
//     // await mutate(`NorificacionN/all`)
//     // await mutate(`NorificacionN/num-notificaciones`)
//     // Notification('se ha notificado con exito', 1)
//   }
//   const [showNotificacion, setShowNotificacion] = useState(false)

//   const toggleModalNotificacion = () => {
//     // setShowNotificacion(!showNotificacion)
//   }

//   const NotificacionVista = async (notificationId) => {
//     // await PostRoute(`NorificacionN/status`, {id: notificationId, estado: 1})
//     // await mutate(`NorificacionN/all`)
//     // await mutate([`NorificacionN/num-notificaciones`,{ubicacion: userAuth.idUbicacion}])
//   }
//   const values = {
//     toggleModalNotificacion,
//     showNotificacion,
//     // notifications,
//     AgregarNotificacion,
//     AgregarNotificacion2,
//     AgregarNotificacionDirecta,
//     NotificacionVista,
//     allNotificaciones,
//     numNotificaciones,
//     misNotificaciones,
//     AgregarNotificacionExterna,

//     One,
//     Actions,
//     StoreUpdate,
//     toggleModal,
//     setOpcion,
//     nameFileExport,
//     allData,
//     show,
//     oneData,
//     opcion,
//   }
//   return <NotificationsContext.Provider value={values}>{children}</NotificationsContext.Provider>
// }
