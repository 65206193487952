import { ContentProvider } from './context'
import Examen from './Examen'
import './style.css'

const Index = ({ codigo, examen }) => {
  return (
    <>
      <ContentProvider codigo={codigo} examen={examen}>
        <Examen codigo={codigo} examen={examen} />
      </ContentProvider>  
    </>
  )
}

export default Index