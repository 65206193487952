/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'

const Admin = () => {
  

  return (    

        <AsideMenuItemWithSub
          to='/almacen/admin'
          title="Admin"
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          <AsideMenuItem
            to='/almacen/admin/articulo'
            title="Artículo"
            biIcon='bi-basket-fill'
          />

          <AsideMenuItem
            to='/almacen/admin/unidad' 
            title="Unidad de Medida"
            biIcon='bi-rulers'
          />

          <AsideMenuItem
            to='/almacen/admin/presentacion'
            title="Presentación"
            
            biIcon='bi-square'
          />

          <AsideMenuItem
            to='/almacen/admin/renglon'
            title="Renglón"
            
            biIcon='bi-sort-numeric-down'
          />

          <AsideMenuItem
            to='/almacen/admin/insumo'
            title="Insumo"
            
            biIcon='bi-circle'
          />

          <AsideMenuItem
            to='/almacen/admin/proveedor'
            title="Proveedor"
            
            biIcon='bi-truck'
          />

          <AsideMenuItem
            to='/almacen/admin/dependencia'
            title="Dependencia"
            
            biIcon='bi-bezier'
          />

          <AsideMenuItem
            to='/almacen/admin/programa'
            title="Programa"
            
            biIcon='bi-circle-fill'
          />

        </AsideMenuItemWithSub>
  )
}


export default Admin