/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import { FC, useState, useContext, useEffect } from 'react'
import { Redirect, Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { UserContext } from '../../app/utility/context/userContext'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout } from '../modules/auth'
import AuthPage from '../views/auth/index'
import { ErrorsPage } from '../views/errors/ErrorsPage'
import { ChangePass } from '../views/auth/security/ChangePass'
import RegistroExternoIndex from '../views/Capacitaciones/RegistroExterno/index'
import RegistroInternoIndex from '../views/Capacitaciones/RegistroInterno/index'
import ExamenInternoIndex from '../views/Capacitaciones/Examen/index'
import FisrtLogin from '../views/Perfil/LoginFirst'

const Routes: FC = () => {

  const { userAuth, state, publicPath, storage, userRoles } = useContext(UserContext)

  const [logeado, setLogeado] = useState<boolean>(false)

  const user = storage ? userAuth : null;

  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (user !== null) {
      if (publicPath.includes(location.pathname)) {
        history.push("/");
      }
      setLogeado(true)
    } else {
      setLogeado(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      {!logeado && !user ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path='/auth' component={AuthPage} />
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      <Route path='/laboratorio-ext/:codigo' render={(props) => (
        <RegistroExternoIndex codigo={props.match.params.codigo} />
      )} />

      <Route path='/laboratorio/:codigo' render={(props) => (
        <RegistroInternoIndex codigo={props.match.params.codigo} />
      )} />

      <Route path='/examen/:codigo/:examen' render={(props) => (
        <ExamenInternoIndex codigo={props.match.params.codigo} examen={props.match.params.examen} />
      )} />

      {!logeado && !user ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <>
          {// eslint-disable-next-line eqeqeq
            (user && user.estado === state[2] && logeado) ? <ChangePass /> : (logeado && user && user.estado === state[3]) ?
              <FisrtLogin /> :
              <MasterLayout>
                { 
                  userRoles.length > 0 && <PrivateRoutes />
                }
              </MasterLayout>}
        </>
      )}
    </Switch>
  )
}

export { Routes }
