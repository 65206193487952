import { Fragment ,useContext} from 'react'

import { ContentProvider } from './context'
import { Toolbar3 } from '../../../_metronic/layout/components/toolbar/Toolbar3'
import Formulario from './Form'
import FormUpdate from './FormUpdate'
import { UserContext } from '../../utility/context/userContext'

const Perfil = () => {
    const { userAuth } = useContext(UserContext)

    return (
        <Fragment>
            <ContentProvider>
                <Toolbar3
                    DefaultTitle={"PERFIL DE USUARIO"}
                    pageModal={() => <></>}
                />
                <Formulario />
                {userAuth.code2 === "Usuario Interno" && <FormUpdate />}
            </ContentProvider>
        </Fragment>
    )
}

export default Perfil