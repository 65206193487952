import {FC, useState, useEffect, useRef, useContext} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {ToastContainer} from 'react-toastify'
import Load from '../../app/utility/Loading/index'
import {LoadingProvider} from '../../app/utility/Loading/provider'
import {useLocation} from 'react-router-dom'
import {validUrl} from '../../app/utility/Globales/index'
import 'react-toastify/dist/ReactToastify.css'
import {useDispatch} from 'react-redux'
import {setAuthUser} from '../../app/utility/redux/actions/user'

import {Button, Modal, ProgressBar, Alert} from 'react-bootstrap-v5'
import {PostRoute} from '../../app/services/Public'
import {ServiceLogVFour, ServiceLogVSix} from '../../app/views/auth/GetServiceLog'
import {browserName, osName, deviceType, getUA, deviceDetect, isWindows} from 'react-device-detect'
import DataDecrpt from './servceData'

const MasterLayout: FC = ({children}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isConnected, setIsConnected] = useState(window.navigator.onLine)

  const [showInternetModal, setShowInternetModal] = useState(!window.navigator.onLine)
  const [progress, setProgress] = useState(100)
  const {pathname} = useLocation()

  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const activityTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const dispatch = useDispatch()

  const logout = async () => {
    const data: any = DataDecrpt()

    let serviceIp = ''
    const serviceFour = (await ServiceLogVFour()) || ''
    const serviceSix = (await ServiceLogVSix()) || ''

    if (serviceSix === '' && serviceFour !== '') {
      serviceIp = serviceFour
    } else if (serviceSix !== '' && serviceFour === '') {
      serviceIp = serviceSix
    } else if (serviceSix !== '' && serviceFour !== '') {
      serviceIp = serviceSix
    }

    const divice = deviceDetect(window.navigator.userAgent)
    // const loc :any|null = localStorage.getItem('authUserI')
    PostRoute(`dv/out`, {
      navegador: browserName,
      sistema: osName,
      descripcion: divice.userAgent === undefined ? getUA : divice.userAgent,
      modelo:
        divice.model === undefined
          ? isWindows
            ? 'PC-windows'
            : deviceType
            ? deviceType
            : 'PC-mac'
          : divice.model,
      version: divice.osVersion,
      proveedor:
        divice.vendor === undefined ? (isWindows ? 'PC-windows' : 'PC-mac') : divice.vendor,
      ip: serviceIp,
      usuario: data.username,
    })

    localStorage.removeItem('authUserI')
    dispatch(setAuthUser(null))
    window.location.href = '/auth'
  }

  const clearCountdownInterval = () => {
    if (countdownIntervalRef.current !== null) {
      clearInterval(countdownIntervalRef.current)
      countdownIntervalRef.current = null
    }
  }

  const resetInactivityTimer = () => {
    clearTimeout(activityTimeoutRef.current!)
    // Establece un nuevo timeout para el logout
    activityTimeoutRef.current = setTimeout(() => {
      setShowConfirmationModal(true)
      // Configura otro timeout para el progreso y el logout final
      countdownIntervalRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress - 1
          if (nextProgress <= 0) {
            clearInterval(countdownIntervalRef.current!)
            logout()
            return 0
          }
          return nextProgress
        })
      }, 1000) // Ajusta este valor si deseas que el progreso disminuya más lentamente
    }, 1000 * 60 * 5) // Reinicia el timer después de 5 minutos de inactividad
  }

  useEffect(() => {
    resetInactivityTimer()

    const handleActivity = () => {
      resetInactivityTimer()
    }

    window.addEventListener('mousemove', handleActivity)
    window.addEventListener('keydown', handleActivity)

    return () => {
      // Limpiar en el desmontaje del componente
      clearTimeout(activityTimeoutRef.current!)
      clearInterval(countdownIntervalRef.current!)
      window.removeEventListener('mousemove', handleActivity)
      window.removeEventListener('keydown', handleActivity)
    }
  }, []) // Asegúrate de que este efecto solo se ejecute una vez

  const handleCancel = () => {
    setShowConfirmationModal(false)
    clearTimeout(activityTimeoutRef.current!)
    clearInterval(countdownIntervalRef.current!)
    resetInactivityTimer()
  }

  useEffect(() => {
    resetInactivityTimer()

    return () => {
      clearCountdownInterval()
    }
  }, [])

  useEffect(() => {
    // Manejar cambios en la conexión a Internet
    const updateOnlineStatus = () => {
      setIsConnected(window.navigator.onLine)
      setShowInternetModal(!window.navigator.onLine)
    }

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  })

  // window.addEventListener("beforeunload", function (e) {

  //   const confirmationMessage = 'Preparando la pagina para su cierre.';
  //   logout()
  //   e.returnValue = confirmationMessage;

  //   return confirmationMessage;
  // });

  return (
    <PageDataProvider>
      <LoadingProvider>
        <Modal
          show={showConfirmationModal}
          onHide={handleCancel}
          backdrop='static'
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Verificación de Actividad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Se cerrará la sesión si su usuario esta inactivo. Cancela el proceso si aún está
              Activo.
            </p>
            <ProgressBar now={progress} label={`${progress}%`} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCancel}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showInternetModal}
          onHide={() => setShowInternetModal(false)}
          backdrop='static'
          centered
          keyboard={false}
          dialogClassName='modal-60w'
        >
          <Modal.Body>
            {isConnected ? (
              <Alert className=' bg-success text-light'>Conexión restablecida</Alert>
            ) : (
              <Alert className=' bg-black text-light text-center'>
                Atención esta SIN CONEXIÓN se está intentando establecer la conexión nuevamente Por
                favor, verifica tu conexión a Internet
              </Alert>
            )}
          </Modal.Body>
        </Modal>

        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div
            className={`wrapper d-flex flex-column flex-row-fluid ${
              pathname.includes(validUrl[1]) || validUrl.includes(pathname) ? 'pt-19' : ''
            } `}
            id='kt_wrapper'
          >
            {/* muestra el encabezado de cada catalogo */}
            <HeaderWrapper />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              {/* muesta el nombre de cada catalogo */}
              {/* <Toolbar /> */}
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{children}</Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <ToastContainer />
        <Load />
        <MasterInit />
        <ScrollTop />
      </LoadingProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
