import CustomLabel from '../../../../utility/customLabel/index'
import {Row, Col, Form, Button} from 'react-bootstrap-v5'
import {useForm, Controller} from 'react-hook-form'
import {useContext, useEffect} from 'react'
import {ContentContext} from '../context'
import ReactTooltip from 'react-tooltip'
import * as Icon from 'react-feather'
import Select from 'react-select'

export const DatosContacto = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm<any>()
  const {
    opcion,
    dataPersona,
    volver,
    oneData,
    allDataMunicipio,
    allDataDepartamento,
    AllMunicipio,
    StoreUpdate
  } = useContext(ContentContext)
  const Departamento = watch('departamento')
  const onSubmit = (data: any) => {
    const Json = {
      id: oneData?.id || '',
      nombre1: dataPersona.nombre1,
      nombre2: dataPersona?.nombre2 || '',
      nombre3: dataPersona?.nombre3 || '',
      nombre4: dataPersona?.nombre4 || '',
      nombre5: dataPersona?.nombre5 || '',
      nombre6: dataPersona?.nombre6 || '',
      apellido1: dataPersona.apellido1,
      apellido2: dataPersona?.apellido2 || '',
      apellidoCasada: dataPersona?.apellido3 || '',
      fechaNacimiento: dataPersona?.fechaNacimiento || '',
      dpi: dataPersona.dpi,
      nit: dataPersona?.nit || '',
      genero: dataPersona.genero,
      etnia: dataPersona.etnia,
      estadoCivil: dataPersona.estadoCivil,
      tipoPersona: '3',

      afiliacionIGSS: dataPersona?.afiliacionIGSS || '',
      profesion: dataPersona.profesion,
      cursos: dataPersona.cursos,
      colegiado: dataPersona.colegiado,
      estadoColegiado: dataPersona.estadoColegiado,
      gradoAcademico: dataPersona.gradoAcademico,
      tipoSangre: dataPersona?.tipoSangre,
      municipio: data.municipio.value,
      email: data.email,
      telefono: data.telefono,
      direccion: data.direccion,
    }
    
    StoreUpdate(Json, oneData, 1)
  }

  const setData = () => {
    if (oneData) {
      setValue('email', oneData.email)
      setValue('direccion', oneData.direccion)
      setValue('telefono', oneData.telefono)
      oneData?.departamento && setValue('departamento', {label: oneData.departamento, value: oneData.idDepartamento})
      oneData?.municipio && setValue('municipio', {label: oneData.municipio, value: oneData.idMunicipio})
    }
  }

  useEffect(() => {
    if (oneData.length !== 0) {
      setData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneData])

  useEffect(() => {
    if (Departamento) {
      AllMunicipio(Departamento)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Departamento])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className='size-form'>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Departamento' type='danger' />
              <Controller
                name='departamento'
                control={control}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      isClearable
                      isSearchable
                      defaultValue={null}
                      isDisabled={opcion === 3}
                      options={allDataDepartamento}
                      placeholder={'Seleccione una opción'}
                      noOptionsMessage={() => 'sin resultados'}
                    />
                  )
                }}
                rules={{required: 'Este campo es requerido'}}
              />
              <p className='text-danger border-2 fw-danger'>
                {!!errors.departamento && errors.departamento.message}
              </p>
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Municipio' type='danger' />
              <Controller
                name='municipio'
                control={control}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      isClearable
                      isSearchable
                      defaultValue={null}
                      isDisabled={opcion === 3}
                      options={allDataMunicipio}
                      placeholder={'Seleccione una opción'}
                      noOptionsMessage={() => 'sin resultados'}
                    />
                  )
                }}
                rules={{required: 'Este campo es requerido'}}
              />
              <p className='text-danger border-2 fw-danger'>
                {!!errors.municipio && errors.municipio.message}
              </p>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Correo Electrónico' type='danger' />
              <Form.Control
                {...register('email', {
                  required: 'Este campo es requerido',
                })}
                readOnly={opcion === 3}
                isInvalid={!!errors.email}
              />
              <p className='text-danger fw-danger'>{!!errors.email && errors.email.message}</p>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='No. de Teléfono' type='danger' />
              <Form.Control
                {...register('telefono', {
                  required: 'Este campo es requerido',
                })}
                readOnly={opcion === 3}
                isInvalid={!!errors.telefono}
              />
              <p className='text-danger fw-danger'>
                {!!errors.telefono && errors.telefono.message}
              </p>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Dirección de Residencia' type='danger' />
              <Form.Control
                as='textarea'
                {...register('direccion', {
                  required: 'Este campo es requerido',
                })}
                readOnly={opcion === 3}
                isInvalid={!!errors.direccion}
              />
              <p className='text-danger fw-danger'>
                {!!errors.direccion && errors.direccion.message}
              </p>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <div className='separator border-2 my-6'></div>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-between'>
          <Button
            className='btn-icon'
            variant='secondary'
            size='sm'
            type='button'
            onClick={() => volver(0)}
            data-tip='Volver'
            data-for='volver'
          >
            <Icon.ArrowLeft size={16} />
          </Button>
          <ReactTooltip id='volver' place='top' type='dark' effect='solid' />

          <Button
            className='btn-icon'
            variant='primary'
            size='sm'
            type='submit'
            data-tip='Siguiente'
            data-for='siguiente'
          >
            <Icon.Save size={16} />
          </Button>
          <ReactTooltip id='siguiente' place='top' type='dark' effect='solid' />
        </Col>
      </Row>
    </Form>
  )
}

export default DatosContacto
