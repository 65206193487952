import { AsideMenuItemWithSub } from "../../AsideMenuItemWithSub";
import { AsideMenuItem } from "../../AsideMenuItem";

const Archivo = () =>{

    return (
      <AsideMenuItemWithSub
        to='/archivo'
        title={'Archivo Fisicos Inst. Admin. F.'}
        featherIcon={<i className='bi bi-archive fs-2'></i>}
      >
        <AsideMenuItem
          to='/archivo/tipoArchivo'
          title='Tipo Archivo'
          biIcon="bi bi-file-earmark-fill"
        />
        <AsideMenuItem
          to='/archivo/tipoResguardo'
          title='Tipo Resguardo'
          biIcon="bi bi-box2-fill"
        />
         <AsideMenuItem
          to='/archivo/controlBodega'
          title='Control de Bodegas'
          biIcon="bi bi-inboxes-fill"
        />
        <AsideMenuItem
          to='/archivo/archivo'
          title='Ingreso Archivo'
          biIcon="bi bi-file-earmark-arrow-up-fill"
        />
        <AsideMenuItem
          to='/archivo/despachoArchivo'
          title='Despacho De Documentos'
          biIcon="bi bi-file-earmark-arrow-down-fill"
        />
        <AsideMenuItem
          to='/archivo/recepcionArchivo'
          title='Recepción De Documentos'
          biIcon="bi bi-file-earmark-check-fill"
        />
        <AsideMenuItem
          to='/archivo/busquedaArchivo'
          title='Busqueda Archivo'
          biIcon="bi bi-funnel-fill"
        />
         {/* <AsideMenuItem
            to='/archivo/seccion-oficios-capacitacion'
            title='Sección de Oficios con Capacitación'
            icon='/media/icons/duotune/general/gen022.svg'
        />  */}
      </AsideMenuItemWithSub>
    )
}

export default Archivo