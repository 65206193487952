/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const IT = () => {
  return (
    <AsideMenuItemWithSub to='/inventarios' title='Inventarios' biIcon='bi-clipboard-check-fill'>
      <AsideMenuItem
        to='/inventarios/cuentas'
        title='Cuentas'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/inventarios/marca'
        title='Marca'
        icon='/media/icons/duotune/general/gen022.svg'
      />

      <AsideMenuItem
        to='/inventarios/tipo-bien'
        title='Tipo Bien'
        icon='/media/icons/duotune/general/gen022.svg'
      />

      <AsideMenuItem
        to='/inventarios/tipo-movimiento'
        title='Tipo Movimiento'
        icon='/media/icons/duotune/general/gen022.svg'
      />

      <AsideMenuItem
        to='/inventarios/bien'
        title='Bien'
        icon='/media/icons/duotune/ecommerce/ecm009.svg'
      />

      <AsideMenuItem to='/inventarios/solvencia' title='Solvencia' biIcon='bi-card-checklist' />

      <AsideMenuItem to='/inventarios/traslados' title='Traslados' biIcon='bi-reply-fill' />

      <AsideMenuItem
        to='/inventarios/tarjeta-responsabilidad'
        title='Tarjeta de Responsabilidad'
        biIcon='bi-postcard-fill'
      />

      <AsideMenuItem
        to='/inventarios/modalidad-compra'
        title='Modalidad de Compra'
        biIcon='bi-currency-exchange'
      />

      <AsideMenuItem
        to='/inventarios/kardex-inventario'
        title='Tarjeta Inventarios'
        biIcon='bi bi-file-earmark-bar-graph'
      />

      {/* <AsideMenuItem
        to='/inventarios/seccion-oficios-capacitacion'
        title='Sección de Oficios con Capacitación'
        icon='/media/icons/duotune/general/gen022.svg'
      />  */}
    </AsideMenuItemWithSub>
  )
}

export default IT
