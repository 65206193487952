/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const Viaticos = () => {
  return (
    <AsideMenuItemWithSub
      to='/viaticos'
      title={'Viáticos Admin. Financiero'}
      fontIcon='bi-archive'
      // icon='bi-car-front'
      biIcon='bi-truck'
      //biIcon=""
    >
      <AsideMenuItem
        to='/viaticos/reporte-viaticos'
        title={'Reporte Viaticos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/viaticos/administrar-solicitudes-viaticos'
        title={'Administar Solicitudes Viaticos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />

<AsideMenuItem
        to='/viaticos/libro-bancos-viaticos'
        title={'Libro Bancos Viaticos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />

      {/* <AsideMenuItem
        to='/viaticos/liquidacion'
        title={'Liquidación'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />

      <AsideMenuItem
        to='/viaticos/gastos'
        title={'Gastos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      {/* <AsideMenuItem
        to='/viaticos/solicitud-viatico'
        title={'Solicitud'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      {/* <AsideMenuItem
        to='/viaticos/reporteLiquidacion'
        title={"Reporte Liquidacion"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      {/* <AsideMenuItemWithSub
        to='/catalogos'
        title={'Catálogos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/viaticos/catalogos/tipo-anticipo'
          title={'Tipo Tramite'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/viaticos/catalogos/tipo-comision'
          title={'Tipo Comisión'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/viaticos/catalogos/tipo-viatico'
          title={'Tipo Viático'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
      </AsideMenuItemWithSub> */}

        {/* <AsideMenuItem
          to='/viaticos/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}
    </AsideMenuItemWithSub>
  )
}

export default Viaticos
