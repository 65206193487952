import { useContext, useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap-v5'
// import { ModelForm } from '../../utility/models/configEmail'
import { ContentContext } from './context'
import CustomLabel from '../../utility/customLabel/index'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import FormularioRRHH from './FormularioRrhh/index'
export const FormUpdate = () => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm()
    const { oneData, StoreUpdate, setOneData, ubicaciones, DataAll } = useContext(ContentContext)

    const onSubmit = (data: any) => {
        let jsData = {
            id: (oneData) ? oneData.id : null,
            emailServerAccount: data.email,
            passwordEmailAccount: data.password,
            smtpServer: data.smtp,
            smtpPort: data.port
        }

        StoreUpdate(jsData)
        Cancel()
    }

    const setData = async () => {
        setValue('email', oneData.emailServerAccount)
        setValue('smtp', oneData.smtpServer)
        setValue('port', oneData.smtpPort)
    }

    const Cancel = () => {
        reset()
        setOneData([])
    }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (oneData) { await setData() }
            }
            fetchMyAPI()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [oneData]
    )

    return (
        <>
            { DataAll && DataAll?.data&& DataAll?.data[0]?.idEstado === 1 ?
                <Card className='mt-5 p-8'>

                    <FormularioRRHH/>
                </Card>
                : null
            }
        </>

    )
}
export default FormUpdate