import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const RecepcionRegional = () =>{
     return (
        <AsideMenuItemWithSub
          to='/regional'
          title={'Recepción Regional'}
          featherIcon={<i className='bi bi-file-earmark-text fs-2'></i>}
        >
            <AsideMenuItem
              to='/regional/tipoLlamada-regional'
              title='Tipo Llamada'
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <AsideMenuItem
              to='/regional/tipoEnvio-regional'
              title='Tipo Envío'
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <AsideMenuItem
              to='/regional/llamadasEntrantes-regional'
              title='Llamadas Entrantes'
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <AsideMenuItem
              to='/regional/llamadasSalientes-regional'
              title='Llamadas Salientes'
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <AsideMenuItem
              to='/regional/envios-regional'
              title='Envíos'
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <AsideMenuItem
              to='/regional/recepcion-regional'
              title='Recepción'
              icon='/media/icons/duotune/general/gen022.svg'
            />
        </AsideMenuItemWithSub>
     )
}

export default RecepcionRegional