/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'

const Reportes = () => {
  

  return (
        <AsideMenuItemWithSub
          to='/almacen/reportes'
          title={"Reportes"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          <AsideMenuItem
            to='/almacen/reportes/existencias'
            title={"Existencias"}
            biIcon='bi-card-heading'
          />
          {/* <AsideMenuItem
            to='/almacen/reportes/kardex/historico'
            title={"Kardex Histórico"}
            biIcon='bi-card-heading'
          /> */}
           <AsideMenuItem
            to='/almacen/reportes/tarjetakardex'
            title={"Tarjeta Kardex"}
            biIcon='bi-card-heading'
          />

        </AsideMenuItemWithSub>
  )
}

export default Reportes
