import React, { Suspense, useEffect } from 'react'
import { SWRConfig } from 'swr'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'

// import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'
import { UserProvider } from './utility/context/userContext'
import { NotificationsProvider } from './utility/context/notificacionContext'
import { NombramientoProvider } from './utility/context/nombramientoContext'

import { userService } from './services/Public'


type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {

  useEffect(() => {
    const currentVersion = localStorage.getItem('appVersion');
    const appVersion = process.env.REACT_APP_VERSION;
    const versionUpdated = localStorage.getItem('versionUpdated');
  
    // Si la versión no ha cambiado o ya se manejó la actualización, termina la ejecución del efecto.
    if (versionUpdated === 'true' || currentVersion === appVersion) {
      localStorage.removeItem('versionUpdated'); // Asegura que se limpie para futuras actualizaciones.
      return;
    }
  
    // Solo procede si es necesario actualizar la versión y recargar.
    if (currentVersion === null || currentVersion !== appVersion) {
      localStorage.setItem('appVersion', appVersion ? appVersion : '');
      localStorage.setItem('versionUpdated', 'true'); // Marcar que la versión ha sido actualizada.
      userService.logout();
      window.location.reload();
    }
  }, []);
  
  

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SWRConfig value={{ provider: () => new Map() }}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <UserProvider>
              <NotificationsProvider>
              <NombramientoProvider>
              <LayoutProvider>
                <Routes />
              </LayoutProvider>
              </NombramientoProvider>
              </NotificationsProvider>
            </UserProvider>
          </I18nProvider>
        </BrowserRouter>
      </SWRConfig>
    </Suspense>
  )
}

export { App }
