import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const ConsumodeCombustible = lazy(() => import('../../../views/Transportes2/ConsumodeCombustible/index')),
  Estimaciones = lazy(() => import('../../../views/Transportes2/Estimacion/index')),
  KilometrajeRecorrido = lazy(() => import('../../../views/Transportes2/KilometrajeRecorrido/index')),
  TarjetaKilometraje = lazy(() => import('../../../views/Transportes2/ControlKilometraje/index')),
  // GestionCupones = lazy(() => import('../../../views/Transportes2/GestionCupones/index')),
  SolicitudVehiculo = lazy(() => import('../../../views/Transportes2/SolicitudVehiculo/index')),
  AsignarVehiculo = lazy(() => import('../../../views/Transportes2/AsignarVehiculo/index')),
  Vehiculo = lazy(() => import('../../../views/Transportes2/Vehiculo/index')),
  GestionCupones = lazy(() => import('../../../views/Transportes2/SolicitudCupones/index')),
  GestionTransporte = lazy(() => import('../../../views/Transportes2/GestionTransporte/index')),
  Mantenimiento = lazy(() => import('../../../views/Transportes2/Mantenimiento/index')),
  TipoCombustible = lazy(() => import('../../../views/Transportes2/Catalogos/tipoCombustible/index')),
  TipoRodadura = lazy(() => import('../../../views/Transportes2/Catalogos/TipoRodadura/index')),
  TipoVehiculo = lazy(() => import('../../../views/Transportes2/Catalogos/tipoVehiculo/index')),
  IngresoCupones = lazy(() => import('../../../views/Transportes2/Catalogos/Cupones/index')),
  Servicios = lazy(() => import('../../../views/Transportes2/Servicios/index')),
  DetalleSolicitudDevolucion = lazy(() => import('../../../views/Transportes2/DetalleSolicitudDevolucion/index')),
  Nombramiento = lazy(() => import('../../../views/Transportes2/Nombramiento2/index')),
  Liquidacion = lazy(() => import('../../../views/Transportes2/Liquidacion/index')),
  Distancia = lazy(() => import('../../../views/Transportes2/Catalogos/Distancia/index')),
  SeccionOficios = lazy(() => import('../../../views/Transportes2/Oficios/index'))


const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/liquidacion`} component={Liquidacion} />
      <Route path={`${props.match.path}/solicitud-vehiculo`} component={SolicitudVehiculo} />
      <Route path={`${props.match.path}/asignar-vehiculo`} component={AsignarVehiculo} />
      <Route path={`${props.match.path}/vehiculo`} component={Vehiculo} />
      <Route path={`${props.match.path}/gestion-cupones`} component={GestionCupones} />
      <Route path={`${props.match.path}/gestion-estimaciones`} component={Estimaciones} />
      <Route path={`${props.match.path}/gestion-transportes`} component={GestionTransporte} />
      <Route path={`${props.match.path}/mantenimiento`} component={Mantenimiento} />
      <Route path={`${props.match.path}/servicios`} component={Servicios} />
      <Route path={`${props.match.path}/detalle-solicitud-devolucion`} component={DetalleSolicitudDevolucion} />
      <Route path={`${props.match.path}/nombramiento`} component={Nombramiento} />
      <Route path={`${props.match.path}/control-kilometraje`} component={KilometrajeRecorrido} />
      <Route path={`${props.match.path}/tarjeta-kilometraje`} component={TarjetaKilometraje} />
      <Route path={`${props.match.path}/consumo-saldos-combustible`} component={ConsumodeCombustible} />
      <Route path={`${props.match.path}/catalogos/tipo-combustible`} component={TipoCombustible} />
      <Route path={`${props.match.path}/catalogos/tipo-rodadura`} component={TipoRodadura} />
      <Route path={`${props.match.path}/catalogos/tipo-vehiculo`} component={TipoVehiculo} />    
      <Route path={`${props.match.path}/catalogos/distancia`} component={Distancia} />  
      <Route path={`${props.match.path}/catalogos/cupones`} component={IngresoCupones} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
