/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Card, Button, Col, Row } from "react-bootstrap-v5"
import { PostRoute } from '../../services/Public'
// import { ToastContainer, toast, Bounce } from 'react-toastify';
import { toast } from 'react-toastify'
import { ContentContext } from './context'
import { useForm, Controller } from "react-hook-form";
import { useContext, useEffect } from 'react'
import * as Icon from 'react-feather'
import { Bounce } from 'react-toastify'
import CustomLabel from '../../utility/customLabel/index'
import Select from 'react-select'

const FormParticipante = () => {
    const
        { formState: { errors }, setValue, control, handleSubmit, watch } = useForm(),
        { setIdCooperativa, labelCooperativas, labelSucursales, setIdSucursales } = useContext(ContentContext),
        labelGenero = [
            { value: 1, label: 'Femenino ' },
            { value: 2, label: 'Masculino ' }
        ],
        seleccionableCooperativas = watch('cooperativa'),
        seleccionableSucursales = watch('sucursales'),
        // eslint-disable-next-line 
        onSubmit = async (data: any) => {
            const response = await PostRoute(`capacitaciones/create-particpante`,
                {
                    nombreCompleto: data.nombre,
                    dpi: data.dpi,
                    idCooperativa: data.cooperativa.value,
                    idSucursal: data.sucursales.value,
                    correo: data.correo,
                    telefono: data.telefono,
                    idGenero: data.genero.value,
                    capacitacion: 1,
                })

            toast.success(response.message, { position: 'bottom-right', transition: Bounce })
        }
    // tokenFail = async () => {

    //     const response = await PostRoute(`usuario/tknreset`, { codigo })
    //     if (response !== null && response !== 0 && response.value !== 2009) {
    //         setShow(true)
    //     }
    // }

    // useEffect(
    //     () => {
    //         async function fetchMyAPI() {
    //             await tokenFail()
    //         }

    //         fetchMyAPI()

    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //     }, [codigo]
    // )

    useEffect(() => {
        async function fetchMyAPI() {
            if (await seleccionableCooperativas) {
                setIdCooperativa(seleccionableCooperativas.value)
            }
            if (seleccionableCooperativas === null || seleccionableCooperativas === '') {
                setValue('sucursales', '')
                setIdCooperativa([])
                // setCheck(0)
            }
        }
        fetchMyAPI()
    }, [seleccionableCooperativas]);

    useEffect(() => {
        async function fetchMyAPI() {
            if (await seleccionableSucursales) {
                setIdSucursales(seleccionableSucursales.value)
                // setCheck(1)
            }
            if (seleccionableSucursales === null || seleccionableSucursales === '') {
                setIdSucursales([])
                // setCheck(0)
            }
        }
        fetchMyAPI()
    }, [seleccionableSucursales]);

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            <Form className="form fv-plugins-bootstrap fv-plugins-framework" onSubmit={handleSubmit(onSubmit)}>
                <Card.Title className='fw-bold mb-4 text-center'>
                    Formulario de Participantes
                </Card.Title>
                <Card.Text className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 ">
                    <p className='text text-center justify-content'>
                        Dirección: 17 Avenida 29-58 zona 11, Colonia Las Charcas, Ciudad de Guatemala.
                        <br />
                        Ponte en contacto con nosotros: 2297-4000
                    </p>
                </Card.Text>
                <div style={{ overflow: 'scroll', height: '620px' }}>
                    <Row>
                        <Form.Group className="mb-3">
                            <CustomLabel required={true} text="Cooperativa" type="danger" />
                            <Controller
                                name="cooperativa"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            {...field}
                                            isClearable
                                            isSearchable
                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                            defaultValue={null}
                                            options={labelCooperativas && labelCooperativas.data}
                                            placeholder={"Seleccione una opción"}
                                            noOptionsMessage={() => 'Sin resultados'}
                                            styles={{}}

                                        />
                                    )
                                }
                                }
                                rules={{ required: "Este campo es requerido" }}
                            />
                            <span className="text-danger" >{!!errors.cooperativa && errors.cooperativa.message}</span>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <CustomLabel required={true} text="Sucursal" type="danger" />
                            <Controller
                                name="sucursales"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            {...field}
                                            isClearable
                                            isSearchable
                                            isLoading={labelSucursales && labelSucursales.data ? false : true}
                                            isDisabled={labelSucursales && labelSucursales.data ? false : true}
                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                            defaultValue={null}
                                            options={labelSucursales && labelSucursales.data}
                                            placeholder={`${labelSucursales && labelSucursales.data ? 'Seleccione una opción' : 'Sin resultados'}`}
                                            noOptionsMessage={() => 'Sin resultados'}
                                            styles={{}}

                                        />
                                    )
                                }
                                }
                                rules={{ required: "Este campo es requerido" }}
                            />
                            <span className="text-danger" >{!!errors.sucursales && errors.sucursales.message}</span>
                        </Form.Group>
                    </Row>
                    <Row className="form-group fv-plugins-icon-container mb-10">
                        <Col  >
                            <Form.Group >
                                <CustomLabel required={true} text="Nombre Completo" type="danger" />
                                <Controller
                                    defaultValue=''
                                    control={control}
                                    name='nombre'
                                    render={({ field }) => (
                                        <Form.Control
                                            className='form-control form-control-solid h-auto py-5 px-5'
                                            type='text'
                                            isInvalid={!!errors.nombre}
                                            {...field}
                                        />
                                    )
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                            </Form.Group >
                        </Col>
                    </Row>
                    <Row className="form-group fv-plugins-icon-container mb-10">
                        <Col  >
                            <Form.Group >
                                <CustomLabel required={true} text="DPI" type="danger" />
                                <Controller
                                    defaultValue=''
                                    control={control}
                                    name='dpi'
                                    render={({ field }) => (
                                        <Form.Control
                                            className='form-control form-control-solid h-auto py-5 px-5'
                                            type='number'
                                            isInvalid={!!errors.dpi}
                                            {...field}
                                        />
                                    )
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                            </Form.Group >
                        </Col>
                    </Row>
                    <Row className="form-group fv-plugins-icon-container mb-10">
                        <Col  >
                            <Form.Group >
                                <CustomLabel required={true} text="Correo Eléctronico" type="danger" />
                                <Controller
                                    defaultValue=''
                                    control={control}
                                    name='correo'
                                    render={({ field }) => (
                                        <Form.Control
                                            className='form-control form-control-solid h-auto py-5 px-5'
                                            type='email'
                                            isInvalid={!!errors.correo}
                                            {...field}
                                        />
                                    )
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                            </Form.Group >
                        </Col>
                    </Row>
                    <Row className="form-group fv-plugins-icon-container mb-10">
                        <Col  >
                            <Form.Group >
                                <CustomLabel required={true} text="Teléfono" type="danger" />
                                <Controller
                                    defaultValue=''
                                    control={control}
                                    name='telefono'
                                    render={({ field }) => (
                                        <Form.Control
                                            className='form-control form-control-solid h-auto py-5 px-5'
                                            type='number'
                                            isInvalid={!!errors.telefono}
                                            {...field}
                                        />
                                    )
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                            </Form.Group >
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <CustomLabel required={true} text="Género" type="danger" />
                            <Controller
                                name="genero"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            {...field}
                                            isClearable
                                            isSearchable
                                            defaultValue={null}
                                            options={labelGenero}
                                            placeholder={"Seleccione una opción"}
                                            noOptionsMessage={() => 'Sin resultados'}
                                            styles={{}}

                                        />
                                    )
                                }
                                }
                                rules={{ required: "Este campo es requerido" }}
                            />
                            <span className="text-danger" >{!!errors.genero && errors.genero.message}</span>
                        </Form.Group>
                    </Row>

                    <Row>
                        {/* <Col>
                        <div className='pt-2 text-center w-100'>
                            <Link to="/auth/login">
                                <Icon.ChevronLeft size={18} /> Atrás para iniciar sesión
                            </Link>
                        </div>
                    </Col> */}

                        <Col className="d-flex flex-wrap justify-content-center align-items-center" >
                            <Form.Group >

                                <Button type="submit" color="success">
                                    Confirmar <Icon.Save size={16} />
                                </Button>
                            </Form.Group >
                        </Col>
                    </Row>
                </div>
            </Form>


        </div>
    )
}

export default FormParticipante