import { lazy,useContext } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import { UserContext } from '../../../../app/utility/context/userContext'

const Admin = lazy(() => import('./admin/index')),
  ControlActas = lazy(() => import('../../../views/Compras/ControlActas')),
  RazonamientoFacturas = lazy(() => import('../../../views/Compras/RazonamientoFacturas')),
  ProcesoEventos = lazy(() => import('../../../views/Compras/ProcesoDeEventos')),
  InformeMensual = lazy(() => import('../../../views/Compras/InformeMensual')),
  Operar = lazy(() => import('../../../views/Compras/Operar/index')),
  OperarMixtas = lazy(() => import('../../../views/Compras/OperarMixtas/index')),
  Insumo = lazy(() => import('../../../views/Compras/Administracion/Insumo/index')),
  SeccionOficios = lazy(() => import('../../../views/Compras/Oficios/index'))

const Index = (props: any) =>{
  const { Roles, userRoles } = useContext(UserContext)
  
  return (
    <Switch>
{((userRoles.includes(Roles.a_cpr)) || (userRoles.includes(Roles.r_cpr)) || 
  (userRoles.includes(Roles.p_cpr)) || (userRoles.includes(Roles.d_cpr)) ||
  (userRoles.includes(Roles.a_fin))) 
  ? (<Route path={`${props.match.path}/operar`} component={Operar} />) : ('')

}
{((userRoles.includes(Roles.a_cpr)) || (userRoles.includes(Roles.r_cpr)) || 
  (userRoles.includes(Roles.p_cpr)) || (userRoles.includes(Roles.d_cpr)) ||
  (userRoles.includes(Roles.a_fin))) 
  ? (<Route path={`${props.match.path}/operar-mixtos`} component={OperarMixtas} />) : ('')

}
{ ((userRoles.includes(Roles.a_cpr)) || (userRoles.includes(Roles.a_fin))) ? (
  <>
      <Route path={`${props.match.path}/admin`} component={Admin} />
      <Route path={`${props.match.path}/insumo`} component={Insumo} />
      <Route path={`${props.match.path}/control-oficios`} component={ControlActas} />
      <Route path={`${props.match.path}/razonamiento-facturas`} component={RazonamientoFacturas} />
      <Route path={`${props.match.path}/proceso-eventos`} component={ProcesoEventos} />
      <Route path={`${props.match.path}/informe-mensual`} component={InformeMensual} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
  </>
  ) : ('')
}

      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
