import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Rol = lazy(() => import('../../../views/IT/Rol/index')),
      Usuario = lazy(() => import('../../../views/IT/Usuario/index')),
      UsuarioCooperativa = lazy(() => import('../../../views/IT/AccesoCooperativa/index')),
      Departamento = lazy(() => import('../../../views/IT/Departamento/index')),
      Municipio = lazy(() => import('../../../views/IT/Municipio/index')),
      ConfigCorreo = lazy(() => import('../../../views/IT/ConfigEmail/index')),
      TipoPersona = lazy(() => import('../../../views/IT/TipoPersona/index')),
      Genero = lazy(() => import('../../../views/IT/Genero/index')),
      Grupo = lazy(() => import('../../../views/IT/Grupo/index')),
      Modalidad = lazy(() => import('../../../views/Compras/Modalidad/index')),
      SubGrupo = lazy(() => import('../../../views/IT/SubGrupo/index')),
      Renglon = lazy(() => import('../../../views/IT/Renglon/index')),
      Almacen = lazy(() => import('../../../views/IT/AdminAlmacen/index')),
      Bodegas = lazy(() => import('../../../views/IT/Bodega/index')),
      SeccionOficios = lazy(() => import('../../../views/IT/Oficios/index')),
      ControlSesiones = lazy(() => import('../../../views/IT/divice/index')),
      ControlActividad = lazy(() => import('../../../views/IT/actividad/index'))



const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/tipo-persona`} component={TipoPersona} />
      <Route path={`${props.match.path}/rol`} component={Rol} />
      <Route path={`${props.match.path}/genero`} component={Genero} />
      <Route path={`${props.match.path}/usuario`} component={Usuario} />
      <Route path={`${props.match.path}/acceso-modalidad`} component={UsuarioCooperativa} />
      <Route path={`${props.match.path}/config-correo`} component={ConfigCorreo} />
      <Route path={`${props.match.path}/departamento`} component={Departamento} />
      <Route path={`${props.match.path}/municipio`} component={Municipio} />
      <Route path={`${props.match.path}/almacen/admin`} component={Almacen} />
      <Route path={`${props.match.path}/almacen/bodega`} component={Bodegas} />
      <Route path={`${props.match.path}/grupo`} component={Grupo} />
      <Route path={`${props.match.path}/sub-grupo`} component={SubGrupo} />
      <Route path={`${props.match.path}/renglon`} component={Renglon} />
      <Route path={`${props.match.path}/modalidad`} component={Modalidad} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Route path={`${props.match.path}/controlsesiones`} component={ControlSesiones} />
      <Route path={`${props.match.path}/actividadcontrol`} component={ControlActividad} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
