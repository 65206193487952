/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const Planificacion = (Props: any) => {
  return (
    <AsideMenuItemWithSub
      to='/planificacion'
      title={'Planificación'}
      featherIcon={<i className='las la-clipboard-list fs-2'></i>}
    >
      <AsideMenuItemWithSub
        to='/catalogos'
        title={'Catálogos'}
        featherIcon={<i className='las la-list-ul fs-2'></i>}
      >
        <AsideMenuItem
          to='/planificacion/programa'
          title={'Programa'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/planificacion/actividades'
          title={'Actividades'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/planificacion/producto'
          title={'Producto'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/planificacion/sub-producto'
          title={'Sub Producto'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/planificacion/unidad-medida'
          title={'Unidad Medida'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/planificacion/tipo-seccion'
          title={'Tipo Secciónes'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/planificacion/tipo-informe'
          title={'Tipo Informes'}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/planificacion/meta'
        title={'Metas'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/cambios-manual'
        title={'Control de Cambios Para Manuales'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/reporte-de-metas'
        title={'Estadisticas de Fiscalización'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/plan-anualfisca'
        title={'Plan Anual de Fiscalización'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/seguimiento-de-metas'
        title={'Estadisticas de Seguimiento'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/est-vat'
        title={'Estadisticas de Vigilancia y Alerta Temprana '}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/vat-de-metas'
        title={'Plan Anual de Vigilancia y Alerta Temprana '}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/est-transportes'
        title={"Estadisticas de Transporte"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/liquidacion'
        title={"Liquidación de Transporte"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/est-viaticos'
        title={'Estadisticas de Viaticos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/administrar-solicitudes-viaticos'
        title={'Liquidación de Viaticos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/manuales'
        title={'Manuales y Procedimientos'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/planificacion/cooperativas'
        title={'Visor de Cooperativas'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      {/* <AsideMenuItem
        to='/planificacion/seccion-oficios-capacitacion'
        title='Sección de Oficios con Capacitación'
        icon='/media/icons/duotune/general/gen022.svg'
      />  */}
    </AsideMenuItemWithSub>
  )
}

export default Planificacion
