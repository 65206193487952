/* eslint-disable react/jsx-no-target-blank */

import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'

const Transportes = () => {

  return (
    <AsideMenuItemWithSub
      to='/transportes'
      title={"Transportes"}
      fontIcon='bi-archive'
      biIcon="bi-hdd-stack"
    //biIcon=""
    >

      <AsideMenuItem
        to='/transportes/solicitud-vehiculo'
        title={"Solicitud de Vehículos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/transportes/gestion-cupones'
        title={"Solicitud de Cupones"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      {/* <AsideMenuItem
        to='/transportes/gestion-estimaciones'
        title={"Estimaciones"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      <AsideMenuItem
        to='/transportes/liquidacion'
        title={"Liquidación"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/transportes/control-kilometraje'
        title={"Control de Kilometraje de Recorridos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItem
        to='/transportes/vehiculo'
        title={"Vehículos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      {/* <AsideMenuItem
        to='/transportes/asignar-vehiculo'
        title={"Asignación de Vehículos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      {/* <AsideMenuItem
        to='/transportes/gestion-transportes'
        title={"Gestión de Transportes"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      {/* <AsideMenuItem
        to='/transportes/mantenimiento'
        title={"Mantenimiento"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      <AsideMenuItem
        to='/transportes/tarjeta-kilometraje'
        title={"Tarjeta de Kilometraje"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      {<AsideMenuItem
        to='/transportes/consumo-saldos-combustible'
        title={"Consumo y Saldos de Combustible"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />}
      <AsideMenuItem
        to='/transportes/servicios'
        title={"Servicios"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      {/* <AsideMenuItem
        to='/transportes/detalle-solicitud-devolucion'
        title={"Detalle Solicitud Devolucion"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      <AsideMenuItem
        to='/transportes/nombramiento'
        title={"Nombramientos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      <AsideMenuItemWithSub
        to='/catalogos'
        title={"Catálogos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/transportes/catalogos/cupones'
          title={"Ingreso Cupones"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/transportes/catalogos/tipo-combustible'
          title={"Tipo Combustible"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        {/* <AsideMenuItem
          to='/transportes/catalogos/distancia'
          title={"Distancias"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        /> */}
        <AsideMenuItem
          to='/transportes/catalogos/tipo-rodadura'
          title={"Tipo Rodadura"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />
        <AsideMenuItem
          to='/transportes/catalogos/tipo-vehiculo'
          title={"Tipo Vehículo"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil016.svg'
        />

      </AsideMenuItemWithSub>
{/* 
      <AsideMenuItem
        to='/transportes/seccion-oficios-capacitacion'
        title='Sección de Oficios con Capacitación'
        icon='/media/icons/duotune/general/gen022.svg'
      />  */}
    </AsideMenuItemWithSub>
  )
}

export default Transportes