import { createContext, useState } from 'react'

export const NombramientoContext = createContext()

export const NombramientoProvider = ({children}) =>{
    const [idNombramientoVat, setIdNombramientoVat] = useState(0)
    const [idNombramientoSeg, setIdNombramientoSeg] = useState(0)
    const [idNombramientoFisc, setIdNombramientoFisc] = useState(0)
    return(
        <NombramientoContext.Provider 
        value={{idNombramientoVat, setIdNombramientoVat,idNombramientoSeg, setIdNombramientoSeg,idNombramientoFisc, setIdNombramientoFisc}}>
            { children }
        </NombramientoContext.Provider>
    )
}




