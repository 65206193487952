/* eslint-disable react/jsx-no-target-blank */

import { useContext } from 'react'
import { AsideMenuItemWithSub } from '../../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../../AsideMenuItem'
import { UserContext } from '../../../../../../../app/utility/context/userContext'

const Ejecucion = () => {
  const { Roles, userRoles } = useContext(UserContext)
  return (

    <AsideMenuItemWithSub
      to='/seguimiento/ejecucion'
      title={"Ejecucion"}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >

      {/* { 
       userRoles.includes(Roles.an_seg|| Roles.agl_seg) && 
        <AsideMenuItem
          to='/seguimiento/ejecucion/asignaciones'
          title={"Perfil de Cooperativas"}
          biIcon='bi bi-person-lines-fill'
        />

        
      } */}
      
      { 
       (userRoles.includes(Roles.an_seg)||userRoles.includes(Roles.agl_seg))  && 
        <AsideMenuItem
        to='/seguimiento/ejecucion/misnombramientos'
        title={"Mis Nombramientos"}
        biIcon='bi bi-file-fill'
      />
      }
      { 
       (userRoles.includes(Roles.an_seg)||userRoles.includes(Roles.agl_seg))  && <AsideMenuItem
        to='/seguimiento/catalogos/cargahistorica'
        title={'Carga Historica'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
        
      />}
      {
       (userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ) && 
        <AsideMenuItem
        to='/seguimiento/ejecucion/segnombramiento'
        title={"Nombramientos En Ejecucion"}
        biIcon='bi bi-check-square'
      />
      }
      {
       (userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ) && 
        <AsideMenuItem
        to='/seguimiento/ejecucion/segasis'
        title={"Nombramientos Para Revisión de Documento Asistente"}
        biIcon='bi bi-check-square'
      />
      }
      {
       ((userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ) || userRoles.includes(Roles.sec_seg)  ) &&
        <AsideMenuItem
        to='/seguimiento/ejecucion/seginforme'
        title={"Nombramientos Para Revisión de Documento Jefatura"}
        biIcon='bi bi-check-square'
      />
      }
     
      
    </AsideMenuItemWithSub>
  )
}


export default Ejecucion
