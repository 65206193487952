import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
// import { UserContext } from '../../../../app/utility/context/userContext'

const Rol = lazy(() => import('../../../views/InformacionPublica/index')),
  TipoGestion = lazy(() => import('../../../views/InformacionPublica/Catalogos/tipoGestion/index')),
  TipoMedio = lazy(() => import('../../../views/InformacionPublica/Catalogos/tipoMedio/index')),
  PeticionInformacion = lazy(() => import('../../../views/InformacionPublica/peticionInformacion/index')),
  SeccionOficios = lazy(() => import('../../../views/InformacionPublica/Oficios/index'))

const Index = (props: any) =>{
  // const { Roles, userRoles } = useContext(UserContext)
  
  return (
    <Switch>
      {
      // userRoles.includes(Roles.a_cpr) &&
        <>
            
            <Route path={`${props.match.path}/info`} component={Rol} />
            <Route path={`${props.match.path}/peticionInformacion`} component={PeticionInformacion} />
            <Route path={`${props.match.path}/catalogos/tipo-gestion`} component={TipoGestion} />
            <Route path={`${props.match.path}/catalogos/tipo-medio`} component={TipoMedio} />
            <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />

        </>
      }
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
