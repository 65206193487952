import {formatDate, stringToDate} from '../../../../utility/Globales/index'
import CustomLabel from '../../../../utility/customLabel/index'
import {Form, Row, Col, Button} from 'react-bootstrap-v5'
import {useEffect, useContext, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import DatePicker from 'react-datepicker'
import ReactTooltip from 'react-tooltip'
import * as Icon from 'react-feather'
import Select from 'react-select'

import {ContentContext} from '../context'

interface IFormInputs {
  nombre1: string
  nombre2: string
  nombre3: string

  nombre4: string
  nombre5: string
  nombre6: string

  apellido1: string
  apellido2: string
  apellido3: string

  dpi: string
  nit: string

  fechaNacimiento: any
  edad: number
  genero: any
  tipoSangre: any
  afiliacionIGSS: string
  profesion: any
  gradoAcademico: any
  cursos: string
  estadoColegiado: any
  colegiado: string
  tipoPersona: number
  estadoCivil: any
  etnia: any
  foto: any
}

export const DatosPersonales = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm<IFormInputs>()
  const {
    opcion,
    etnias,
    oneData,
    generos,
    setStep,
    allDataEstadoCivil,
    setDataPersona,
    dataPersona,
    allDataTipoSangre,
    allDataProfesion,
    allDataGradoAcademico,
  } = useContext(ContentContext)
  const [validEstadoColegiado, setValidEstadoColegiado] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Visualizar, setVisualizar] = useState(''),
    setData = async () => {
      let fechaSplit = oneData.fechaNacimiento.split('/')
      let fechaFormato = fechaSplit[1] + '/' + fechaSplit[0] + '/' + fechaSplit[2]
      let edadCalculada = new Date().getTime() - new Date(fechaFormato).getTime()
      edadCalculada = Math.floor(edadCalculada / (365.25 * 24 * 60 * 60 * 1000));

      if (oneData) {
        await setValue('nombre1', oneData.nombre1)
        await setValue('nombre2', oneData.nombre2)
        await setValue('nombre3', oneData.nombre3)

        await setValue('apellido1', oneData.apellido1)
        await setValue('apellido2', oneData.apellido2)
        await setValue('apellido3', oneData.apellidoCasada)

        await setValue('dpi', oneData.dpi)
        await setValue('nit', oneData.nit)
        await setValue('afiliacionIGSS', oneData.afiliacionIGSS)
        await setValue('colegiado', oneData.colegiado)
        setValidEstadoColegiado(oneData.estadoColegiado === '1' ? true : false)
        oneData.fechaNacimiento &&
          (await setValue(
            'fechaNacimiento',
            new Date(stringToDate(oneData.fechaNacimiento, 'dd/MM/yyyy', '/'))
          ))
          oneData.fechaNacimiento && 
           (await setValue('edad', edadCalculada))
        oneData?.idGenero && (await setValue('genero', {value: oneData.idGenero, label: oneData.genero}))
        oneData?.idEstadoCivil &&(await setValue('estadoCivil', { value: oneData.idEstadoCivil, label: oneData.estadoCivil}))
        oneData?.idEtnia && (await setValue('etnia', {value: oneData.idEtnia, label: oneData.etnia}))
        oneData?.idProfesion && (await setValue('profesion', {value: oneData.idProfesion, label: oneData.profesion}))
        oneData?.idGradoAcademico && (await setValue('gradoAcademico', { value: oneData.idGradoAcademico, label: oneData.gradoAcademico }))
        oneData?.idTipoSangre && (await setValue('tipoSangre', {value: oneData.idTipoSangre, label: oneData.tipoSangre}))
        await setValue('cursos', oneData.cursos)
        await setVisualizar(oneData && oneData.foto)
      }
    },
    onSubmit = (data: any) => {
      const Json = {
        nombre1: data.nombre1,
        nombre2: data?.nombre2,
        nombre3: data?.nombre3,
        apellido1: data.apellido1,
        apellido2: data?.apellido2,
        apellido3: data?.apellido3,
        dpi: data.dpi,
        nit: data.nit,
        tipoSangre: data?.tipoSangre?.value,
        gradoAcademico: data.gradoAcademico.value,
        cursos: data.cursos,
        profesion: data.profesion.value,
        afiliacionIGSS: data?.afiliacionIGSS || '',
        colegiado: data.colegiado || '',
        estadoColegiado: data.estadoColegiado ? 1 : 0,
        fechaNacimiento: formatDate(data.fechaNacimiento),
        genero: data.genero.value,
        estadoCivil: data.estadoCivil.value,
        etnia: data.etnia.value,
        //foto: opcion === 1 ? data.foto[0] : '',
      }
      setDataPersona({...dataPersona, ...Json})
      setStep('1')
    }
    // MostrarDoc = (value: any) => {
    //   if (value.target.value) {
    //     var pdffile = value.target.files[0],
    //       pdffile_url = URL.createObjectURL(pdffile)

    //     setVisualizar(`${pdffile_url}`)
    //   } else {
    //     setVisualizar('')
    //   }
    // },
    // clearFoto = () => {
    //   setVisualizar('')
    //   setValue('foto', null)
    // }

  useEffect(() => {
    if (oneData.length !== 0) {
      setData()

      const Json = {
        nombre1: oneData.nombre1,
        nombre2: oneData?.nombre2,
        nombre3: oneData?.nombre3,
        apellido1: oneData.apellido1,
        apellido2: oneData?.apellido2,
        apellido3: oneData?.apellido3,
        dpi: oneData.dpi,
        nit: oneData.nit,
        tipoSangre: oneData?.idTipoSangre === "" ? null : oneData?.idTipoSangre,
        gradoAcademico: oneData.idGradoAcademico,
        cursos: oneData.cursos,
        profesion: oneData.idProfesion,
        afiliacionIGSS: oneData?.afiliacionIGSS || '',
        colegiado: oneData.colegiado || '',
        estadoColegiado: oneData.estadoColegiado === "0" ? 0 : 1,
        fechaNacimiento: new Date(stringToDate(oneData.fechaNacimiento, 'dd/MM/yyyy', '/')).toISOString().split('T')[0],
        genero: oneData.idGenero,
        estadoCivil: oneData.idEstadoCivil,
        etnia: oneData.idEtnia,
        municipio: oneData.idMunicipio,
        email: oneData.email,
        telefono: oneData.telefono,
        direccion: oneData.direccion,
        //foto: opcion === 1 ? data.foto[0] : '', 
      }
      setDataPersona({...dataPersona, ...Json})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneData])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className='size-form'>
        <Row>
          {/* <Col lg={3}> 
            <Row>
              <Col className='d-flex justify-content-center align-self-center'>
                <div
                  className={`kt-avatar kt-avatar--outline ${Visualizar && 'kt-avatar--changed'}`}
                  id='kt_user_avatar'
                >
                  <div
                    className={`kt-avatar__holder ${
                      errors && errors.foto && errors.foto.message && 'border-danger'
                    }`}
                    style={{backgroundImage: `url('${Visualizar}')`}}
                  ></div>
                  {opcion === 1 && (
                    <>
                      <Form.Label
                        className='kt-avatar__upload p-0'
                        data-toggle='kt-tooltip'
                        title='Cargar Foto'
                        data-original-title='Change avatar'
                      >
                        {/* <Icon.Edit size={40}/> /}
                        <i className={`fa ${opcion === 2 ? 'fa-edit' : 'fa-image'}`}></i>
                        <Form.Control
                          {...register('foto', {
                            required: {
                              value: true,
                              message: 'Este campo es requerido',
                            },
                          })}
                          lang='es'
                          type='file'
                          className='fileInput'
                          accept='.jpg, .jpeg, .png'
                          onChange={(e) => MostrarDoc(e)}
                        />
                      </Form.Label>
                      <span className='kt-avatar__cancel' onClick={() => clearFoto()}>
                        <i className='fas fa-trash text-danger'></i>
                      </span>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row className='text-center'>
              <Col lg={12}>
                <small className='text-danger'>
                  {errors && errors.foto && errors.foto.message}
                </small>
              </Col>
              <Col lg={12}>
                <small className='text-muted'>Permitido *.jpeg, *.jpg, *.png</small>
              </Col>
              <Col lg={12}>
                <small className='text-muted'>tamaño máximo de 10 MB</small>
              </Col>
            </Row>
          </Col> */}
          <Col lg={12}>
            <Row>
              <Col lg={4}>
                <Form.Group className='mb-3'>
                  <CustomLabel required={true} text='Primer Nombre' type='danger' />
                  <Form.Control
                    {...register('nombre1', {
                      required: 'Este campo es requerido',
                    })}
                    readOnly={opcion === 3}
                    isInvalid={!!errors.nombre1}
                  />
                  <p className='text-danger fw-danger'>
                    {!!errors.nombre1 && errors.nombre1.message}
                  </p>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className='mb-3'>
                  <CustomLabel required={false} text='Segundo Nombre' />
                  <Form.Control {...register('nombre2')} readOnly={opcion === 3} />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className='mb-3'>
                  <Form.Label className='fw-bolder'>{'Tercer Nombre'}</Form.Label>
                  <Form.Control {...register('nombre3')} readOnly={opcion === 3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group className='mb-3'>
                  <CustomLabel required={true} text='Primer Apellido' type='danger' />
                  <Form.Control
                    {...register('apellido1', {
                      required: 'Este campo es requerido',
                    })}
                    readOnly={opcion === 3}
                    isInvalid={!!errors.apellido1}
                  />
                  <p className='text-danger fw-danger'>
                    {!!errors.apellido1 && errors.apellido1.message}
                  </p>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className='mb-3'>
                  <CustomLabel required={false} text='Segundo Apellido' />
                  <Form.Control
                    {...register('apellido2')}
                    readOnly={opcion === 3}
                    isInvalid={!!errors.apellido2}
                  />
                  <p className='text-danger fw-danger'>
                    {!!errors.apellido2 && errors.apellido2.message}
                  </p>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className='mb-3'>
                  <CustomLabel required={false} text='Apellido Casada' />
                  <Form.Control {...register('apellido3')} readOnly={opcion === 3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <CustomLabel required={true} text='No. de DPI' type='danger' />
                  <Form.Control
                    {...register('dpi', {
                      required: 'Este campo es requerido',
                    })}
                    readOnly={opcion === 3}
                    isInvalid={!!errors.dpi}
                  />
                  <p className='text-danger fw-danger'>{!!errors.dpi && errors.dpi.message}</p>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <CustomLabel required={true} text='No. de NIT' type='danger' />
                  <Form.Control
                    {...register('nit', {
                      required: 'Este campo es requerido',
                    })}
                    readOnly={opcion === 3}
                    isInvalid={!!errors.nit}
                  />
                  <p className='text-danger fw-danger'>{!!errors.nit && errors.nit.message}</p>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <CustomLabel required={true} text='Fecha Nacimiento' type='danger' />
                  <Controller
                    control={control}
                    name='fechaNacimiento'
                    defaultValue={new Date()}
                    render={({field}) => (
                      <DatePicker
                        disabled={opcion === 3}
                        autoComplete='off'
                        className='form-control'
                        placeholderText='Fecha Ingreso'
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        dateFormat='dd/MM/yyyy'
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'Seleccione una fecha',
                      },
                    }}
                  />
                  <p className='text-danger fw-danger'>
                    {!!errors.fechaNacimiento && errors.fechaNacimiento.message}
                  </p>
                </Form.Group>
              </Col>
              { opcion !== 1 && 
                <Col lg={3}>
                  <Form.Group className='mb-3'>
                    <CustomLabel required={false} text='Edad (años)' type='danger' />
                    <Form.Control
                      {...register('edad')}
                      readOnly={true}
                      isInvalid={!!errors.edad}
                    />
                    <p className='text-danger fw-danger'>{!!errors.edad && errors.edad.message}</p>
                  </Form.Group>
                </Col>
              }
            </Row>
          </Col>
        </Row>
        <Row className='mt-2'></Row>

        <Row>
          <Col lg={3} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Género' type='danger' />
              <Controller
                name='genero'
                control={control}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      isClearable
                      isSearchable
                      defaultValue={null}
                      isDisabled={opcion === 3}
                      options={generos}
                      placeholder={'Seleccione una opción'}
                      noOptionsMessage={() => 'sin resultados'}
                    />
                  )
                }}
                rules={{required: 'Este campo es requerido'}}
              />
              <p className='text-danger fw-danger'>{!!errors.genero && errors.genero.message}</p>
            </Form.Group>
          </Col>
          <Col lg={3} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Estado Civil' type='danger' />
              <Controller
                name='estadoCivil'
                control={control}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      isClearable
                      isSearchable
                      defaultValue={null}
                      isDisabled={opcion === 3}
                      options={allDataEstadoCivil}
                      placeholder={'Seleccione una opción'}
                      noOptionsMessage={() => 'sin resultados'}
                    />
                  )
                }}
                rules={{required: 'Este campo es requerido'}}
              />
              <p className='text-danger border-2 fw-danger'>
                {!!errors.estadoCivil && errors.estadoCivil.message}
              </p>
            </Form.Group>
          </Col>
          <Col lg={3} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Etnia' type='danger' />
              <Controller
                name='etnia'
                control={control}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      isClearable
                      isSearchable
                      defaultValue={null}
                      isDisabled={opcion === 3}
                      options={etnias}
                      placeholder={'Seleccione una opción'}
                      noOptionsMessage={() => 'sin resultados'}
                    />
                  )
                }}
                rules={{required: 'Este campo es requerido'}}
              />
              <p className='text-danger border-2 fw-danger'>
                {!!errors.etnia && errors.etnia.message}
              </p>
            </Form.Group>
          </Col>
          <Col lg={3} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={false} text='Tipo de Sangre' /* type='danger' */ />
              <Controller
                name='tipoSangre'
                control={control}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      isClearable
                      isSearchable
                      defaultValue={null}
                      isDisabled={opcion === 3}
                      options={allDataTipoSangre}
                      placeholder={'Seleccione una opción'}
                      noOptionsMessage={() => 'sin resultados'}
                    />
                  )
                }}
                //rules={{required: 'Este campo es requerido'}}
              />
              <p className='text-danger border-2 fw-danger'>
                {!!errors.tipoSangre && errors.tipoSangre.message}
              </p>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={3} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Grado Academico' type='danger' />
              <Controller
                name='gradoAcademico'
                control={control}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      isClearable
                      isSearchable
                      defaultValue={null}
                      isDisabled={opcion === 3}
                      options={allDataGradoAcademico}
                      placeholder={'Seleccione una opción'}
                      noOptionsMessage={() => 'sin resultados'}
                    />
                  )
                }}
                rules={{required: 'Este campo es requerido'}}
              />
              <p className='text-danger border-2 fw-danger'>
                {!!errors.gradoAcademico && errors.gradoAcademico.message}
              </p>
            </Form.Group>
          </Col>
          <Col lg={3} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={true} text='Profesión' type='danger' />
              <Controller
                name='profesion'
                control={control}
                render={({field}) => {
                  return (
                    <Select
                      {...field}
                      isClearable
                      isSearchable
                      defaultValue={null}
                      isDisabled={opcion === 3}
                      options={allDataProfesion}
                      placeholder={'Seleccione una opción'}
                      noOptionsMessage={() => 'sin resultados'}
                    />
                  )
                }}
                rules={{required: 'Este campo es requerido'}}
              />
              <p className='text-danger border-2 fw-danger'>
                {!!errors.profesion && errors.profesion.message}
              </p>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group className='mb-3'>
              <CustomLabel required={false} text='Colegiado' type='danger' />
              <Form.Control
                {...register('colegiado', {
                  required: false,
                })}
                readOnly={opcion === 3}
                isInvalid={!!errors.colegiado}
              />
              <p className='text-danger fw-danger'>
                {!!errors.colegiado && errors.colegiado.message}
              </p>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group className='mb-3'>
              <CustomLabel required={false} text='Estado Colegiado' type='danger' />
              <Form.Check // prettier-ignore
                type='switch'
                {...register('estadoColegiado', {
                  required: false,
                })}
                onChange={() => setValidEstadoColegiado(!validEstadoColegiado)}
                checked={validEstadoColegiado}
              />

              <p className='text-danger border-2 fw-danger'>
                {!!errors.estadoColegiado && errors.estadoColegiado.message}
              </p>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Form.Group className='mb-3'>
              <CustomLabel required={false} text='Afiliación IGSS' type='danger' />
              <Form.Control
                {...register('afiliacionIGSS', {
                  required: false,
                })}
                readOnly={opcion === 3}
                isInvalid={!!errors.afiliacionIGSS}
              />
              <p className='text-danger fw-danger'>
                {!!errors.afiliacionIGSS && errors.afiliacionIGSS.message}
              </p>
            </Form.Group>
          </Col>
          <Col lg={9} sm={12}>
            <Form.Group className='mb-3'>
              <CustomLabel required={false} text='Ultimo Curso' type='danger' />
              <Form.Control
                {...register('cursos', {
                  required: false,
                })}
                readOnly={opcion === 3}
                isInvalid={!!errors.cursos}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <div className='separator border-2 my-6'></div>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          
          <Button
            className='btn-icon'
            variant='primary'
            size='sm'
            type='submit'
            data-tip='Siguiente'
            data-for='siguiente'
          >
            <Icon.ArrowRight size={16} />
          </Button>
          <ReactTooltip id='siguiente' place='top' type='dark' effect='solid' />
        </Col>
      </Row>
    </Form>
  )
}

export default DatosPersonales
