import {useState, useEffect, useContext} from 'react'
import {Row, Col, Form, Button, Modal} from 'react-bootstrap-v5'
import {ContentContext} from './context'
import Select from 'react-select'
import {handleErrorToken, handleErrorLabReg, handleSuccessLabReg} from '../../../utility/sweetAlert'
import {useForm, Controller} from 'react-hook-form'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import CustomLabel from '../../../utility/customLabel'
import {customStyles} from '../../../utility/Globales/index'
import {Diploma} from './export/Diploma'
import ModalDiplomaExt from '../../../utility/TemplatePdf/ModalDiplomaExt'

const RegistroExterno = ({codigo}) => {
  const [dataCapa, setDataCapa] = useState([])
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset,
  } = useForm()
  const {
      allData,
      labelCooperativas,
      labelEdades,
      errorToken,
      StoreUpdate,
      warningAlert,
      successAlert,
      setSuccessAlert,
      showVisorDiploma,
      setShowVisorDiploma,
      pdf,
      setPdf,
      oneData,
      dataPrevSave,
      OneDiploma,
    } = useContext(ContentContext),
    showModal = async () => {
      const doc: any = await Diploma(oneData.adjunto, dataPrevSave, dataCapa)
      await setShowVisorDiploma(true)
      await setPdf(doc)
    }

  const onSubmit = async (data: any) => {
    const filtrado = allData.find((reg) => reg.token === codigo)
    setDataCapa(filtrado)

    let jsData = {
      nombreCompleto: data.nombre,
      dpi: data.nit,
      idCooperativa: data.cooperativa.value,
      idGenero: data.sexo.value,
      etnia: data.etnia.value,
      correo: data.email,
      telefono: data.telefono,
      cargoCooperativa: data.cargo,
      edad: data.edad.value,
      enterarseEvento: data.enterado,
      otroCurso: data.curso,
      capacitacion: filtrado.id,
    }

    await OneDiploma(filtrado.idGrupoCapacitaciones)
    await StoreUpdate(jsData)
  }

  const labelSexo = [
    {
      label: 'Masculino',
      value: 1,
    },
    {
      label: 'Femenino',
      value: 2,
    },
  ]

  const labelEtnia = [
    {
      label: 'Maya',
      value: 1,
    },
    {
      label: 'Xinca',
      value: 2,
    },
    {
      label: 'Garífuna',
      value: 3,
    },
    {
      label: 'Mestizo',
      value: 4,
    },
    {
      label: 'Otro',
      value: 5,
    },
  ]

  const handleRedirectAward = () => {
    setSuccessAlert(false)
    showModal()
  }

  return (
    <>
      {errorToken ? (
        handleErrorToken()
      ) : (
        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'nowrap'}}>
          <div
            style={{
              background: '#FFD54F',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
            }}
          >
            <div className='d-flex justify-content-center'>
              <img src={toAbsoluteUrl('/media/logos/Logo.png')} alt='logo' />
            </div>
            <div className='d-flex justify-content-center'>
              <h1>{`Bienvenido al Registro de la capacitación ${codigo} !`}</h1>
            </div>
          </div>
          <div
            style={{
              background: '#E0E0E0',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
            }}
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className='d-flex justify-content-center'>
                <Col></Col>
                <Col className='mt-4 col-sm-8'>
                  <Form.Group controlId='nit' className='mb-3 position-relative'>
                    <CustomLabel
                      required={true}
                      text='Número de Identificación Tributaria (Nit)'
                      type='danger'
                    />
                    <Form.Control
                      {...register('nit', {
                        required: 'Este campo es requerido',
                      })}
                      isInvalid={!!errors.nit}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {!!errors.nit && errors.nit.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col></Col>
              </div>
              <div className='d-flex justify-content-center'>
                <Col></Col>
                <Col className='my-2 col-sm-8'>
                  <Form.Group controlId='email' className='mb-3 position-relative'>
                    <CustomLabel required={true} text='Correo Electrónico' type='danger' />
                    <Form.Control
                      {...register('email', {
                        required: 'Este campo es requerido',
                      })}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {!!errors.email && errors.email.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col></Col>
              </div>
              <div className='d-flex justify-content-center'>
                <Col></Col>
                <Col className='col-sm-8'>
                  <Form.Group className='mb-3'>
                    <CustomLabel required={true} text='Cooperativa' type='danger' />
                    <Controller
                      name='cooperativa'
                      control={control}
                      render={({field}) => {
                        return (
                          <Select
                            {...field}
                            isClearable
                            isSearchable
                            defaultValue={null}
                            options={labelCooperativas && labelCooperativas.data}
                            placeholder={'Seleccione la Cooperativa a la que pertenece'}
                            noOptionsMessage={() => 'Sin resultados'}
                            styles={!!errors.cooperativa ? customStyles : {}}
                          />
                        )
                      }}
                      rules={{required: 'Este campo es requerido'}}
                    />
                    <span className='text-danger'>
                      {!!errors.cooperativa && errors.cooperativa.message}
                    </span>
                  </Form.Group>
                </Col>
                <Col></Col>
              </div>
              <div className='d-flex justify-content-center'>
                <Col></Col>
                <Col className='my-4 col-sm-8'>
                  <Form.Group controlId='nombre' className='mb-3 position-relative'>
                    <CustomLabel
                      required={true}
                      text='Nombres y Apellidos Completos'
                      type='danger'
                    />
                    <Form.Control
                      {...register('nombre', {
                        required: 'Este campo es requerido',
                      })}
                      isInvalid={!!errors.nombre}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {!!errors.nombre && errors.nombre.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col></Col>
              </div>
              <div className='d-flex justify-content-center'>
                <Col></Col>
                <Col className='col-sm-8'>
                  <Form.Group controlId='cargo' className='mb-3 position-relative'>
                    <CustomLabel
                      required={true}
                      text='Cargo que desempeña en la cooperativa'
                      type='danger'
                    />
                    <Form.Control
                      {...register('cargo', {
                        required: 'Este campo es requerido',
                      })}
                      isInvalid={!!errors.cargo}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {!!errors.cargo && errors.cargo.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col></Col>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='col-sm-4'>
                  <Form.Group controlId='telefono' className='mb-3 position-relative'>
                    <CustomLabel required={true} text='Teléfono' type='danger' />
                    <Form.Control
                      {...register('telefono', {
                        required: 'Este campo es requerido',
                      })}
                      isInvalid={!!errors.telefono}
                      style={{width: '90%'}}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {!!errors.telefono && errors.telefono.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='col-sm-4'>
                  <Form.Group controlId='sexo' className='mb-3 position-relative'>
                    <CustomLabel required={true} text='Género' type='danger' />
                    <Controller
                      name='sexo'
                      control={control}
                      render={({field}) => {
                        return (
                          <Select
                            {...field}
                            isClearable
                            isSearchable
                            defaultValue={null}
                            options={labelSexo}
                            placeholder={'Seleccione su Género'}
                            noOptionsMessage={() => 'Sin resultados'}
                            styles={!!errors.sexo ? customStyles : {}}
                          />
                        )
                      }}
                      rules={{required: 'Este campo es requerido'}}
                    />
                    <span className='text-danger'>{!!errors.sexo && errors.sexo.message}</span>
                  </Form.Group>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                <Col></Col>
                <Col className='col-sm-4'>
                  <Form.Group controlId='edad' className='mb-3 position-relative'>
                    <CustomLabel required={true} text='Edad' type='danger' />
                    <Controller
                      name='edad'
                      control={control}
                      render={({field}) => {
                        return (
                          <Select
                            {...field}
                            isClearable
                            isSearchable
                            defaultValue={null}
                            options={labelEdades && labelEdades.data}
                            placeholder={'Seleccione su Rango de Edad'}
                            noOptionsMessage={() => 'Sin resultados'}
                            styles={!!errors.edad ? customStyles : {}}
                            className='custom-width-90'
                          />
                        )
                      }}
                      rules={{required: 'Este campo es requerido'}}
                    />
                    <span className='text-danger'>{!!errors.edad && errors.edad.message}</span>
                  </Form.Group>
                </Col>
                <Col className='col-sm-4'>
                  <Form.Group controlId='etnia' className='mb-3 position-relative'>
                    <CustomLabel required={true} text='Grupo Étnico' type='danger' />
                    <Controller
                      name='etnia'
                      control={control}
                      render={({field}) => {
                        return (
                          <Select
                            {...field}
                            isClearable
                            isSearchable
                            defaultValue={null}
                            options={labelEtnia}
                            placeholder={'Seleccione su Grupo Étnico'}
                            noOptionsMessage={() => 'Sin resultados'}
                            styles={!!errors.etnia ? customStyles : {}}
                          />
                        )
                      }}
                      rules={{required: 'Este campo es requerido'}}
                    />
                    <span className='text-danger'>{!!errors.etnia && errors.etnia.message}</span>
                  </Form.Group>
                </Col>
                <Col></Col>
              </div>
              <div className='d-flex justify-content-center'>
                <Col></Col>
                <Col className='col-sm-4'>
                  <Form.Group controlId='enterado' className='mb-3 position-relative'>
                    <CustomLabel
                      required={true}
                      text='Como te enteraste de este evento?'
                      type='danger'
                    />
                    <Form.Control
                      {...register('enterado', {
                        required: 'Este campo es requerido',
                      })}
                      style={{width: '90%'}}
                      isInvalid={!!errors.enterado}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {!!errors.enterado && errors.enterado.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className='col-sm-4'>
                  <Form.Group controlId='curso' className='mb-3 position-relative'>
                    <CustomLabel
                      required={true}
                      text='Menciona que otro curso te gustaría recibir'
                      type='danger'
                    />
                    <Form.Control
                      {...register('curso', {
                        required: 'Este campo es requerido',
                      })}
                      isInvalid={!!errors.curso}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {!!errors.curso && errors.curso.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col></Col>
              </div>

              <div className='d-flex justify-content-center mt-20'>
                <Button
                  className='btn btn-success btn-lg'
                  style={{width: '50%', marginBottom: '200px'}}
                  type='submit'
                >
                  Enviar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}

      {warningAlert === true && handleErrorLabReg()}
      {successAlert === true && handleSuccessLabReg(() => handleRedirectAward())}

      <>
        <ModalDiplomaExt
          modal={showVisorDiploma}
          toggle={setShowVisorDiploma}
          titulo={'Descargar Diploma'}
          pdf={pdf}
        />
      </>
    </>
  )
}

export default RegistroExterno
