/* eslint-disable @typescript-eslint/no-unused-vars */
import { Suspense, lazy, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import IT from './private/IT/index'
import Almacen from './private/almacen/index'
import Compras from './private/Compras/index'
import Gestiones from './private/gestiones/index'
import Perfil from '../views/Perfil/'
import { UserContext } from '../../app/utility/context/userContext'
// import Bienvenido from '../views/tablero/Bienvenido'
import Auditoria from './private/Auditoria/index'
import Planificacion from './private/Planificacion/index'
import Capacitaciones from './private/Capacitaciones/index'
// import PublicCapacitaciones from './private/Capacitaciones/publicCapacitaciones'
import Inventarios from './private/Inventarios/index'
import Archivo from './private/Archivo/index'
import Recepcion from './private/Recepcion/index'
import Contabilidad from './private/Contabilidad/index'
import Juridico from './private/Juridico/index'
import RRHH from './private/rrhh/index'
import LibrosFormas from './private/LibrosFormas/index'
import Public from './private/public/index'
import Seguimiento from './private/Seguimiento/index'
import Supervision from './private/DespachoInspeccionG/index'
import DespachoNuevo from './private/DespachoNuevo/index'
import InformacionPublica from './private/InformacionPublica/index'
import Fiscalizacion from './private/Fiscalizacion/index'
import Viaticos from './private/Viaticos/index'
import Transportes2 from './private/Transportes2/index'
import Caja from './private/Caja/index'
import VigilanciaAlertaTemprana from './private/VigilanciaAlertaTemprana/index'
import InventarioFisico from './private/InventarioFisico/index'
import RecepcionRegional from './private/RecepcionRegional/index'
import FondosRotativos from './private/FondosRotativos/index'
import MisNotificacion from './private/Notificacion/index'
import CapacitacionesHechas from './private/Capacitaciones/CapasHechas'
import ReqTraslado from './private/RequerimientoTraslado/index'
import EnvioProvidenciasOficios from './private/EnvioProvidenciaOficio/index'
import OperadorRegional from './private/OperarRegional/Operar/index'
import OperadorRegionalMixto from './private/OperarRegional/OperarMixto/index'
import Metas from './private/metas/index'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../views/tablero/index'))
  const CatalogoInsumos = lazy(() => import('../views/gestiones/catalogoInsumos/index'))

  const { Roles, userRoles } = useContext(UserContext)
  const Manuales = lazy(() => import('../views/manuales/index'))
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={BuilderPageWrapper} />
        <Route path='/catalogo-insumos' component={CatalogoInsumos} />
        <Route path='/tablero' component={Metas} />
        {userRoles.includes(Roles.adm) && <Route path='/it' component={IT} />}
        {(userRoles.includes(Roles.adm) || userRoles.includes(Roles.a_desp)) && (
          <Route path='/despacho-inspeccion' component={DespachoNuevo} />
        )}
        {((userRoles.includes(Roles.sup_pet) || userRoles.includes(Roles.sup_quet) || userRoles.includes(Roles.sup_cob)|| userRoles.includes(Roles.sup_hue)|| userRoles.includes(Roles.sup_cen)) ) &&  (
          <Route path='/supervision-inspeccion' component={Supervision} />
        )}
        {userRoles.includes(Roles.adm) || userRoles.includes(Roles.a_public) ? (
          <Route path='/informacion-publica' component={InformacionPublica} />
        ) : (
          ''
        )}
        {(userRoles.includes(Roles.jefe_fisc) ||
          userRoles.includes(Roles.sup_fisc) ||
          userRoles.includes(Roles.insp_fisc) ||
          userRoles.includes(Roles.jef_reg_fisc) ||
          userRoles.includes(Roles.insp_reg_fisc) ||
          userRoles.includes(Roles.sup_reg_fisc) ||
          userRoles.includes(Roles.sec_jef_fisc) ||
          userRoles.includes(Roles.sec_sup_fisc) ||
          userRoles.includes(Roles.sec_jef_reg_fisc) ||
          userRoles.includes(Roles.sec_sup_reg_fisc) ||
          userRoles.includes(Roles.user_ext_fisc)) && (
            <Route path='/fiscalizacion' component={Fiscalizacion} />
          )}
        {(userRoles.includes(Roles.j_seg || Roles.asist_seg) ||
          userRoles.includes(Roles.an_seg || Roles.agl_seg) ||
          userRoles.includes(Roles.asist_seg) ||
          userRoles.includes(Roles.sec_seg) ||
          userRoles.includes(Roles.agl_seg)) && (
            <Route path='/seguimiento' component={Seguimiento} />
          )}

        {<Route path='/perfil' component={Perfil} />}

        {userRoles.includes(Roles.a_bod) ||
          userRoles.includes(Roles.o_bod) ||
          userRoles.includes(Roles.r_bod) ||
          userRoles.includes(Roles.a_fin) ? (
          <Route path='/almacen' component={Almacen} />
        ) : (
          ''
        )}

        {<Route path='/gestiones' component={Gestiones} />}

        {userRoles.includes(Roles.a_cpr) ||
          userRoles.includes(Roles.r_cpr) ||
          userRoles.includes(Roles.p_cpr) ||
          userRoles.includes(Roles.d_cpr) ||
          userRoles.includes(Roles.a_fin) ? (
          <Route path='/compras' component={Compras} />
        ) : (
          ''
        )}

        {<Route path='/auditoria' component={Auditoria} />}

        {<Route path='/planificacion' component={Planificacion} />}

        {<Route path='/capacitaciones' component={Capacitaciones} />}

        {userRoles.includes(Roles.a_inv) || userRoles.includes(Roles.a_fin) ? (
          <Route path='/inventarios' component={Inventarios} />
        ) : (
          ''
        )}

        <Route path='/inventario-fisico' component={InventarioFisico} />

        {userRoles.includes(Roles.adm_arc) || userRoles.includes(Roles.a_fin) ? (
          <Route path='/archivo' component={Archivo} />
        ) : (
          ''
        )}
        {userRoles.includes(Roles.adm_rec) && <Route path='/recepcion' component={Recepcion} />}
        {userRoles.includes(Roles.adm_reg) && (
          <Route path='/regional' component={RecepcionRegional} />
        )}
        {userRoles.includes(Roles.a_cont) ||
          userRoles.includes(Roles.aux_cont) ||
          userRoles.includes(Roles.a_inv) ||
          userRoles.includes(Roles.a_fin) ? (
          <Route path='/contabilidad' component={Contabilidad} />
        ) : (
          ''
        )}
        {userRoles.includes(Roles.adm_juri) && <Route path='/juridico' component={Juridico} />}

        {userRoles.includes(Roles.a_rrhh) && <Route path='/rrhh' component={RRHH} />}

        {userRoles.includes(Roles.adm_caja) || userRoles.includes(Roles.a_fin) ? (
          <Route path='/caja' component={Caja} />
        ) : (
          ''
        )}

        {userRoles.includes(Roles.adm_aler) && (
          <Route path='/vigilancia-alerta-temprana' component={VigilanciaAlertaTemprana} />
        )}

        {userRoles.includes(Roles.a_libfor) && (
          <Route path='/libros-formas' component={LibrosFormas} />
        )}
        {userRoles.includes(Roles.accs_coope) && <Route path='/public' component={Public} />}
        {/* <Route path='/public-capacitaciones' component={PublicCapacitaciones} /> */}

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />

        {userRoles.includes(Roles.a_viatc) || userRoles.includes(Roles.a_fin) ? (
          <Route path='/viaticos' component={Viaticos} />
        ) : (
          ''
        )}
        {userRoles.includes(Roles.a_trans) || userRoles.includes(Roles.a_fin) ? (
          <Route path='/transportes' component={Transportes2} />
        ) : (
          ''
        )}
        {userRoles.includes(Roles.enc_conta) && (
          <Route path='/fondos-rotativos' component={FondosRotativos} />
        )}
        {<Route path='/mis-notificaciones' component={MisNotificacion} />}
        {<Route path='/mis-capacitaciones' component={CapacitacionesHechas} />}
        {<Route path='/rtraslado' component={ReqTraslado} />}
        {<Route path='/denvio' component={EnvioProvidenciasOficios} />}
        {userRoles.includes(Roles.op_reg) ? (
          <Route path='/compras' component={OperadorRegional} />
        ) : (
          ''
        )}
        {userRoles.includes(Roles.op_reg) ? (
          <Route path='/mixtos' component={OperadorRegionalMixto} />
        ) : (
          ''
        )}

        <Route path='/manuales' component={Manuales} />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
