import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const Recepcion = () =>{
     return (
       <AsideMenuItemWithSub
         to='/recepcion'
         title={'Recepción'}
         featherIcon={<i className='bi bi-file-earmark-text fs-2'></i>}
       >
         <AsideMenuItem
           to='/recepcion/tipoLlamada'
           title='Tipo Llamada'
           icon='/media/icons/duotune/general/gen022.svg'
         />
         <AsideMenuItem
           to='/recepcion/tipoEnvio'
           title='Tipo Envío'
           icon='/media/icons/duotune/general/gen022.svg'
         />
         <AsideMenuItem
           to='/recepcion/llamadasEntrantes'
           title='Llamadas Entrantes'
           icon='/media/icons/duotune/general/gen022.svg'
         />
         <AsideMenuItem
           to='/recepcion/llamadasSalientes'
           title='Llamadas Salientes'
           icon='/media/icons/duotune/general/gen022.svg'
         />
         <AsideMenuItem
           to='/recepcion/envios'
           title='Envíos'
           icon='/media/icons/duotune/general/gen022.svg'
         />
         <AsideMenuItem
           to='/recepcion/recepcion'
           title='Recepción'
           icon='/media/icons/duotune/general/gen022.svg'
         />
         {/* <AsideMenuItem
          to='/recepcion/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}
       </AsideMenuItemWithSub>
     )
}

export default Recepcion