/* eslint-disable react/jsx-no-target-blank */

import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'

const IT = () => {

  return (
    <AsideMenuItemWithSub
      to='/caja'
      title="Caja Admin. Financiero"
      biIcon="bi-cash"
    >
      <AsideMenuItem
        to='/caja/formato-63-A2'
        title="Formato 63-A2"
        biIcon="bi bi-file-ruled-fill"
      />
      <AsideMenuItem
        to='/caja/formato-aporte-gobierno-central'
        title="Formato Gobierno Central"
        biIcon="bi bi-file-earmark-post"
      />
       <AsideMenuItem
        to='/caja/carga-historica'
        title="Carga Historica"
        biIcon="bi bi-clock-history"
      />
      <AsideMenuItem
        to='/caja/formato-integracion-contabilidad'
        title="Formato Integración Contabilidad"
        biIcon="bi bi-table"
      />
      <AsideMenuItem
        to='/caja/fechas-corto-plazo'
        title="Fechas Corto Plazo"
        biIcon="bi-calendar-week"
      />
      <AsideMenuItem
        to='/caja/modalidad-compra'
        title='Modalidad de Compra'
        biIcon='bi-currency-exchange'
      />
       {/* <AsideMenuItem
          to='/caja/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}
    </AsideMenuItemWithSub>
  )
}

export default IT