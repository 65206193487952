import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const TipoLlamadaRegional = lazy(() => import('../../../views/RecepcionRegional/TipoLLamada/index')),
  LlamadasEntrantesRegional = lazy(() => import('../../../views/RecepcionRegional/LlamadasEntrantes/index')),
  LlamadasSalientesRegional = lazy(() => import('../../../views/RecepcionRegional/LlamadasSalientes/index')),
  TipoEnvioRegional = lazy(() => import('../../../views/RecepcionRegional/TipoEnvio/index')),
  EnviosRegional = lazy(() => import('../../../views/RecepcionRegional/Envios/index')),
  RecepcionRegional = lazy(() => import('../../../views/RecepcionRegional/Recepcion/index'))
const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/tipoLlamada-regional`} component={TipoLlamadaRegional} />
      <Route path={`${props.match.path}/llamadasEntrantes-regional`} component={LlamadasEntrantesRegional} />
      <Route path={`${props.match.path}/llamadasSalientes-regional`} component={LlamadasSalientesRegional} />
      <Route path={`${props.match.path}/tipoEnvio-regional`} component={TipoEnvioRegional} />
      <Route path={`${props.match.path}/envios-regional`} component={EnviosRegional} />
      <Route path={`${props.match.path}/recepcion-regional`} component={RecepcionRegional} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
