/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import { useContext } from 'react'
import { AsideMenuItem } from './AsideMenuItem'
import IT from './Menu/IT/index'
import Gestiones from './Menu/Gestiones/index'
import RutasExternas from './Menu/RutasExternas/index'
import Almacen from './Menu/Almacen/index'
import Compras from './Menu/Compras/index'
import Planificacion from './Menu/Planificacion/index'
import Auditoria from './Menu/Auditoria/index'
import Capacitaciones from './Menu/Capacitaciones/index'
import PublicCapacitaciones from './Menu/Capacitaciones/publicCapacitaciones'
import Inventarios from './Menu/Inventarios/index'
import Archivo from './Menu/Archivo/index'
import Recepcion from './Menu/Recepcion'
import RecepcionRegional from './Menu/RecepcionRegional'
import Contabilidad from './Menu/Contabilidad/index'
import Juridico from './Menu/Juridico/index'
import RRHH from './Menu/rrhh/index'
import LibrosFormas from './Menu/LibrosFormas/index'
import Seguimiento from './Menu/Seguimiento/index'
import Fiscalizacion from './Menu/Fiscalizacion/index'
import Viaticos from './Menu/Viaticos/index'
import Transportes2 from './Menu/Transportes2/index'
import Supervision from './Menu/DespachoInspeccionG/index'
import DespachoNuevo from './Menu/DespachoNuevo/index'
import InfoPublica from './Menu/InformacionPublica/index'
import Caja from './Menu/Caja/index'
import VigilanciaAlertaTemp from './Menu/VigilanciaAlertaTemprana/index'
import InventarioFisico from './Menu/InventarioFisico/index'
import FondosRotativos from './Menu/FondosRotativos'
import MisNotificaciones from './Menu/MisNotificaciones'
import RequerimientoTraslado from './Menu/RequerimientoTraslado'
import { UserContext } from '../../../../app/utility/context/userContext'
import ProvidenciaOficio from './Menu/ProvidenciaOficio'
import OperarRegional from './Menu/OperadorRegional/Operar'
import OperarRegionalMixto from './Menu/OperadorRegional/OperarMixtos'

export function AsideMenuMain() {

  const {Roles, userRoles} = useContext(UserContext)

  return (
    <>
      {userRoles.includes(Roles.accs_coope) && (
        <AsideMenuItem
          to={'/public/cooperativa'}
          icon='/media/icons/duotune/art/art002.svg'
          title={'Sección Cooperativa'}
          fontIcon='bi-app-indicator'
        />
      )}
      {userRoles.includes(Roles.accs_coope) === false && (
        <AsideMenuItem
          to={'/dashboard'}
          icon='/media/icons/duotune/art/art002.svg'
          title='Tablero'
          fontIcon='bi-app-indicator'
        />
      )}
        {
          (
            ((
            userRoles.includes(Roles.accs_coope) === false)) && (
            userRoles.includes(Roles.adm))) && <AsideMenuItem
            to={'/tablero/metas'}
            icon='/media/icons/duotune/graphs/gra004.svg'
            title='Metas'
            fontIcon='bi-circle'
          />
        }
        {
          ((userRoles.includes(Roles.sup_pet) || 
          userRoles.includes(Roles.sup_quet) || 
          userRoles.includes(Roles.sup_cob)|| 
          userRoles.includes(Roles.sup_hue)|| 
          userRoles.includes(Roles.sup_cen) ||
          userRoles.includes(Roles.adm_aler) ||
          userRoles.includes(Roles.a_cap) ||
          userRoles.includes(Roles.j_seg) ||
          userRoles.includes(Roles.a_libfor) ||
          userRoles.includes(Roles.a_libfor) ||
          userRoles.includes(Roles.jefe_fisc) ||
          userRoles.includes(Roles.jef_reg_fisc)) ) && <AsideMenuItem
          to={'/tablero/metas'}
          icon='/media/icons/duotune/graphs/gra004.svg'
          title='Metas'
          fontIcon='bi-circle'
        />
        }
      
      {/* {userRoles.includes(Roles.administrador) && <IT />} */}
      {userRoles.includes(Roles.accs_coope) === false && <Gestiones />}
      {userRoles.includes(Roles.accs_coope) && <RutasExternas />}

      {userRoles.includes(Roles.adm) && <IT />}
      {userRoles.includes(Roles.a_desp) && <DespachoNuevo />}
      {
        ((userRoles.includes(Roles.sup_pet) || userRoles.includes(Roles.sup_quet) || userRoles.includes(Roles.sup_cob)|| userRoles.includes(Roles.sup_hue)|| userRoles.includes(Roles.sup_cen)) ) && <Supervision />
      }
      {userRoles.includes(Roles.a_public) && <InfoPublica />}
      {(userRoles.includes(Roles.a_fisc) ||
        userRoles.includes(Roles.sup_fisc) ||
        userRoles.includes(Roles.jefe_fisc) ||
        userRoles.includes(Roles.insp_fisc) ||
        userRoles.includes(Roles.jef_reg_fisc) ||
        userRoles.includes(Roles.insp_reg_fisc) ||
        userRoles.includes(Roles.sup_reg_fisc) ||
        userRoles.includes(Roles.sec_jef_fisc) ||
        userRoles.includes(Roles.sec_sup_fisc) ||
        userRoles.includes(Roles.sec_jef_reg_fisc) ||
        userRoles.includes(Roles.sec_sup_reg_fisc) ||
        userRoles.includes(Roles.user_ext_fisc)) && (
          <Fiscalizacion Roles={Roles} userRoles={userRoles} /> 
        )}
      {userRoles.includes(Roles.adm_aler) && <VigilanciaAlertaTemp />}
      {(userRoles.includes(Roles.j_seg || Roles.asist_seg) ||
        userRoles.includes(Roles.an_seg || Roles.agl_seg) ||
        userRoles.includes(Roles.asist_seg) ||
        userRoles.includes(Roles.sec_seg) ||
        userRoles.includes(Roles.agl_seg)) && <Seguimiento />}
      {userRoles.includes(Roles.adm_caja) || userRoles.includes(Roles.a_fin) ? <Caja /> : ''}
      {userRoles.includes(Roles.a_bod) ||
        userRoles.includes(Roles.o_bod) ||
        userRoles.includes(Roles.r_bod) ||
        userRoles.includes(Roles.a_fin) ? (
        <Almacen Roles={Roles} />
      ) : (
        ''
      )}
      {userRoles.includes(Roles.a_trans) || userRoles.includes(Roles.a_fin) ? <Transportes2 /> : ''}
      {userRoles.includes(Roles.a_plnf) ||
        userRoles.includes(Roles.o_plnf) ||
        userRoles.includes(Roles.a_fin) ? (
        <Planificacion Roles={Roles} />
      ) : (
        ''
      )}
      {(userRoles.includes(Roles.a_aud) || userRoles.includes(Roles.aux_aud)) && (
        <Auditoria Roles={Roles} />
      )}
      {userRoles.includes(Roles.a_cap) && <Capacitaciones />}
      {(userRoles.includes(Roles.a_cpr) ||
        userRoles.includes(Roles.r_cpr) ||
        userRoles.includes(Roles.p_cpr) ||
        userRoles.includes(Roles.d_cpr) ||
        userRoles.includes(Roles.a_fin)) && <Compras />}
      {userRoles.includes(Roles.a_inv) || userRoles.includes(Roles.a_fin) ? <Inventarios /> : ''}
      {userRoles.includes(Roles.a_fijos) && <InventarioFisico />}
      {userRoles.includes(Roles.adm_arc) || userRoles.includes(Roles.a_fin) ? <Archivo /> : ''}
      {userRoles.includes(Roles.adm_rec) && <Recepcion />}
      {userRoles.includes(Roles.a_cont) ||
        userRoles.includes(Roles.aux_cont) ||
        userRoles.includes(Roles.a_inv) ||
        userRoles.includes(Roles.a_fin) ? (
        <Contabilidad />
      ) : (
        ''
      )}
      {userRoles.includes(Roles.adm_juri) && <Juridico />}
      {(userRoles.includes(Roles.a_rrhh) || userRoles.includes(Roles.op_rrhh)) && <RRHH />}
      {userRoles.includes(Roles.adm_reg) && <RecepcionRegional />}
      {userRoles.includes(Roles.a_libfor) && <LibrosFormas />}
      {/* {
        !userRoles.includes(Roles.a_cap) && userRoles.includes(Roles.accs_coope) === false &&
        <PublicCapacitaciones />
      } */}
      {userRoles.includes(Roles.a_viatc) || userRoles.includes(Roles.a_fin) ? <Viaticos /> : ''}
      {userRoles.includes(Roles.enc_conta) && <FondosRotativos />}
      {(userRoles.includes(Roles.adm) ||
        userRoles.includes(Roles.a_fin) ||
        userRoles.includes(Roles.jefe_fisc) ||
        userRoles.includes(Roles.sup_fisc) ||
        userRoles.includes(Roles.insp_fisc) ||
        userRoles.includes(Roles.jef_reg_fisc) ||
        userRoles.includes(Roles.insp_reg_fisc) ||
        userRoles.includes(Roles.sup_reg_fisc) ||
        userRoles.includes(Roles.sec_jef_fisc) ||
        userRoles.includes(Roles.sec_sup_fisc) ||
        userRoles.includes(Roles.sec_jef_reg_fisc) ||
        userRoles.includes(Roles.sec_sup_reg_fisc) ||
        userRoles.includes(Roles.user_ext_fisc) ||
        userRoles.includes(Roles.j_seg || Roles.asist_seg) ||
        userRoles.includes(Roles.an_seg || Roles.agl_seg) ||
        userRoles.includes(Roles.asist_seg) ||
        userRoles.includes(Roles.sec_seg) ||
        userRoles.includes(Roles.agl_seg) 
        ) && <RequerimientoTraslado />}
      {(userRoles.includes(Roles.adm) ||
        userRoles.includes(Roles.aux_cont) ||
        userRoles.includes(Roles.adm_rec) ||
        userRoles.includes(Roles.adm_arc) ||
        userRoles.includes(Roles.a_fin) ||
        userRoles.includes(Roles.a_public) ||
        userRoles.includes(Roles.a_desp) ||
        userRoles.includes(Roles.jefe_fisc) ||
        userRoles.includes(Roles.sup_fisc) ||
        userRoles.includes(Roles.insp_fisc) ||
        userRoles.includes(Roles.jef_reg_fisc) ||
        userRoles.includes(Roles.insp_reg_fisc) ||
        userRoles.includes(Roles.sup_reg_fisc) ||
        userRoles.includes(Roles.sec_jef_fisc) ||
        userRoles.includes(Roles.sec_sup_fisc) ||
        userRoles.includes(Roles.sec_jef_reg_fisc) ||
        userRoles.includes(Roles.sec_sup_reg_fisc) ||
        userRoles.includes(Roles.user_ext_fisc) ||
        userRoles.includes(Roles.j_seg || Roles.asist_seg) ||
        userRoles.includes(Roles.an_seg || Roles.agl_seg) ||
        userRoles.includes(Roles.asist_seg) ||
        userRoles.includes(Roles.sec_seg) ||
        userRoles.includes(Roles.agl_seg) ||
        userRoles.includes(Roles.a_bod) ||
        userRoles.includes(Roles.o_bod) ||
        userRoles.includes(Roles.r_bod) ||
        userRoles.includes(Roles.a_cpr) ||
        userRoles.includes(Roles.r_cpr) ||
        userRoles.includes(Roles.p_cpr) ||
        userRoles.includes(Roles.d_cpr) ||
        userRoles.includes(Roles.a_inv) ||
        userRoles.includes(Roles.a_cont) ||
        userRoles.includes(Roles.adm_juri) ||
        userRoles.includes(Roles.a_rrhh) ||
        userRoles.includes(Roles.adm_caja) ||
        userRoles.includes(Roles.adm_aler) ||
        userRoles.includes(Roles.a_libfor) ||
        userRoles.includes(Roles.a_viatc) ||
        userRoles.includes(Roles.a_trans) ||
        userRoles.includes(Roles.enc_conta)) && <ProvidenciaOficio />}
      {(userRoles.includes(Roles.adm) ||
        userRoles.includes(Roles.aux_cont) ||
        userRoles.includes(Roles.adm_rec) ||
        userRoles.includes(Roles.adm_arc) ||
        userRoles.includes(Roles.a_fin) ||
        userRoles.includes(Roles.a_public) ||
        userRoles.includes(Roles.a_desp) ||
        userRoles.includes(Roles.jefe_fisc) ||
        userRoles.includes(Roles.sup_fisc) ||
        userRoles.includes(Roles.insp_fisc) ||
        userRoles.includes(Roles.jef_reg_fisc) ||
        userRoles.includes(Roles.insp_reg_fisc) ||
        userRoles.includes(Roles.sup_reg_fisc) ||
        userRoles.includes(Roles.sec_jef_fisc) ||
        userRoles.includes(Roles.sec_sup_fisc) ||
        userRoles.includes(Roles.sec_jef_reg_fisc) ||
        userRoles.includes(Roles.sec_sup_reg_fisc) ||
        userRoles.includes(Roles.user_ext_fisc) ||
        userRoles.includes(Roles.j_seg || Roles.asist_seg) ||
        userRoles.includes(Roles.an_seg || Roles.agl_seg) ||
        userRoles.includes(Roles.asist_seg) ||
        userRoles.includes(Roles.sec_seg) ||
        userRoles.includes(Roles.agl_seg) ||
        userRoles.includes(Roles.a_bod) ||
        userRoles.includes(Roles.o_bod) ||
        userRoles.includes(Roles.r_bod) ||
        userRoles.includes(Roles.a_cpr) ||
        userRoles.includes(Roles.r_cpr) ||
        userRoles.includes(Roles.p_cpr) ||
        userRoles.includes(Roles.d_cpr) ||
        userRoles.includes(Roles.a_inv) ||
        userRoles.includes(Roles.a_cont) ||
        userRoles.includes(Roles.adm_juri) ||
        userRoles.includes(Roles.a_rrhh) ||
        userRoles.includes(Roles.adm_caja) ||
        userRoles.includes(Roles.adm_aler) ||
        userRoles.includes(Roles.a_libfor) ||
        userRoles.includes(Roles.a_viatc) ||
        userRoles.includes(Roles.a_trans) ||
        userRoles.includes(Roles.enc_conta)||
        (userRoles.includes(Roles.sup_pet) || userRoles.includes(Roles.sup_quet) || userRoles.includes(Roles.sup_cob)|| userRoles.includes(Roles.sup_hue)|| userRoles.includes(Roles.sup_cen) 
        )||
        userRoles.includes(Roles.a_plnf) ||
        userRoles.includes(Roles.o_plnf) ) && <MisNotificaciones />}
      {userRoles.includes(Roles.op_reg) && <OperarRegional />}
      {userRoles.includes(Roles.op_reg) && <OperarRegionalMixto />}
    </>
  )
}
