import {useContext} from 'react'
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'
import Admin from './Admin/index'
import Operar from './Operar/index'
import OperarMixtos from './OperarMixtas'
import {UserContext} from '../../../../../../app/utility/context/userContext'

const Compras = (Props: any) => {
  const {Roles, userRoles} = useContext(UserContext)
  return (
    <AsideMenuItemWithSub to='/compras' title={'Compras Admin. Financiero'} biIcon='bi-cart3'>
      {userRoles.includes(Roles.a_cpr) || userRoles.includes(Roles.a_fin) ? <Admin /> : ''}
      {userRoles.includes(Roles.a_cpr) ||
      userRoles.includes(Roles.r_cpr) ||
      userRoles.includes(Roles.p_cpr) ||
      userRoles.includes(Roles.d_cpr) ||
      userRoles.includes(Roles.a_fin) ? (
       // <Operar />
        ''
      ) : (
        ''
      )}
      {userRoles.includes(Roles.a_cpr) ||
      userRoles.includes(Roles.r_cpr) ||
      userRoles.includes(Roles.p_cpr) ||
      userRoles.includes(Roles.d_cpr) ||
      userRoles.includes(Roles.a_fin) ? (
        <OperarMixtos />
      ) : (
        ''
      )}

      {userRoles.includes(Roles.a_cpr) || userRoles.includes(Roles.a_fin) ? (
        <>
          <AsideMenuItem
            to='/compras/insumo/vigentes'
            title={'Gestión Catálogo Insumo'}
            biIcon='bi-circle'
          />
          <AsideMenuItem
            to='/compras/proceso-eventos'
            title={'Modalidad de Compra'}
            biIcon='bi-currency-exchange'
          />
          <AsideMenuItem
            to='/compras/informe-mensual'
            title={'Informe Mensual'}
            biIcon='bi-graph-up-arrow'
          />
          <AsideMenuItem
            to='/compras/razonamiento-facturas'
            title={'Razonamiento de Facturas'}
            biIcon='bi-file-earmark-text'
          />
          {/* <AsideMenuItem
            to='/compras/seccion-oficios-capacitacion'
            title='Sección de Oficios con Capacitación'
            icon='/media/icons/duotune/general/gen022.svg'
          /> */}
        </>
      ) : (
        ''
      )}
    </AsideMenuItemWithSub>
  )
}

export default Compras
