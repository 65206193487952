/* eslint-disable react/jsx-no-target-blank */

//import { useContext } from 'react'
import { AsideMenuItemWithSub } from '../../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../../AsideMenuItem'
//import { UserContext } from '../../../../../../../app/utility/context/userContext'

const Ejecucion = () => {
  //const { Roles, userRoles } = useContext(UserContext)
  return (

    <AsideMenuItemWithSub
      to='/vigilancia-alerta-temprana/ejecucion'
      title={"Ejecucion"}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
{/* 
      { 
       userRoles.includes(Roles.an_seg|| Roles.agl_seg) && 
        <AsideMenuItem
          to='/vigilancia-alerta-temprana/ejecucion/asignaciones'
          title={"Perfil de Cooperativas"}
          biIcon='bi bi-person-lines-fill'
        />

        
      } */}
      
      { 
      //  userRoles.includes(Roles.an_seg|| Roles.agl_seg) && 
        <AsideMenuItem
        to='/vigilancia-alerta-temprana/ejecucion/vatmisnombramientos'
        title={"Nombramientos Activos"}
        biIcon='bi bi-file-fill'
      />
      }
      {
      //  userRoles.includes(Roles.j_seg || Roles.asist_seg) && 
        <AsideMenuItem
        to='/vigilancia-alerta-temprana/ejecucion/vatnombramiento'
        title={"Revisión de Nombramientos Asignados"}
        biIcon='bi bi-check-square'
      />
      }
     
      
    </AsideMenuItemWithSub>
  )
}


export default Ejecucion
