import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const TipoBien = lazy(() => import('../../../views/Inventarios/TipoBien/index')),
      TipoMovimiento = lazy(() => import('../../../views/Inventarios/TipoMovimiento/index')),
      Solvencia = lazy(() => import('../../../views/Inventarios/Solvencia/index')),
      Correlativos = lazy(() => import('../../../views/Inventarios/Correlativo/index')),
      Traslados = lazy(() => import('../../../views/Inventarios/Traslados/index')),
      Tarjeta = lazy(() => import('../../../views/Inventarios/TarjetaResponsabilidad/index')),
      Bien = lazy(() => import('../../../views/Inventarios/Bien/index')),
      Marca = lazy(() => import('../../../views/Inventarios/Marca/index')),
      ModalidadCompra = lazy(() => import('../../../views/Compras/ProcesoDeEventos/index')),
      Cuentas = lazy(() => import('../../../views/Inventarios/Cuentas/index')),
      Kardex = lazy(() => import('../../../views/Inventarios/TarjetaKardex/index')),
      SeccionOficios = lazy(() => import('../../../views/Inventarios/Oficios/index'))
      
const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/correlativos`} component={Correlativos} />
      <Route path={`${props.match.path}/tipo-bien`} component={TipoBien} />
      <Route path={`${props.match.path}/tipo-movimiento`} component={TipoMovimiento} />
      <Route path={`${props.match.path}/solvencia`} component={Solvencia} />
      <Route path={`${props.match.path}/traslados`} component={Traslados} />
      <Route path={`${props.match.path}/bien`} component={Bien} />
      <Route path={`${props.match.path}/marca`} component={Marca} />
      <Route path={`${props.match.path}/modalidad-compra`} component={ModalidadCompra} />
      <Route path={`${props.match.path}/cuentas`} component={Cuentas} />
      <Route path={`${props.match.path}/tarjeta-responsabilidad`} component={Tarjeta} />
      <Route path={`${props.match.path}/kardex-inventario`} component={Kardex} />
      <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
