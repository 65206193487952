import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
// import { UserContext } from '../../../../app/utility/context/userContext'

const Expediente = lazy(() => import('./Expediente/index')),
  Ejecucion = lazy(() => import('./Ejecucion/index')),
  Catalogos = lazy(() => import('./Catalogos/index')),
  SeccionOficios = lazy(() => import('../../../views/Seguimiento/Oficios/index')),
  Metas = lazy(() => import('../../../views/Seguimiento/Metas/index'))

const Index = (props: any) => {
  // const { Roles, userRoles } = useContext(UserContext)

  return (
    <Switch>
      {
        // userRoles.includes(Roles.a_cpr) &&
        <>
          <Route path={`${props.match.path}/expediente`} component={Expediente} />
          <Route path={`${props.match.path}/ejecucion`} component={Ejecucion} />
          <Route path={`${props.match.path}/catalogos`} component={Catalogos} />
          <Route
            path={`${props.match.path}/seccion-oficios-capacitacion`}
            component={SeccionOficios}
          />
          <Route path={`${props.match.path}/metas`} component={Metas} />
        </>
      }
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
