import {useState, useEffect, useContext} from 'react'
import { Row, Col, Form, Button, Card } from "react-bootstrap-v5"
import { ContentContext } from './context'
import Select from 'react-select'
import { handleErrorToken, handleErrorLabReg, handleSuccessLabReg2  } from "../../../utility/sweetAlert";
import { useForm, Controller } from "react-hook-form"
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import CustomLabel from '../../../utility/customLabel'
import { Diploma } from "./export/Diploma"
import ModalDiplomaInt from '../../../utility/TemplatePdf/ModalDiplomaInt'
import { customStyles } from '../../../utility/Globales/index'
import { UserContext } from '../../../utility/context/userContext'



const Examen = ({ codigo, examen }) => {
  const { userAuth } = useContext(UserContext)
  const [ filtrado, setFiltrado ] = useState()
  const { register, handleSubmit, formState: { errors }, control, setValue, reset } = useForm()
  const { allData, allDataCapa, labelPersonas, labelEdades, errorToken, setPdf, pdf, showVisorDiploma, setShowVisorDiploma,
          total, setTotal, img, setImg, GenerarPlantillaDiploma, successAlert, setSuccessAlert, One, oneData } = useContext(ContentContext)
  const [respuestaCorrecta, setRespuestaCorrecta] = useState(Array(allData.length).fill(''));
  const showModal = async () => {
    const doc: any = await Diploma(
        img,
        userAuth,
        filtrado,
        total,
        oneData
    )
    await setShowVisorDiploma(true)
    await setPdf(doc)
  }

  const handleRespuestaChange = (index, value) => {
    const nuevasRespuestas = [...respuestaCorrecta];
    nuevasRespuestas[index] = value;
    setRespuestaCorrecta(nuevasRespuestas);
  };

  const handleFinishExam = () => {
    setFiltrado(allDataCapa.find((reg) => reg.token === codigo))
    One(parseInt(examen))
    //console.log(filtrado)

    //console.log(respuestaCorrecta)
    const noPreguntas = allData.length
    const respuestasSeleccionadas = respuestaCorrecta
    //console.log(noPreguntas)

    let contadorRespuestasCorrectas = 0;

    allData.forEach((pregunta, index) => {
      // Obtén la respuesta correcta para esta pregunta
      const respuestaC = pregunta.respuestaCorrecta;
      //console.log(respuestaC)
    
      // Obtén la respuesta seleccionada por el usuario
      const respuestaSeleccionada = respuestasSeleccionadas[index];
      //console.log(respuestaSeleccionada)
    
      // Compara la respuesta seleccionada con la respuesta correcta
      if (respuestaSeleccionada === respuestaC) {
        contadorRespuestasCorrectas++;
      }
    });
    
    //console.log(`Respuestas correctas: ${contadorRespuestasCorrectas}`);

    let totalPunteo = contadorRespuestasCorrectas * 100 / noPreguntas
    setTotal(totalPunteo)
    GenerarPlantillaDiploma(parseInt(examen))
  }

  const handleRedirectAward = () => {
    setSuccessAlert(false)
    showModal()
  }
  

  return (
    <>
        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'nowrap'}}>
          <div style={{background: '#FFD54F', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap'}}>
              <div className='d-flex justify-content-center'>
               <img src={toAbsoluteUrl("/media/logos/Logo.png")}  alt="logo" />
              </div>
              <div className='d-flex justify-content-center'>
                <h1>{`Bienvenido al Examen de la capacitación ${codigo} !`}</h1>
              </div>
          </div>
          <div style={{background: '#E0E0E0', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap'}}>
            <div className='d-flex justify-content-center mt-4'>
              <Col></Col>
              <Col className='col-sm-8'>
               {allData.map((preg, index) => (
                <Card className='my-4'>
                <Card.Header>
                  <Card.Title>
                    <CustomLabel required={true} text={preg.pregunta} type="danger" />
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                      <Form.Group controlId="custom" className="mb-3 position-relative">
                      
                      <div className='d-flex flex-column'>
                        <div className='d-flex justify-content-between' style={{marginLeft: '20px'}}>
                          <h4>• {preg.ResA}</h4>
                          <div>
                            {['radio'].map((type) => (
                            <div key={`default-${type}`} className="mb-3" style={{marginRight: '10px'}}>
                              <Form.Check // prettier-ignore
                                id={`default-${type}-A`}
                                label={''}
                                name="respuestaCorrecta"
                                value={preg.ResA}
                                checked={respuestaCorrecta[index] === preg.ResA} // Comparar con el valor de la respuesta
                                onChange={() => handleRespuestaChange(index, preg.ResA)}
                              />
                              </div>
                            ))}
                          </div>
                          
                        </div>
                        <div className='d-flex justify-content-between' style={{marginLeft: '20px'}}>
                          <h4>• {preg.ResB}</h4>
                          <div>
                            {['radio'].map((type) => (
                            <div key={`default-${type}`} className="mb-3" style={{marginRight: '10px'}}>
                              <Form.Check // prettier-ignore
                                id={`default-${type}-B`}
                                label={''}
                                name="respuestaCorrecta"
                                value={preg.ResB}
                                checked={respuestaCorrecta[index] === preg.ResB} // Comparar con el valor de la respuesta
                                onChange={() => handleRespuestaChange(index, preg.ResB)}
                              />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between' style={{marginLeft: '20px'}}>
                          <h4>• {preg.ResC}</h4>
                          <div>
                            {['radio'].map((type) => (
                            <div key={`default-${type}`} className="mb-3" style={{marginRight: '10px'}}>
                              <Form.Check // prettier-ignore
                                id={`default-${type}-C`}
                                label={''}
                                name="respuestaCorrecta"
                                value={preg.ResC}
                                checked={respuestaCorrecta[index] === preg.ResC} // Comparar con el valor de la respuesta
                                onChange={() => handleRespuestaChange(index, preg.ResC)}
                              />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between' style={{marginLeft: '20px'}}>
                          <h4>• {preg.ResD}</h4>
                          <div>
                            {['radio'].map((type) => (
                            <div key={`default-${type}`} className="mb-3" style={{marginRight: '10px'}}>
                              <Form.Check // prettier-ignore
                                id={`default-${type}-D`}
                                label={''}
                                name="respuestaCorrecta"
                                value={preg.ResD}
                                checked={respuestaCorrecta[index] === preg.ResD} // Comparar con el valor de la respuesta
                                onChange={() => handleRespuestaChange(index, preg.ResD)}
                              />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                  </Form.Group>
                </Card.Body>
                </Card>
               ))}
              </Col>
              <Col></Col>
            </div>
            
            <div className='d-flex justify-content-center mt-20'>
                <Button className='btn btn-success btn-lg' style={{width: '50%', marginBottom: '340px'}} onClick={() => handleFinishExam()}>Enviar</Button>
            </div>
          </div>
        </div>
        
        {successAlert === true && handleSuccessLabReg2(() => handleRedirectAward())}

        <div>
        <ModalDiplomaInt
          modal={showVisorDiploma}
          toggle={setShowVisorDiploma}
          titulo={"Descargar Diploma"}
          pdf={pdf}
          totalP={total}
        />
      </div>
    </>
  );

}

export default Examen