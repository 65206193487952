import jsPDF from 'jspdf'
import 'jspdf-autotable'

export function Diploma(base64, detalle, allData, total, allDataPlantilla) {
  const borderColor = [0, 0, 0]
  const fillColor = [255, 255, 255]

  const dateNow = new Date()

  function formatDate(fecha) {
    const opciones = {year: 'numeric', month: 'long', day: 'numeric'}
    return fecha.toLocaleDateString(undefined, opciones)
  }

  //console.log(base64)
  //console.log(detalle)

  //console.log(allDataPlantilla)

  function wrapText(doc, text, x, y, maxWidth, lineHeight) {
    var words = text.split(' ')
    var line = ''

    for (var i = 0; i < words.length; i++) {
      var testLine = line + words[i] + ' '
      var textSize = doc.getTextWidth(testLine)

      if (textSize > maxWidth && i > 0) {
        doc.text(line, x, y, {align: 'center'})
        line = words[i] + ' '
        y += lineHeight
      } else {
        line = testLine
      }
    }

    doc.text(line, x, y, {align: 'center'})
  }

  var doc = new jsPDF('l', 'pt', 'letter').setProperties({
      title: 'Formulario de Solicitud de Vehículo',
    }),
    totalPagesExp = '{total_pages_count_string}',
    pageSize = doc.internal.pageSize,
    pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight(),
    str = null,
    hoy = new Date(),
    rows = [],
    ColorFact = [0, 0, 0]

  function pageContent(data) {
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()

    doc.addImage(base64, 'PNG', 0, 0, pageWidth, pageHeight)
    doc.setFontSize(20) // Tamaño de fuente
    doc.setTextColor('#1A237E')
    doc.setFont('helvetica', 'bold') // Color del texto (negro)
    doc.text(`${detalle.nombreCompleto}`, 400, 320, {align: 'center'})

    doc.setFontSize(18) // Tamaño de fuente
    doc.setTextColor('#1A237E')
    doc.setFont('helvetica', 'normal') // Color del texto (negro)
    if (total >= 60) {
      doc.text(`Por participar y aprobar la capacitación denominada`, 400, 385, {align: 'center'})
    } else {
      doc.text(`Por su participación en la capacitación denominada`, 400, 385, {align: 'center'})
    }

    doc.setFontSize(17) // Tamaño de fuente
    doc.setTextColor('#1A237E')
    doc.setFont('helvetica', 'bold') // Color del texto (negro)
    wrapText(doc, `${allData.descripcion}`, 400, 385 + 20, 450, 20)

    doc.setFontSize(17) // Tamaño de fuente
    doc.setTextColor('#1A237E')
    doc.setFont('helvetica', 'normal') // Color del texto (negro)
    wrapText(
      doc,
      `Con una duración de ${allData.duracion} de forma virtual`,
      400,
      405 + 40,
      500,
      20
    )

    doc.setFontSize(15)
    doc.setTextColor('#1A237E')
    doc.setFont('helvetica', 'normal') // Color del texto (negro)
    doc.text(`Ciudad de Guatemala, ${formatDate(dateNow)}`, 400, 465, {align: 'center'})

    // doc.setLineWidth(2); // Establece el ancho de la línea
    // doc.setDrawColor('#1A237E'); // Establece el color de la línea (negro)
    // doc.line(60, 520, 330, 520);

    // doc.setFontSize(12); // Tamaño de fuente
    // doc.setTextColor('#1A237E');
    // doc.setFont("helvetica", "normal");// Color del texto (negro)
    // wrapText(doc, `${allDataPlantilla.nombreFirmaUno}`, 200, 480 + 60, 450, 20);

    // doc.setFontSize(8); // Tamaño de fuente
    // doc.setTextColor('#1A237E');
    // doc.setFont("helvetica", "normal");// Color del texto (negro)
    // wrapText(doc, `${allDataPlantilla.cargoFirmaUno}`, 200, 480 + 70, 450, 20);

    // doc.setLineWidth(2); // Establece el ancho de la línea
    // doc.setDrawColor('#1A237E'); // Establece el color de la línea (negro)
    // doc.line(460, 520, 730, 520);

    // doc.setFontSize(12); // Tamaño de fuente
    // doc.setTextColor('#1A237E');
    // doc.setFont("helvetica", "normal");// Color del texto (negro)
    // wrapText(doc, `${allDataPlantilla.nombreFirmaDos}`, 600, 480 + 60, 450, 20);

    // doc.setFontSize(8); // Tamaño de fuente
    // doc.setTextColor('#1A237E');
    // doc.setFont("helvetica", "normal");// Color del texto (negro)
    // wrapText(doc, `${allDataPlantilla.cargoFirmaDos}`, 600, 480 + 70, 450, 20);
  }

  // function bodyForm(data) {

  //     fechaYCorrelativo(doc)
  //     infoSolicitante(doc)
  //     tipoComision(doc)
  //     Acompanantes(doc)
  //     fechaYDevolucion(doc)
  //     lugarYDireccion(doc)
  //     firmasYSellos(doc)
  //     firmasYSellosDos(doc)
  //     footerContent(doc)

  // }

  pageContent()

  //bodyForm(doc);

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp)
  }
  return doc.output('datauristring')
  // doc.save(`${nombrePdf}.pdf`);
}

export default {Diploma}
