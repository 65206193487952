import {lazy, useContext} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import { UserContext } from '../../../../app/utility/context/userContext'

const Bancos = lazy(() => import('../../../views/Contabilidad/Bancos/index')),
  Bienes = lazy(() => import('../../../views/Contabilidad/Bienes/index')),
  Cuentas = lazy(() => import('../../../views/Contabilidad/Cuentas/index')),
  Conciliacion = lazy(() => import('../../../views/Contabilidad/ConciliacionBancaria/index')),
  LibroBancos = lazy(() => import('../../../views/Contabilidad/LibroBancos/index')),
  Usuarios = lazy(() => import('../../../views/Contabilidad/Usuario/index')),
  ModalidadCompra = lazy(() => import('../../../views/Compras/ProcesoDeEventos/index')),
  CreacionOperaciones = lazy(
    () => import('../../../views/Contabilidad/CreacionesOperaciones/index')
  ),
  Depreciaciones = lazy(() => import('../../../views/Contabilidad/Depreciacion/index')),
  SeccionOficios = lazy(() => import('../../../views/Contabilidad/Oficios/index'))

const Index = (props: any) => {
  const { Roles, userRoles } = useContext(UserContext)

  return (
    <Switch>
      {
        (userRoles.includes(Roles.a_cont) ? (
          <>
          <Route path={`${props.match.path}/bancos`} component={Bancos} />
          <Route path={`${props.match.path}/bienes`} component={Bienes} />
          <Route path={`${props.match.path}/cuentas`} component={Cuentas} />
          <Route path={`${props.match.path}/conciliacion`} component={Conciliacion} />
          <Route path={`${props.match.path}/libro-bancos`} component={LibroBancos} />
          <Route path={`${props.match.path}/operaciones`} component={CreacionOperaciones} />
          <Route path={`${props.match.path}/modalidad-compra`} component={ModalidadCompra} />
          <Route path={`${props.match.path}/depreciaciones`} component={Depreciaciones} />
          <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
          <Route path={`${props.match.path}/usuarios`} component={Usuarios} />
          </>
        ) : userRoles.includes(Roles.a_inv) ? (
          <>
          <Route path={`${props.match.path}/cuentas`} component={Cuentas} />
          <Route path={`${props.match.path}/depreciaciones`} component={Depreciaciones} />
          </>
        ) : (
          <>
          <Route path={`${props.match.path}/bancos`} component={Bancos} />
          <Route path={`${props.match.path}/bienes`} component={Bienes} />
          <Route path={`${props.match.path}/cuentas`} component={Cuentas} />
          <Route path={`${props.match.path}/conciliacion`} component={Conciliacion} />
          <Route path={`${props.match.path}/libro-bancos`} component={LibroBancos} />
          <Route path={`${props.match.path}/operaciones`} component={CreacionOperaciones} />
          <Route path={`${props.match.path}/modalidad-compra`} component={ModalidadCompra} />
          <Route path={`${props.match.path}/depreciaciones`} component={Depreciaciones} />
          <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
          </>
        ))
      }
      
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
