import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'
import Expedientes from './Expediente/index'
import Ejecucion from './Ejecucion/index'
import Catalogos from './Catalogos/index'
import {UserContext} from '../../../../../../app/utility/context/userContext'
import {useContext} from 'react'

const Seguimiento = (Props: any) => {
  const {Roles, userRoles} = useContext(UserContext)
  return (
    <AsideMenuItemWithSub to='/seguimiento' title={'Seguimiento'} biIcon='bi-arrow-repeat'>
      {(userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ||
        userRoles.includes(Roles.sec_seg)) && <Expedientes />}
      {(userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ||
        userRoles.includes(Roles.an_seg || Roles.agl_seg) ||  userRoles.includes(Roles.sec_seg)) && <Ejecucion />}
      {(userRoles.includes(Roles.j_seg) || userRoles.includes(Roles.asist_seg) ) && <Catalogos />}
      <AsideMenuItem
        to='/seguimiento/metas'
        title='Metas'
        icon='/media/icons/duotune/general/gen022.svg'
      /> 
      {/* 
      <AsideMenuItem
        to='/seguimiento/seccion-oficios-capacitacion'
        title='Sección de Oficios con Capacitación'
        icon='/media/icons/duotune/general/gen022.svg'
      /> 
       */}
    </AsideMenuItemWithSub>
  )
}

export default Seguimiento
