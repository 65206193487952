import clsx from 'clsx'
import { FC, useContext ,useEffect,useState} from 'react'
import { KTSVG,toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials'
import {useLayout} from '../../core'
import { UserContext } from '../../../../app/utility/context/userContext'
import { Badge } from 'react-bootstrap-v5'
import { NotificationsContext } from '../../../../app/utility/context/notificacionContext'
import FormularioNotificacion from '../../../../app/views/MisNotificaciones/Notificaciones/Form'
import * as Icons from 'react-feather'



const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

interface NavigatorWithNetworkInformation extends Navigator {
  connection: NetworkInformation & EventTarget;

}

interface NetworkInformation {
  downlink?: any;
  effectiveType?: string;
  onchange?: () => void;
  rtt?: number;
}
  
  const navigatorWithNetworkInformation = navigator as NavigatorWithNetworkInformation;
  
const Topbar: FC = () => {
  const {  sumaSinLeer } = useContext(NotificationsContext)
  const [previousSumaSinLeer, setPreviousSumaSinLeer] = useState(sumaSinLeer);
  const [badConection, setBadConection] = useState(false);
  const [sinRed, setSinRed] = useState(false);
  const [descarga, setDescarga] = useState(0);
  const { Roles,userRoles,userAuth } = useContext(UserContext)
  const {config} = useLayout(), 
  { aside } = config
  const item : any = localStorage.getItem('metronic-react-demo100')
  const parse : any = JSON.parse(item)
  var newStorage : any = [], newAside : any = []
  
const updateTheme = ( data : string) => {

  newAside = {
    display: parse.aside.display,
    fixed: parse.aside.fixed,
    hoverable: parse.aside.hoverable,
    menu: parse.aside.menu,
    menuIcon: parse.aside.menuIcon,
    minimize: parse.aside.minimize,
    minimized: parse.aside.minimized,
    theme: data 
  }

  newStorage = {
    content : parse.content,
    footer : parse.footer,
    header : parse.header,
    loader : parse.loader,
    main : parse.main,
    megaMenu : parse.megaMenu,
    pageTitle : parse.pageTitle,
    scrolltop : parse.scrolltop,
    toolbar : parse.toolbar,
    aside : newAside
  }
  localStorage.setItem('metronic-react-demo100',  JSON.stringify(newStorage))
  window.location.reload()
}

var audio = new Audio(toAbsoluteUrl('/media/notificacion.mp3'));
const [audioEnabled, setAudioEnabled] = useState(false);

const enableAudio = () => {
  setAudioEnabled(true);
};


useEffect(() => {
  // Asegúrate de que el usuario esté autenticado y haya una nueva notificación para leer
  if (userAuth?.id > 0 ){
    if( sumaSinLeer > previousSumaSinLeer) {
    audio.volume = 0.35;
    // Reproduce el sonido de la notificación, maneja la promesa por si acaso
    audio.play().catch((e) => console.error("Error al reproducir el audio:", e));
  }
}
  // Actualiza el estado para llevar la cuenta de las notificaciones sin leer
  setPreviousSumaSinLeer(sumaSinLeer);
}, [sumaSinLeer, userAuth?.id, audioEnabled]);


useEffect
(() => {
  let checkConnectionInterval;
  const checkConnectionQuality  = () => {
    const connection = navigatorWithNetworkInformation.connection 
    if (connection) {
      setDescarga(connection.downlink)
      if (!window.navigator.onLine) {
        setSinRed(true); 
      } else if (connection.downlink < 7)//Mbps 
      {
        setBadConection(true)
        
        setSinRed(false);

      } else {
        setBadConection(false)
        setSinRed(false);
        
      }
    }
  };
  const handleConnectionChange = () => {
    checkConnectionQuality(); // Verifica inmediatamente la calidad al detectar un cambio
  };

  checkConnectionInterval = setInterval(checkConnectionQuality, 3000); // Verifica cada 10 segundos

  if (navigatorWithNetworkInformation.connection) {
    navigatorWithNetworkInformation.connection.addEventListener('change', handleConnectionChange);
  }
  return () => {
    clearInterval(checkConnectionInterval); // Detiene la verificación periódica
    if (navigatorWithNetworkInformation.connection) {
      navigatorWithNetworkInformation.connection.removeEventListener('change', handleConnectionChange);
    }
  };
}
,[])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      
        {/* NOTIFICATIONS */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                {/* begin::Menu- wrapper */}
                {/* <div
                  className={clsx(
                    'btn btn-icon btn-active-light-primary position-relative',
                    'svg-icon-1'
                  )}
                  data-kt-menu-trigger='click'
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='bottom'
                >
                   {aside.theme === 'dark' ? <i className="bi bi-sun-fill fs-1" onClick={() => updateTheme('light')}></i> : <i className="bi bi-moon-stars-fill" onClick={() => updateTheme('dark')}></i> }
                  
                </div> */}
                
                {/* end::Menu wrapper */}
              </div>
              {sinRed ?
              <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
              
              <Icons.WifiOff className='mx-2' size={20} color={ 'black'} />
              
              <Badge pill bg={`dark`}>{'Sin Internet'}</Badge>
            </div>
            :<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
              
                <Icons.Wifi className='mx-2' size={20} color={badConection ? `${descarga < 5 ?'red':descarga >= 5 && descarga <= 7 &&'orange'} ` : 'green'} />
                
                <Badge pill bg={`${badConection ? `${descarga < 5 ?'danger':descarga >= 5 && descarga <= 7 &&'warning'} `  : 'success'}`}>{badConection ? `Señal ${descarga < 5 ?'Inestable':descarga >= 5 && descarga <= 7 &&'Regular'} ` : `Señal Estable `}</Badge>
              </div>}

              {!userRoles.includes(Roles.accs_coope)  && 
              <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                    
                      {
                        <FormularioNotificacion />
                      }
                    <Badge pill bg="danger">{sumaSinLeer}</Badge>

                    {/* <HeaderNotificationsMenu /> */}
              </div>}
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          
          <img src={userAuth && userAuth.foto} alt='metronic' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
