import { createContext, useState, useContext, useEffect } from 'react'
import { Notification } from '../../../utility/Notify/index'
import { PostRoute, GetRoute } from '../../../services/Private'
import { LoadingContext } from '../../../utility/Loading/provider'
import { useSWRConfig } from 'swr'
import { useRequest } from '../../../utility/Hooks/useRequestSWR'
export const ContentContext = createContext()

export const ContentProvider = ({ children, codigo }) => {

    const
        { mutate } = useSWRConfig(),
        // { setShowLoad } = useContext(LoadingContext),
        [show, setShow] = useState(false),
        [oneData, setOneData] = useState([]),
        [allData, setAllData] = useState([]),
        [dataPrevSave, setDataPrevSave] = useState([]),
        [categorias, setCategorias] = useState([]),
        [pdf, setPdf] = useState([]),
        [opcion, setOpcion] = useState(0),
        [errorToken, setErrorToken] = useState(false),
        [successAlert, setSuccessAlert] = useState(false),
        [warningAlert, setWarningAlert] = useState(false),
        [showVisorDiploma, setShowVisorDiploma] = useState(false),
        nameComponent = 'Tipo Edades',
        nameController = 'Capacitaciones',
        nameController2 = 'tipoEdades',
        nameController3 = 'Participante',
        nameController4 = 'Plantillas',
        nameFileExport = 'Reporte de Tipo Edades',
        { data: DataAll } = useRequest(`${nameController}/all`, 'GET'),
        { data: labelCooperativas } = useRequest(`${nameController}/label-cop`, 'GET'),
        { data: labelEdades } = useRequest(`${nameController2}/label`, 'GET'),

        toggleModal = data => {
            setOpcion(data)
            if (data === 1) { setOneData([]) }
            setShow(!show)
        },
        OneDiploma = async (data) => {
            const response = await PostRoute(`${nameController4}/one-archivo`, {id: data})
            setOneData((response.data.length) ? response.data[0] : [])
        },
        StoreUpdate = async (data) => {
            setDataPrevSave(data)
            let response = []
            response = await PostRoute(`${nameController3}/create`, data)
            if (response.response === 0) {
                setWarningAlert(true)
            } else {
                setSuccessAlert(true) 
            }
        },
        Actions = async (data) => {
            //setShowLoad(true)
                   const response = await PostRoute(`${nameController}/status`, {
        id: data.id,
        estado: data.idEstado === 1 ? 0 : 1,
      })
            Notification(response.message, 1)
            mutate(`${nameController}/all`)
            //setShowLoad(false)
        },
        value = {
            Actions,
            OneDiploma,
            setOpcion,
            StoreUpdate,
            toggleModal,
            allData,
            categorias,
            nameComponent,
            nameFileExport,
            oneData,
            opcion,
            show,
            errorToken, setErrorToken,
            labelCooperativas,
            labelEdades,
            successAlert, setSuccessAlert,
            warningAlert, setWarningAlert,
            showVisorDiploma, setShowVisorDiploma,
            pdf, setPdf,
            dataPrevSave, setDataPrevSave,
            OneDiploma
        }

    useEffect(() => {
        if (DataAll && DataAll.value === 1) {
            const codigoExistente = DataAll.data.some((dat) => dat.token === codigo);
        
            if (codigoExistente) {
              setAllData(DataAll.data);
              setErrorToken(false)
            } else {
              setErrorToken(true)
            }
          }
    }, [DataAll, codigo])

    return (
        <ContentContext.Provider value={value} >
            {children}
        </ContentContext.Provider>
    )
}