/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const ProvidenciaOficio = () => {
  return (
    <AsideMenuItemWithSub to='/denvio' title='Providencias Y Oficios' biIcon='bi-file-check'>
      <AsideMenuItem
        to='/denvio/envio-providencias-oficios'
        title='Providencias Y Oficios Recibidos'
        biIcon='bi-file-text'
      />
    </AsideMenuItemWithSub>
  )
}

export default ProvidenciaOficio
