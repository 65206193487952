import {useState, useEffect} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Form, Alert, Button} from 'react-bootstrap-v5'
import {userService} from '../../services/UserServices'
import {useIntl} from 'react-intl'
import {ServiceLogVSix, ServiceLogVFour} from './GetServiceLog'
import {browserName, osName, deviceType, getUA, deviceDetect, isWindows} from 'react-device-detect'
import {PostRoute} from '../../services/Public'
type SendLogin = {
  username: string
  password: string
}

const Login = () => {
  const divice = deviceDetect(window.navigator.userAgent)
  const [customUa, setCustomUa] = useState({})

  const functionServiceFetch = async () => {
    let serviceIp = ''
    const serviceFour = (await ServiceLogVFour()) || ''
    const serviceSix = (await ServiceLogVSix()) || ''

    if (serviceSix === '' && serviceFour !== '') {
      serviceIp = serviceFour
    } else if (serviceSix !== '' && serviceFour === '') {
      serviceIp = serviceSix
    } else if (serviceSix !== '' && serviceFour !== '') {
      serviceIp = serviceSix
    }

    setCustomUa({
      navegador: browserName,
      sistema: osName,
      descripcion: divice.userAgent === undefined ? getUA : divice.userAgent,
      modelo:
        divice.model === undefined
          ? isWindows
            ? 'PC-windows'
            : deviceType
            ? deviceType
            : 'PC-mac'
          : divice.model,
      version: divice.osVersion,
      proveedor:
        divice.vendor === undefined ? (isWindows ? 'PC-windows' : 'PC-mac') : divice.vendor,
      ip: serviceIp,
    })
  }

  const {
      register,
      handleSubmit,
      formState: {errors},
    } = useForm(),
    intl = useIntl(),
    [viewPass, setViewPass] = useState<boolean>(false),
    [messageError, setMessageError] = useState<String>(''),
    [validInput, setValidInput] = useState<boolean>(false),
    [loading, setLoading] = useState<boolean>(false),
    onSubmit = async (data: SendLogin) => {
      setLoading(true)
      //seccion vitacora
      const jsonDivice = {...customUa, usuario: data.username}
      await PostRoute(`dv/lg`, jsonDivice)
      userService.login(data).then(
        (user) => {
          window.location.href = '/'
        },
        (error) => {
          setValidInput(false)
          setMessageError(error)
          setLoading(false)
        }
      )
    }

  useEffect(() => {
    functionServiceFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='h1 text-uppercase'>{intl.formatMessage({id: 'AUTH.TITLE'})}</h3>
        <p className='text-muted fw-bolder'>{intl.formatMessage({id: 'AUTH.SUBTITLE'})}</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={handleSubmit((data: any) => onSubmit(data))}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        <div className='alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10'>
          <div className='d-flex flex-column text-center text-primary pe-0 pe-sm-10 fw-bolder'>
            {intl.formatMessage({id: 'AUTH.NOTIFY'})}
          </div>
          {/* 
                    <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                        <span className="svg-icon svg-icon-1 svg-icon-primary"></span>
                    </button> */}
        </div>

        <div className='form-group fv-plugins-icon-container mb-10'>
          <label className='form-label'>Usuario</label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.USUARIO'})}
            type='text'
            className={clsx(
              'form-control form-control-solid h-auto py-5 px-5',
              {
                'is-valid': validInput,
              },
              {
                'is-invalid': errors.username && errors.username.type === 'required',
              }
            )}
            {...register('username', {
              required: 'Este campo es requerido',
            })}
          />
          {errors.username && errors.username.type === 'required' && (
            <div className='fv-plugins-message-container text-danger text-center'>
              <div className='fv-help-block'>{errors.username.message}</div>
            </div>
          )}
        </div>
        <div className='form-group fv-plugins-icon-container mb-5'>
          <label className='form-label'>Contraseña</label>
          <div className='input-group'>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              type={viewPass ? 'text' : 'password'}
              className={clsx(
                'form-control form-control-solid h-auto py-5 px-5',
                {
                  'is-valid': validInput,
                },
                {
                  'is-invalid': errors.password && errors.password.type === 'required',
                }
              )}
              {...register('password', {
                required: 'Este campo es requerido',
              })}
            />
            <button
              className='btn btn-secondary'
              type='button'
              onClick={() => setViewPass(!viewPass)}
            >
              <i className={`bi bi-eye${viewPass ? '-slash' : ''}-fill`} />
            </button>
          </div>
          {errors.password && errors.password.type === 'required' && (
            <div className='fv-plugins-message-container text-danger text-center'>
              <div className='fv-help-block'>{errors.password.message}</div>
            </div>
          )}
        </div>
        {messageError && (
          <Form.Group>
            <Alert variant='alert-dismissible bg-light-danger text-danger fw-bolder text-center'>
              {messageError}
            </Alert>
          </Form.Group>
        )}

        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <Link
            to='/auth/forgot-password'
            className='text-black-50 text-hover-primary my-3 mr-2'
            id='kt_login_forgot'
          >
            {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})}
          </Link>
          <Button
            id='kt_login_signin_submit'
            type='submit'
            className={`btn btn-primary px-5 py-4 my-3 d-flex`}
          >
            <span className='fw-bolder'>
              {intl.formatMessage({id: 'AUTH.GENERAL.LOGIN_BUTTON'})}
            </span>
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div>
        {/* <div className="text-gray-500 text-center fw-semibold fs-6">
                ¿Nuevo en nuestra plataforma?<Link
                        to="/auth/register"
                        className="link-primary"
                        id="kt_login_forgot"
                    >  Crear Cuenta</Link>
                </div> */}
      </form>
    </div>
  )
}

export default Login
