import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// import { UserContext } from '../../../../app/utility/context/userContext'

const
    PlanAnual = lazy(() => import('../../../views/Fiscalizacion/PlanAnual')),
    HorasEfectivas = lazy(() => import('../../../views/Fiscalizacion/HorasEfectivas')),
    Nombramientos = lazy(() => import('../../../views/Fiscalizacion/Nombramientos')),
    NombramientosInspectores = lazy(() => import('../../../views/Fiscalizacion/RevNombramientosInspectores')),
    ReportePorGenero = lazy(() => import('../../../views/Fiscalizacion/ReportePorGenero')),
    ReporteDeMetas = lazy(() => import('../../../views/Fiscalizacion/ReporteMetas')),
    ReporteGeneral = lazy(() => import('../../../views/Fiscalizacion/ReporteGeneral')),
    Asignaciones = lazy(() => import('../../../views/Fiscalizacion/Asignaciones/index')),
    DetalleAsignaciones = lazy(() => import('../../../views/Fiscalizacion/Asignaciones/segPersona/index')),
    Clasificacion = lazy(() => import('../../../views/Fiscalizacion/Clasificacion')),
    SeguimientoPersonaCoperativa = lazy(() => import('../../../views/Fiscalizacion/Asignaciones/segPersonaCooperativa/index')),
    Actividades = lazy(() => import('../../../views/Fiscalizacion/Catalogos/Actividad')),
    CategoriaObservaciones = lazy(() => import('../../../views/Fiscalizacion/Catalogos/CategoriaObservaciones')),
    TipoFiscalizacion = lazy(() => import('../../../views/Fiscalizacion/Catalogos/TipoFiscalizacion')),
    TipoInforme = lazy(() => import('../../../views/Fiscalizacion/Catalogos/TipoInforme')),
    TipoPermiso = lazy(() => import('../../../views/Fiscalizacion/Catalogos/TipoPermiso')),
    TipoRecomendacion = lazy(() => import('../../../views/Fiscalizacion/Catalogos/TipoRecomendacion')),
    TipoViatico = lazy(() => import('../../../views/Fiscalizacion/Catalogos/TipoViatico')),
    Requerimiento = lazy(() => import('../../../views/Fiscalizacion/Catalogos/Requerimiento')),
    TipoRequerimiento = lazy(() => import('../../../views/Fiscalizacion/Catalogos/TipoRequerimiento')),
    ChecklistInforme = lazy(() => import('../../../views/Fiscalizacion/Catalogos/ChecklistInforme')),
    EstadosLineaDeTiempo = lazy(() => import('../../../views/Fiscalizacion/Catalogos/EstadosLineaDeTiempo')),
    SeccionesInforme = lazy(() => import('../../../views/Fiscalizacion/Catalogos/SeccionesInforme')),
    AdminOficios = lazy(() => import('../../../views/Fiscalizacion/AdmOficios/index')),
    SeccionOficios = lazy(() => import('../../../views/Fiscalizacion/Oficios/index')),
    ObservacionesRecomendaciones = lazy(() => import('../../../views/Fiscalizacion/Catalogos/ObservacionesRecomendaciones')),
    CedulaDeNotificacion = lazy(() => import('../../../views/Fiscalizacion/CedulaDeNotificacion')),
    FiscaRegional = lazy(() => import('../../../views/DespachoInspeccion/fiscaregional/index')),
    RevisionAutorizadoInforme = lazy(() => import('../../../views/Fiscalizacion/revisionAutorizacionInformes/index'))
    const CorteFormas = lazy(() => import('../../../views/Fiscalizacion/CorteFormas/index'))
    const Cooperativas = lazy(() => import('../../../views/Fiscalizacion/Cooperativas/index'))
    const Metas = lazy(() => import('../../../views/Fiscalizacion/Metas/index'))
    const Sede = lazy(() => import('../../../views/Fiscalizacion/Sede/index'))
    

const Index = (props: any) => {

    return (
        <Switch>
            <Route path={`${props.match.path}/persona/:code/cooperativa/:codecooperativa/planificacion/:codeidplan`} component={SeguimientoPersonaCoperativa} />
            <Route path={`${props.match.path}/persona/:code`} component={DetalleAsignaciones} />
            <Route path={`${props.match.path}/asignaciones`} component={Asignaciones} />
            <Route path={`${props.match.path}/plan-anual`} component={PlanAnual} />
            <Route path={`${props.match.path}/horas-efectivas`} component={HorasEfectivas} />
            <Route path={`${props.match.path}/nombramientos`} component={Nombramientos} />
            <Route path={`${props.match.path}/nomb-inspectores`} component={NombramientosInspectores} />
            <Route path={`${props.match.path}/reporte-por-genero`} component={ReportePorGenero} />
            <Route path={`${props.match.path}/reporte-de-metas`} component={ReporteDeMetas} />
            <Route path={`${props.match.path}/reporte-general`} component={ReporteGeneral} />
            <Route path={`${props.match.path}/clasificacion-por-usuario`} component={Clasificacion} />
            <Route path={`${props.match.path}/catalogos/actividad`} component={Actividades} />
            <Route path={`${props.match.path}/catalogos/ObservacionesRecomendaciones`} component={ObservacionesRecomendaciones} />
            <Route path={`${props.match.path}/catalogos/secciones-informe`} component={SeccionesInforme} />
            <Route path={`${props.match.path}/catalogos/tipo-permiso`} component={TipoPermiso} />
            <Route path={`${props.match.path}/catalogos/tipo-informe`} component={TipoInforme} />
            <Route path={`${props.match.path}/catalogos/tipo-recomendacion`} component={TipoRecomendacion} />
            <Route path={`${props.match.path}/catalogos/tipo-fiscalizacion`} component={TipoFiscalizacion} />
            <Route path={`${props.match.path}/catalogos/tipo-requerimiento`} component={TipoRequerimiento} />
            <Route path={`${props.match.path}/catalogos/requerimiento`} component={Requerimiento} />
            <Route path={`${props.match.path}/catalogos/tipo-viatico`} component={TipoViatico} />
            <Route path={`${props.match.path}/catalogos/categoria-observaciones`} component={CategoriaObservaciones} />
            <Route path={`${props.match.path}/catalogos/checklist-informe`} component={ChecklistInforme} />
            <Route path={`${props.match.path}/catalogos/estados-linea-tiempo`} component={EstadosLineaDeTiempo} />
            <Route path={`${props.match.path}/adm_oficios/oficios`} component={AdminOficios} />
            <Route path={`${props.match.path}/corteFormas`} component={CorteFormas} />
            <Route path={`${props.match.path}/revision-autorizacion-informes`} component={RevisionAutorizadoInforme} />
            <Route path={`${props.match.path}/CedulaDeNotificacion`} component={CedulaDeNotificacion} />
            <Route path={`${props.match.path}/seccion-oficios-capacitacion`} component={SeccionOficios} />
            <Route exact path={`${props.match.path}/cooperativa/:codigo`} component={Sede} />
            <Route path={`${props.match.path}/cooperativas`} component={Cooperativas} />
            <Route path={`${props.match.path}/metas`} component={Metas} />
          <Route path={`${props.match.path}/informe-inspectores-revision/`} component={FiscaRegional} />

            <Redirect to='/error/404' />
        </Switch>
    )
}

export default Index
