import { ContentProvider } from './context'
import RegistroInterno from './RegistroInterno'
import './style.css'

const Index = ({ codigo }) => {
  return (
    <>
      <ContentProvider codigo={codigo}>
        <RegistroInterno codigo={codigo} />
      </ContentProvider>  
    </>
  )
}

export default Index