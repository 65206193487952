
// import { useContext } from 'react'
import { AsideMenuItemWithSub } from '../../AsideMenuItemWithSub'
import { AsideMenuItem } from '../../AsideMenuItem'
// import { UserContext } from '../../../../../../app/utility/context/userContext'

const Seguimiento = (Props: any) => {
  // const { Roles, userRoles } = useContext(UserContext)
  return (
    <AsideMenuItemWithSub
      to='/despacho-inspeccion'
      title={"Despacho"}
      biIcon="bi-file-earmark-text"
    >
      {/* 
      <AsideMenuItem
        to='/despacho-inspeccion/fiscalizacion'
        title={"Fiscalización"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> 
      */}
      <AsideMenuItem
        to='/despacho-inspeccion/fiscalizaciondsp'
        title={"Revisión de Informes de Fiscalización"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
       {/* <AsideMenuItem
        to='/despacho-inspeccion/fiscalizacion-plan-anual'
        title={"Fiscalización Plan Anual"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> */}
      <AsideMenuItem
        to='/despacho-inspeccion/informes-seguimiento'
        title={"Revisión de Constancias y Notificaciones de Seguimiento"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'  
      />
      <AsideMenuItem
          to='/despacho-inspeccion/cooperativasjefatura'
          title='Cooperativas'
          icon='/media/icons/duotune/general/gen022.svg'
        />
      
      <AsideMenuItem
        to='/despacho-inspeccion/despachortraslado'
        title='Seccion de Requerimiento de Traslado'
        icon='/media/icons/duotune/general/gen022.svg'
      /> 
      <AsideMenuItem
        to='/despacho-inspeccion/recibida'
        title={"Papelería Despacho"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />

       <AsideMenuItem
        to='/despacho-inspeccion/PapeleriaRecibidaSubDespacho'
        title={"Papelería SubDespacho"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />

       <AsideMenuItem
        to='/fiscalizacion/clasificacion-por-usuario'
        title={"Monitor de Fiscalización"}
        fontIcon='bi-archive'
        biIcon="bi-graph-up-arrow"
      /> 

      <AsideMenuItemWithSub
        to='/catalogos'
        title={"Catálogos"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
      <AsideMenuItem
        to='/despacho-inspeccion/catalogos/tipo-documento'
        title={"Tipo de Documento"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/caja'
        title={"Caja"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/despacho-inspeccion/caja/rango-excedente'
          title={"Rango de Excedentes"}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil001.svg'
        />
      </AsideMenuItemWithSub>
       <AsideMenuItem
        to='/despacho-inspeccion/envio-providencias-oficios'
        title={"Envío de Providencias y Oficios"}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      /> 

    </AsideMenuItemWithSub>
  )
}

export default Seguimiento