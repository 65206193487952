import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const FondosRotativos = lazy(() => import('../../../views/FondosRotativos/LibroBancos/index'))

const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/libro-bancos`} component={FondosRotativos} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
