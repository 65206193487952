/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const Notificaciones = () => {
  return (
    <AsideMenuItemWithSub to='/rtraslado' title='Gestion RT' biIcon='bi-file-check'>
      <AsideMenuItem to='/rtraslado/requerimientot' title='Recepcion de RT' biIcon='bi-file-text' />
    </AsideMenuItemWithSub>
  )
}

export default Notificaciones
