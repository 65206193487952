import {createContext, useState, useContext, useEffect} from 'react'
import {Notification} from '../../../utility/Notify/index'
import {GetRoute, PostRoute, PostRouteFD} from '../../../services/Private'
import {LoadingContext} from '../../../utility/Loading/provider'
import {useRequest} from '../../../utility/Hooks/useRequestSWR'
import {useSWRConfig} from 'swr'
import {NotificationsContext} from '../../../utility/context/notificacionContext'

export const ContentContext = createContext()

export const ContentProvider = ({children}) => {
  
  
  const {setShowLoad} = useContext(LoadingContext),
    {mutate} = useSWRConfig(),
    {AgregarNotificacion} = useContext(NotificationsContext),
    [show, setShow] = useState(false),
    [showExpediente, setShowExpediente] = useState(false),
    [showMF, setShowMF] = useState(false),
    [oneData, setOneData] = useState([]),
    [oneDataFoto, setOneDataFoto] = useState([]),
    [allDataEstadoCivil, setAllDataEstadoCivil] = useState([]),
    [allData, setAllData] = useState([]),
    [generos, setGeneros] = useState([]),
    [etnias, setEtnias] = useState([]),
    [sedes, setSedes] = useState([]),
    [ubicaciones, setUbicaciones] = useState([]),
    [puestos, setPuestos] = useState([]),
    [allDataSecciones, setAllDataSecciones] = useState([]),
    [allDataDepartamento, setAllDataDepartamento] = useState([]),
    [allDataMunicipio, setAllDataMunicipio] = useState([]),
    [allDataTipoSalarioDescuento, setAllDataTipoSalarioDescuento] = useState([]),
    [allDataProfesion, setAllDataProfesion] = useState([]),
    [allDataGradoAcademico, setAllDataGradoAcademico] = useState([]),
    [allDataTipoSangre, setAllDataTipoSangre] = useState([]),
    [allDataDetallePersonaSalarioDescuento, setAllDataDetallePersonaSalarioDescuento] = useState(
      []
    ),
    [allDataContratos, setAllDataContratos] = useState([]),
    [showFile, setShowFile] = useState([]),
    // [allDataAdjunto, setAllDataAdjunto] = useState([]),
    [Visualizar, setVisualizar] = useState(''),
    [renglones, setRenglones] = useState([]),
    [tipoPersonas, setTipoPersonas] = useState([]),
    [checkInput, setCheckInput] = useState([]),
    [dataPersona, setDataPersona] = useState([]),
    [allDataServiciosPrestados, setAllDataServiciosPrestados] = useState([]),
    [opcion, setOpcion] = useState(0),
    [idPersona, setIdPersona] = useState(0),
    [showDeactivate, setShowDeactivate] = useState(false),
    [step, setStep] = useState('0'),
    nameController = 'persona',
    nameController2 = 'estadoCivil',
    nameController3 = 'etnia',
    nameController4 = 'renglon',
    nameController5 = 'departamento',
    nameController6 = 'sedeIngecop',
    nameController7 = 'ubicacion',
    nameController8 = 'puesto',
    nameController9 = 'municipio',
    nameController10 = 'tipoSangre',
    nameController11 = 'profesion',
    nameController12 = 'gradoAcademico',
    nameController13 = 'tipoSalarioDescuento',
    //nameController14 = 'salarioDescuento',
    nameController15 = 'personaSalarioDescuento',
    nameController16 = 'serviciosPrestados',
    nameController17 = 'contratoPersona',
    nameFileExport = 'Reporte Personas',
    toggleModal = (data) => {
      setOpcion(data)
      setStep(0)
      mutate(`${nameController11}/label`, 'GET')
      //Si se cierra el modal se actualizan las personas
      if (data === 0) {
        All()
      }
      if (data === 1) {
        volver()
        AllDetallePersonaSalarioDescuento([])
        setOneData([])
      }
      setShow(!show)
    },
    toggleModalExpediente = (data) => {
      setIdPersona(data)

      setShowExpediente(!showExpediente)
    }
  const {data: dataAllProfesion} = useRequest(`${nameController11}/label`, 'GET')
  const {data: dataAllGradoAcademico} = useRequest(`${nameController12}/label`, 'GET')
  const {data: dataAllTipoSangre} = useRequest(`${nameController10}/label`, 'GET')
  const {data: dataAllUbicaciones} = useRequest(`${nameController7}/label`, 'GET')
  const {data: allDataAdjunto} = useRequest(
    `${'AdjuntoNombramientoRec'}/all-nombramiento`,
    'POSTSWR',
    {id: idPersona}
  )

  const toggleModalDeactivate = (data) => {
    if (data !== 0) setIdPersona(data)
    setShowDeactivate(!showDeactivate)
  }
  const All = async () => {
    const response = await GetRoute(`${nameController}/all-employee`)
    setAllData(response?.data !== null ? response.data : [])
  }

  // const AllProfesion = async () => {
  //   const response = await GetRoute(`${nameController11}/label`)
  //   setAllDataProfesion(response.data !== null ? response.data : [])
  // }

  // const AllGradoAcademico = async () => {
  //   const response = await GetRoute(`${nameController12}/label`)
  //   setAllDataGradoAcademico(response.data !== null ? response.data : [])
  // }

  const AllTipoSalarioDescuento = async () => {
    const response = await GetRoute(`${nameController13}/label`)
    setAllDataTipoSalarioDescuento(response.data !== null ? response.data : [])
  }

  const AllDetallePersonaSalarioDescuento = async (data) => {
    const response = await PostRoute(`${nameController15}/all`, {persona: data})
    setAllDataDetallePersonaSalarioDescuento(response.data !== null ? response.data : [])
  }
  const AllEtnias = async () => {
    const response = await GetRoute(`${nameController3}/label`)
    setEtnias(response.data !== null ? response.data : [])
  }

  // const AllTipoSangre = async () => {
  //   const response = await GetRoute(`${nameController10}/label`)
  //   setAllDataTipoSangre(response.data !== null ? response.data : [])
  // }

  const AllRenglones = async () => {
    const response = await GetRoute(`${nameController4}/label-renglon-personal`)
    setRenglones(response.data !== null ? response.data : [])
  }
  const AllEstadosCiviles = async () => {
    const response = await GetRoute(`${nameController2}/label`)
    setAllDataEstadoCivil(response.data !== null ? response.data : [])
  }
  const AllDepartamento = async () => {
    const response = await GetRoute(`${nameController5}/label`)
    setAllDataDepartamento(response.data !== null ? response.data : [])
  }
  const AllMunicipio = async (data) => {
    if (data) {
      const response = await PostRoute(`${nameController9}/label`, {departamento: data.value})
      setAllDataMunicipio(response.data !== null ? response.data : [])
    } else {
      setAllDataMunicipio([])
    }
  }

  const AllSedes = async () => {
    const response = await GetRoute(`${nameController6}/label`)
    setSedes(response.data !== null ? response.data : [])
  }

  // const AllUbicaciones = async () => {
  //   const response = await GetRoute(`${nameController7}/label`)
  //   setUbicaciones(response.data !== null ? response.data : [])
  // }

  const AllPuestos = async (data) => {
    if (data) {
      const response = await PostRoute(`${nameController8}/label`, {ubicacion: data.value})
      setPuestos(response.data !== null ? response.data : [])
    } else {
      setPuestos([])
    }
  }

  const AllServiciosPrestados = async () => {
    const response = await GetRoute(`${nameController16}/label`)
    setAllDataServiciosPrestados(response.data !== null ? response.data : [])
  }
  const One = async () => {
    setShowLoad(true)
    const responseP = await PostRoute(`${nameController}/get-perfil`)
    let idPersona = (responseP.data !== null ) ? responseP.data[0].idPersona : []
    const response = await PostRoute(`${nameController}/one`, {id: idPersona})
    setOneData(response.data !== null ? response.data[0] : [])

    //Opcion 10 - Boton siguiente hacia salarios - no se debe cambiar el estado del modal

    setShowLoad(false)
  }
  const StoreUpdate = async (data, one, tipo) => {
    setShowLoad(true)
    let response = []
    if (tipo === 1 || tipo === 10) {
      
      response = await PostRoute(`${nameController}/update-perfil`, data)
    }
    Notification(response.message, 1)
    //Opcion 1 = guardar - Despues de haber guardado el registro cierra el modal


        One()


    setShowLoad(false)
    AgregarNotificacion(
      'Nueva Usuario Asignado! ',
      ` Se ha asignado a un nuevo usuario por contrato`,
      false
    )
  }
  const Actions = async (data) => {
    setShowLoad(true)
    const response = await PostRoute(
      `${nameController}/${data.estado === '1' ? 'destroy' : 'active'}`,
      {id: data.id}
    )
    Notification(response.message, 1)
    All()
    setShowLoad(false)
  }
  const AllTipoPersona = async () => {
    const response = await GetRoute(`tipopersona/label`)
    setTipoPersonas(response.data !== null ? response.data : [])
  }
  const AllGenero = async () => {
    const response = await GetRoute(`genero/label`)
    setGeneros(response.data !== null ? response.data : [])
  }
  const oneFoto = (data) => {
    setShowMF(true)
    setOneDataFoto(data, 4)
  }
  const updateFoto = async (data) => {
    setShowLoad(true)
    let response = []
    response = await PostRouteFD('persona/update-image', data)
    Notification(response.message, 1)
    All()
    setShowMF(false)
    setShowLoad(false)
  }
  const volver = (stp) => {
    setStep(stp)
    setCheckInput([])
  }

  const Deactivate = async (data) => {
    setShowLoad(true)
    const response = await PostRoute(`${nameController}/deactivate`, data)
    Notification(response.message, 1)
    All()
    setShowLoad(false)
  }

  const Activate = async (data) => {
    setShowLoad(true)
    const response = await PostRoute(`${nameController}/status`, {id: data, estado: 1})
    Notification(response.message, 1)
    All()
    setShowLoad(false)
  }

  // -------------------------------------------------------------
  const StoreUpdateContratos = async (data) => {
    setShowLoad(true)
    const response = await PostRoute(`${nameController17}/${!data?.id ? 'create' : 'update'}`, data)
    AllContratos(data.persona)
    Notification(response.message, 1)
    setShowLoad(false)
    AgregarNotificacion('Nueva Contrato Asignado! ', ` Se ha asignado a un nuevo contrato`, false)
  }

  const AllContratos = async (data) => {
    const response = await PostRoute(`${nameController17}/all`, {
      persona: data,
    })
    setAllDataContratos(response.data !== null ? response.data : [])
  }

  const DestroyContratoPersona = async (data) => {
    setShowLoad(true)
    const response = await PostRoute(`${nameController17}/status`, {id: data.id, estado: 0})
    setShowDeactivate(false)
    Notification(response.message, 1)
    AllContratos(data.persona)
    setShowLoad(false)
  }

  const GetAllDataSecciones = async (id) => {
    if (id) {
      const response = await PostRoute(`${nameController7}/all-seccion-padre-label`, {id: id})
      setAllDataSecciones(response.data !== null ? response.data : [])
    } else {
      setAllDataSecciones([])
    }
  }
  // -------------------------------------------------------------
  const StoreAdjunto = async (data) => {
      setShowLoad(true)
      const response = await PostRouteFD(`${'AdjuntoNombramientoRec'}/create`, data)
      if (response.response === 0) {
        Notification(response.message, 2)
      } else {
        Notification(response.message, 1)
      }
      mutate([`${'AdjuntoNombramientoRec'}/all-nombramiento`, {id: idPersona}])

      // mutate([`${nameController}/all-memorias`, {codigo: codigo ?? 0}])
      setShowFile([])
      setShowLoad(false)
    },
    OneAdjunto = async (data) => {
      const response = await PostRoute(`${'AdjuntoNombramientoRec'}/one-archivo`, {id: data.id})
      setShowLoad(true)
      setVisualizar(response.data[0].adjunto)

      setShowLoad(false)
    },
    // AllAdjuntos = async (data) => {
    //   const response = await PostRoute(`${'AdjuntoNombramientoRec'}/all-nombramiento`, {
    //     id: data,
    //   })

    //   if (response.data !== null) {
    //     setAllDataAdjunto(response.data)
    //   } else {
    //     setAllDataAdjunto([])
    //   }
    // },
    ActionsAdjunto = async (data) => {
      setShowLoad(true)
      const response = await PostRoute(`${'AdjuntoNombramientoRec'}/status`, {
        id: data.id,
        estado: data.idEstado === 1 ? 0 : 1,
      })
      mutate([`${'AdjuntoNombramientoRec'}/all-nombramiento`, {id: idPersona}])

      Notification(response.message, 1)
      setShowLoad(false)
    }
  const value = {
    showFile,
    allDataAdjunto,
    Visualizar,
    setVisualizar,
    StoreAdjunto,
    OneAdjunto,
    ActionsAdjunto,
    toggleModalExpediente,
    showExpediente,
    setShowExpediente,
    All,
    One,
    volver,
    setStep,
    oneFoto,
    Actions,
    setOpcion,
    setShowMF,
    Deactivate,
    Activate,
    AllPuestos,
    updateFoto,
    StoreUpdate,
    toggleModal,
    AllMunicipio,
    setCheckInput,
    setDataPersona,
    allDataSecciones,
    GetAllDataSecciones,
    StoreUpdateContratos,
    toggleModalDeactivate,
    DestroyContratoPersona,
    allDataDetallePersonaSalarioDescuento,
    allDataTipoSalarioDescuento,
    allDataServiciosPrestados,
    allDataGradoAcademico,
    allDataDepartamento,
    allDataEstadoCivil,
    allDataTipoSangre,
    allDataMunicipio,
    allDataProfesion,
    allDataContratos,
    nameFileExport,
    showDeactivate,
    tipoPersonas,
    ubicaciones,
    dataPersona,
    oneDataFoto,
    checkInput,
    renglones,
    idPersona,
    oneData,
    allData,
    puestos,
    generos,
    etnias,
    showMF,
    opcion,
    sedes,
    step,
    show,
  }

  useEffect(() => {
    const functionFetch = async () => {
      All()
      AllTipoSalarioDescuento()
      AllSedes()
      AllEtnias()
      AllGenero()
      //AllProfesion()
      AllRenglones()
      //AllTipoSangre()
      //AllUbicaciones()
      AllTipoPersona()
      AllDepartamento()
      AllEstadosCiviles()
      //AllGradoAcademico()
      AllServiciosPrestados()
    }
    functionFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (dataAllProfesion?.value === 1) {
      setAllDataProfesion(dataAllProfesion.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAllProfesion])

  useEffect(() => {
    if (dataAllGradoAcademico?.value === 1) {
      setAllDataGradoAcademico(dataAllGradoAcademico.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAllGradoAcademico])

  useEffect(() => {
    if (dataAllTipoSangre?.value === 1) {
      setAllDataTipoSangre(dataAllTipoSangre.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAllTipoSangre])

  useEffect(() => {
    if (dataAllUbicaciones?.value === 1) {
      setUbicaciones(dataAllUbicaciones.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAllUbicaciones])

  useEffect(() => {
    One()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
}
