/* eslint-disable react/jsx-no-target-blank */

// import { useContext } from 'react'
import {AsideMenuItemWithSub} from '../../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../AsideMenuItem'
// import { UserContext } from '../../../../../../../app/utility/context/userContext'

const Ejecucion = () => {
  // const { Roles, userRoles } = useContext(UserContext)
  return (
    <AsideMenuItemWithSub
      to='/catalogos'
      title={'Catálogos'}
      fontIcon='bi-archive'
      icon='/media/icons/duotune/general/gen022.svg'
    >
      {/* <AsideMenuItem
        to='/seguimiento/catalogos/actividad-inventario'
        title={'Actividad Inventario'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      />
      <AsideMenuItem
        to='/seguimiento/catalogos/actividad-nombramiento'
        title={'Actividad Nombramiento'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      /> */}
      <AsideMenuItem
        to='/seguimiento/catalogos/tipo-nombramiento'
        title={'Tipo Nombramiento'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      />
      {/* <AsideMenuItem
        to='/seguimiento/catalogos/tipo-documento'
        title={'Tipo Documento'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      /> */}
      {/* <AsideMenuItem
        to='/seguimiento/catalogos/tipo-recomendacion'
        title={'Tipo Recomendacion'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      />
      <AsideMenuItem
        to='/seguimiento/catalogos/tipo-requisito'
        title={'Tipo Requisito'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      />
      <AsideMenuItem
        to='/seguimiento/catalogos/tipo-proceso'
        title={'Tipo Proceso'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      /> */}
      <AsideMenuItem
        to='/seguimiento/catalogos/cargahistorica'
        title={'Carga Historica'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
        
      />
      <AsideMenuItem
        to='/seguimiento/catalogos/fisca-tipo-informe'
        title={'Catalogo  de Tipo de Fiscalizacion'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      />
      {/* <AsideMenuItem
        to='/seguimiento/catalogos/tipoexpediente'
        title={'Tipo Expediente'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      />
      
      <AsideMenuItem
        to='/seguimiento/catalogos/secciones-informe'
        title={'Secciones Informe'}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      /> */}
    </AsideMenuItemWithSub>
  )
}

export default Ejecucion
