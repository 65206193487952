import {Fragment, useContext, useEffect,useState} from 'react'
import {Button, Modal, Form,Alert, Badge, Col} from 'react-bootstrap-v5'
import {ContentContext} from './context'
import {useForm} from 'react-hook-form'
import ReactTooltip from 'react-tooltip'
import {X, MessageCircle,User,MessageSquare} from 'react-feather'
import { NotificationsContext } from '../../../../app/utility/context/notificacionContext'
import {PostRoute, GetRoute} from '../../../services/Private'

import clsx from 'clsx'
import {
  defaultMessages,
  MessageModel
} from '../../../../_metronic/helpers'

import DataTable from 'react-data-table-component'
import { CustomText, EstadoLista } from '../../../utility/customTable/index'
import { UserContext } from '../../../utility/context/userContext'
const bufferMessages = defaultMessages

export const Formulario = () => {
  const {
      register,
      handleSubmit,
      formState: {errors},
      setValue,
      reset,
      watch
    } = useForm(),
    {toggleModal, show, opcion, oneData, StoreUpdate,
      Personal,
      tab,
      tabActive,
      personasSinLeer,
      sumaSinLeer,
      enviadasNotificaciones,
      Actions,
      recibidosNotificaciones
    } = useContext(NotificationsContext),
      {userAuth} = useContext(UserContext),
      [searchTerm, setSearchTerm] = useState(''),
      [filteredData, setFilteredData] = useState<any>([]),
      [nombrePersona, setnombrePersona] = useState<any>([]),
      [ubicacionPersona, setUbicacionPersona] = useState<any>([]),
      [idUbicacionPersona, setIdUbicacionPersona] = useState<any>([]),
      [idPersona, setIdPersona] = useState<any>([]),
      [closeHandler, setCloseHandler] = useState<any>(false),
      [messages, setMessages] = useState<any>([]),

    onSubmit = async (data: any) => {
      const content = {
        titulo: '',
        mensaje: data.mensaje,
        persona: idPersona,
        visto: 0,
        ubicacion: idUbicacionPersona,
      }
      
      await PostRoute(`NorificacionN/Create`, content)
      reset()

      await AllMensajes(idPersona)
    }

    const AllMensajes = async (data :any) => {
      const content = {
        persona: data
      }
      
      let response = await PostRoute(`NorificacionN/all-mensajes`, content)
      setMessages(response.data !== null ? response.data : [])
    }
    const handleSearchChange = (e) => {
      let updatedData = []
      const value = String(e.target.value)
        if (value.length) {
          if(tab === 1){
            updatedData = []
            updatedData = Personal.filter((item: any) => {
              const startsWith =
                  item.label.toLowerCase().startsWith(value.toLowerCase()) ||
                  item.ubicacion.toLowerCase().startsWith(value.toLowerCase())
    
              const includes =
                  item.label.toLowerCase().includes(value.toLowerCase()) ||
                  item.ubicacion.toLowerCase().includes(value.toLowerCase())
    
              if (startsWith) {
                  return startsWith
              } else if (!startsWith && includes) {
                  return includes
              } else return null
            })
          }
          if(tab === 2){
            updatedData = []
            updatedData = personasSinLeer.filter((item: any) => {
              const startsWith =
                  item.label.toLowerCase().startsWith(value.toLowerCase()) ||
                  item.ubicacion.toLowerCase().startsWith(value.toLowerCase())
    
              const includes =
                  item.label.toLowerCase().includes(value.toLowerCase()) ||
                  item.ubicacion.toLowerCase().includes(value.toLowerCase())
    
              if (startsWith) {
                  return startsWith
              } else if (!startsWith && includes) {
                  return includes
              } else return null
            })
          }
          if(tab === 3){
            updatedData = []
            updatedData = enviadasNotificaciones.filter((item: any) => {
              const startsWith =
                  item.label.toLowerCase().startsWith(value.toLowerCase()) ||
                  item.ubicacion.toLowerCase().startsWith(value.toLowerCase())
    
              const includes =
                  item.label.toLowerCase().includes(value.toLowerCase()) ||
                  item.ubicacion.toLowerCase().includes(value.toLowerCase())
    
              if (startsWith) {
                  return startsWith
              } else if (!startsWith && includes) {
                  return includes
              } else return null
            })
          }
          if(tab === 4){
            updatedData = []
            updatedData = recibidosNotificaciones.filter((item: any) => {
              const startsWith =
                  item.label.toLowerCase().startsWith(value.toLowerCase()) ||
                  item.ubicacion.toLowerCase().startsWith(value.toLowerCase())
    
              const includes =
                  item.label.toLowerCase().includes(value.toLowerCase()) ||
                  item.ubicacion.toLowerCase().includes(value.toLowerCase())
    
              if (startsWith) {
                  return startsWith
              } else if (!startsWith && includes) {
                  return includes
              } else return null
            })
          }
        
        }
        setFilteredData(updatedData)
        setSearchTerm(value)

    };
    
    const handleNameClick = (data) => {
      setnombrePersona(data.label);
      setUbicacionPersona(data.ubicacion);
      setIdPersona(data.value);
      setIdUbicacionPersona(data.idUbicacion);
      AllMensajes(data.value)
    };
    
    const toggleMessages = () => {
      setCloseHandler(!closeHandler);
      if (!closeHandler) {
      }
      else
      {
        setnombrePersona([]);
        setUbicacionPersona([]);
        setIdPersona([]);
        setIdUbicacionPersona([]);}
    };

  const basicColumns = [
    {
        name: "",
        column: 'mensaje',
        selector: (row: any) => row.mensaje,
        sortable: true,
        center: true,
        cell: (row: any) => (
          
          <Col   className={` ${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'd-flex justify-content-end':'d-flex justify-content-start'} `} xl={11} lg={11} md={11} sm={11}>
        <Alert   className={`alert-xs  `}
        variant={`alert-dismissible bg-light-${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'success':'primary'} text-${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'success':'primary'}`}>
          
                <p className={` ${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'align-right':''}`}>
                  <h6 className={`text-${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'success':'primary'}`}>
                    <b>
                    {
                    
                    row.enviado }
                  </b>
                  </h6>
                </p>
                <hr />
                <p className={` ${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'align-right':''}`}>
                <h6 className={`text-${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'success':'primary'}`}>{                    
                    row.mensaje }
                  </h6>
                </p>
                <hr />
                <p className={` ${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'align-right':''}`}>
                {                    
                    row.Creado + '-' + row.fecha }
                </p>
              </Alert> 
        </Col>
        
        )
    }
]





  return (
    <Fragment>
      <Button
        variant='primary'
        size='sm'
        onClick={() => toggleModal(1)}
        data-for='crear'
        data-tip='Notificar'
        className="btn btn-icon btn-sm  mx-2"
      >
        <MessageCircle  className="fa fa-file-pdf svg-icon svg-icon-1" size={16} />
        {/* <span className="pulse-ring"></span> */}
      </Button>
      <ReactTooltip id='crear' place='left' type='dark' effect='solid' />
      <Modal show={show} onHide={() => toggleModal(0)} backdrop='static' centered keyboard={false} dialogClassName='modal-70w'>
        <Modal.Header closeButton>
          <Modal.Title className='text-uppercase h1'>
            {'Sección de Notificación '}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
          <div className='d-flex flex-column flex-lg-row'>
            <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
              <div className='card card-flush'>
              <div className='d-flex h-55px'>
                  <Button
                      className='mx-2 my-2 btn btn-icon btn-active-light-success me-6'
                      variant='success'
                      size='sm'
                      onClick={() => tabActive(1)}
                      data-for='nuevo'
                      data-tip='Nuevo'
                    >
                      +<MessageSquare  className="fa fa-file-pdf svg-icon svg-icon-1" size={16} />
                    </Button>
                    <ReactTooltip id='nuevo' place='top' type='dark' effect='solid' />
                                      
              </div>
              <div className='mx-5 d-flex h-55px'>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 border-0 '>
            <li className='nav-item' onClick={() => tabActive(2)}>
                    <a
                      className={`nav-link text-active-primary me-6 ` + (tab === 2 && 'active')}
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_2'
                    >
                      Sin Leer{' '}<Badge pill bg="danger">{sumaSinLeer}</Badge>
                    </a>
                  </li>
              <li className='nav-item' onClick={() => tabActive(3)}>
                <a
                  className={`nav-link text-active-primary me-6 ` + (tab === 3 && 'active')}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_2'
                >
                  Enviados
                </a>
              </li>
              <li className='nav-item' onClick={() => tabActive(4)}>
                <a
                  className={`nav-link text-active-primary me-6 ` + (tab === 4 && 'active')}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_2'
                >
                  Recibidos
                </a>
              </li>
            </ul>
          </div>
                <div  id='kt_chat_contacts_header'>
                  {/* <form className='w-100 position-relative' autoComplete='off'> */}
                    

                    <input
                      type='text'
                      className='form-control form-control-solid px-15'
                      name='search'
                      placeholder='Buscar'
                      onChange={handleSearchChange} // Agregar manejador de cambio aquí
                    />
                  {/* </form> */}
                </div>
          


{/* Listado de personas para envio de mensajes  */}
          <div className='card-body pt-5' id='kt_chat_contacts_body'>
{tab === 1 &&
            <div

            style={{  height: '580px', overflowY: 'scroll'}}
          >
                
                  { searchTerm.length ? filteredData.map((item, i) => (
            <div className='d-flex flex-stack py-4'>

              <div className='d-flex align-items-center'>

                      <div className='symbol symbol-45px symbol-circle'>
                      <span className='symbol-label bg-light-success text-success fs-6 fw-bolder'>
                                    <User className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />
                                  </span>
                      </div>
                      <div className='ms-5'>
                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' 
                                onClick={() => {handleNameClick(item);toggleMessages()}}>
                        {item.label}
                      </a>
                      <div className='fw-bold text-gray-400'>{item.ubicacion}</div>
                      
                    </div>
              </div>
              {/* <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-400'>Active</span>
              </div>
              <div className='d-flex flex-column align-items-end ms-2'>
                <span className='text-muted fs-7 mb-1'>5 hrs</span>
              </div> */}
            </div>
                    )) : Personal.map((item, i) => (
                      <div className='d-flex flex-stack py-4'>
        
                        <div className='d-flex align-items-center'>
        
                                <div className='symbol symbol-45px symbol-circle'>
                                  <span className='symbol-label bg-light-success text-success fs-6 fw-bolder'>
                                    <User className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />
                                  </span>
                                </div>
                                <div className='ms-5'>
                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' 
                                onClick={() => {handleNameClick(item);toggleMessages()}}>
                                  {item.label}
                                </a>
                                <div className='fw-bold text-gray-400'>{item.ubicacion}</div>
                                
                              </div>
                        </div>
                        {/* <div className='mb-0 lh-1'>
                          <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                          <span className='fs-7 fw-bold text-gray-400'>Active</span>
                        </div>
                        <div className='d-flex flex-column align-items-end ms-2'>
                          <span className='text-muted fs-7 mb-1'>5 hrs</span>
                        </div> */}
                      </div>
                              ))
                    
                  }
            <div className='separator separator-dashed d-none'></div>

          </div>}
{/* Listado de Personas con Mensajes sin Leer */}
{tab === 2 &&
            <div

            style={{  height: '580px', overflowY: 'scroll'}}
          >
                
                  { searchTerm.length ? filteredData.map((item, i) => (
            <div className='d-flex flex-stack py-4'>

              <div className='d-flex align-items-center'>

                      <div className='symbol symbol-45px symbol-circle'>
                          <span className='symbol-label bg-light-success text-success fs-6 fw-bolder'>
                            <User className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />{' '}<Badge pill bg="danger">{item.cantidadMensajesSinLeer}</Badge>
                          </span>
                      </div>
                      <div className='ms-5'>
                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' 
                                onClick={() => {handleNameClick(item);toggleMessages();Actions(item.value)}}>
                        {item.label}
                      </a>
                      <div className='fw-bold text-gray-400'>{item.ubicacion}</div>
                      
                    </div>
              </div>
              {/* <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-400'>Active</span>
              </div>
              <div className='d-flex flex-column align-items-end ms-2'>
                <span className='text-muted fs-7 mb-1'>5 hrs</span>
              </div> */}
            </div>
                    )) : personasSinLeer.map((item, i) => (
                      <div className='d-flex flex-stack py-4'>
        
                        <div className='d-flex align-items-center'>
        
                                <div className='symbol symbol-45px symbol-circle'>
                                  <span className='symbol-label bg-light-success text-success fs-6 fw-bolder'>
                                    <User className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />{' '}<Badge pill bg="danger">{item.cantidadMensajesSinLeer}</Badge>
                                  </span>
                                </div>
                                <div className='ms-5'>
                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' 
                                onClick={() => {handleNameClick(item);toggleMessages();Actions(item.value)}}>
                                  {item.label}
                                </a>
                                <div className='fw-bold text-gray-400'>{item.ubicacion}</div>
                                
                              </div>
                        </div>
                        {/* <div className='mb-0 lh-1'>
                          <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                          <span className='fs-7 fw-bold text-gray-400'>Active</span>
                        </div>
                        <div className='d-flex flex-column align-items-end ms-2'>
                          <span className='text-muted fs-7 mb-1'>5 hrs</span>
                        </div> */}
                      </div>
                              ))
                    
                  }
            <div className='separator separator-dashed d-none'></div>

          </div>}
{tab === 3 &&
            <div

            style={{  height: '580px', overflowY: 'scroll'}}
          >
                
                  { searchTerm.length ? filteredData.map((item, i) => (
            <div className='d-flex flex-stack py-4'>

              <div className='d-flex align-items-center'>

                      <div className='symbol symbol-45px symbol-circle'>
                      <span className='symbol-label bg-light-success text-success fs-6 fw-bolder'>
                                    <User className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />
                                  </span>
                      </div>
                      <div className='ms-5'>
                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' 
                                onClick={() => {handleNameClick(item);toggleMessages()}}>
                        {item.label}
                      </a>
                      <div className='fw-bold text-gray-400'>{item.ubicacion}</div>
                      
                    </div>
              </div>
              {/* <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-400'>Active</span>
              </div>
              <div className='d-flex flex-column align-items-end ms-2'>
                <span className='text-muted fs-7 mb-1'>5 hrs</span>
              </div> */}
            </div>
                    )) : enviadasNotificaciones.map((item, i) => (
                      <div className='d-flex flex-stack py-4'>
        
                        <div className='d-flex align-items-center'>
        
                                <div className='symbol symbol-45px symbol-circle'>
                                  <span className='symbol-label bg-light-success text-success fs-6 fw-bolder'>
                                    <User className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />
                                  </span>
                                </div>
                                <div className='ms-5'>
                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' 
                                onClick={() => {handleNameClick(item);toggleMessages()}}>
                                  {item.label}
                                </a>
                                <div className='fw-bold text-gray-400'>{item.ubicacion}</div>
                                
                              </div>
                        </div>
                        {/* <div className='mb-0 lh-1'>
                          <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                          <span className='fs-7 fw-bold text-gray-400'>Active</span>
                        </div>
                        <div className='d-flex flex-column align-items-end ms-2'>
                          <span className='text-muted fs-7 mb-1'>5 hrs</span>
                        </div> */}
                      </div>
                              ))
                    
                  }
            <div className='separator separator-dashed d-none'></div>

          </div>}
{tab === 4 &&
            <div

            style={{  height: '580px', overflowY: 'scroll'}}
          >
                
                  { searchTerm.length ? filteredData.map((item, i) => (
            <div className='d-flex flex-stack py-4'>

              <div className='d-flex align-items-center'>

                      <div className='symbol symbol-45px symbol-circle'>
                      <span className='symbol-label bg-light-success text-success fs-6 fw-bolder'>
                                    <User className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />
                                  </span>
                      </div>
                      <div className='ms-5'>
                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' 
                                onClick={() => {handleNameClick(item);toggleMessages()}}>
                        {item.label}
                      </a>
                      <div className='fw-bold text-gray-400'>{item.ubicacion}</div>
                      
                    </div>
              </div>
              {/* <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-400'>Active</span>
              </div>
              <div className='d-flex flex-column align-items-end ms-2'>
                <span className='text-muted fs-7 mb-1'>5 hrs</span>
              </div> */}
            </div>
                    )) : recibidosNotificaciones.map((item, i) => (
                      <div className='d-flex flex-stack py-4'>
        
                        <div className='d-flex align-items-center'>
        
                                <div className='symbol symbol-45px symbol-circle'>
                                  <span className='symbol-label bg-light-success text-success fs-6 fw-bolder'>
                                    <User className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />
                                  </span>
                                </div>
                                <div className='ms-5'>
                                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2' 
                                onClick={() => {handleNameClick(item);toggleMessages()}}>
                                  {item.label}
                                </a>
                                <div className='fw-bold text-gray-400'>{item.ubicacion}</div>
                                
                              </div>
                        </div>
                        {/* <div className='mb-0 lh-1'>
                          <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                          <span className='fs-7 fw-bold text-gray-400'>Active</span>
                        </div>
                        <div className='d-flex flex-column align-items-end ms-2'>
                          <span className='text-muted fs-7 mb-1'>5 hrs</span>
                        </div> */}
                      </div>
                              ))
                    
                  }
            <div className='separator separator-dashed d-none'></div>

          </div>}

          </div>
        </div>
      </div>

      {closeHandler && <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
        
        <div className='card' id='kt_chat_messenger'>
{/* cambecera de persona seleccionada para mensajes  */}
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'></div>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a
                  href='#'
                  className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                >
                  {nombrePersona}
                </a>

                <div className='mb-0 lh-1'>
                  {/* <span className='badge badge-success badge-circle w-10px h-10px me-1'></span> */}
                  <span className='fs-7 fw-bold text-gray-400'>{ubicacionPersona}</span>
                </div>
              </div>
            </div>

            <div  className='card-toolbar'>
              <div className='me-n3'>
              <Button variant="danger" className='btn btn-sm btn-icon' onClick={toggleMessages}>
                <X className='fa fa-file-pdf svg-icon svg-icon-1' size={16} />
              </Button>

              </div>
            </div>
          </div>
          <div 
          >
{/* Mensajes Recuperados de la Persona seleccionada  */}
          <div
      className='card-body'
      id={true ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !true})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          true
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          true ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={true ? '0px' : '-2px'}
      >
        
      </div>
      {/* <DataTable
                // dense
                noHeader
                highlightOnHover
                pagination
                data={messages}
                columns={basicColumns}
                className='table-responsive'
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: "Filas por pagina",
                        rangeSeparatorText: "de"
                    }
                }
                noDataComponent={"Sin datos"}
            /> */}

<div className="messages-container" style={{ height: '550px', overflowY: 'scroll',display: 'flex',
  flexDirection: 'column-reverse',
  }}>
              {messages.map((row, index) => (
                <Col   className={` ${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'd-flex justify-content-end':'d-flex justify-content-start'} `} xl={11} lg={11} md={11} sm={11}>
                <Alert   className={`alert-xs  `}
                variant={`alert-dismissible bg-light-${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'success':'primary'} text-${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'success':'primary'}`}>
                  
                        <p className={` ${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'align-right':''}`}>
                          <p className={`text-${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'success d-flex justify-content-end':'primary'}`}>
                            <b>
                            { userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'Yo': row.enviado }
                          </b>
                          </p>
                        </p>
                        <hr />
                        <p className={` ${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'align-right':''}`}>
                        <p className={`text-${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'success':'primary'}`}>{                    
                            row.mensaje }
                          </p>
                        </p>
                        <p className={` ${userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'align-right':''}`}>
                        { userAuth.nombreCompleto.replace(/ /g, '') === row.enviado.replace(/ /g, '') ? 'Enviado ': 'Recibido ' }

                        {                    
                            row.Creado  }
                        </p>
                      </Alert> 
                </Col>
              ))}
            </div>
    </div>


          </div>
        </div>
{/* Formulario para envio de mensajes  */}
        <div
        className='card-footer pt-4'
        id={true ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
         <Form.Group controlId='mensaje' className='mb-3 position-relative'>
              <Form.Control
                {...register('mensaje', {
                  required: 'Escribir Mensaje',
                })}
                readOnly={opcion === 2}
                isInvalid={!!errors.mensaje}
                as='textarea'
                rows={2}
                placeholder='Escribir Mensaje'
              />
              <Form.Control.Feedback type='invalid'>
                {!!errors.mensaje && errors.mensaje.message}
              </Form.Control.Feedback>
            </Form.Group>

        
      </div>
    <div className='d-flex justify-content-end'>
          
    <Button
                  variant='success'
                  size='sm'
                  type='submit'
                  data-for='Enviar'
                  data-tip='Enviar'
                >
                  Enviar
                </Button>
                <ReactTooltip id='Enviar' place='top' type='dark' effect='solid' />
        </div>
      </div>}
    </div>
          </Modal.Body>

        </Form>
      </Modal>
    </Fragment>
  )
}

export default Formulario
