/* @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import {Tabs, Tab} from 'react-bootstrap-v5'
import DatosPersonales from './datosPersonales'
import {DatosContacto} from './datosContacto'

import {ContentContext} from '../context'

import {useContext} from 'react'

// eslint-disable-next-line react-hooks/exhaustive-deps
const Formulario = () => {
  const {setStep, step} = useContext(ContentContext)

  return (
    <>
      
          <Tabs
            fill
            activeKey={step}
            className='mb-5'
            id='controlled-tab-example'
            onSelect={(k: any) => setStep(k)}
          >
            <Tab eventKey='0' title='Datos Personales'>
              <DatosPersonales />
            </Tab>
            <Tab eventKey='1' title='Datos de Contacto'>
              <DatosContacto />
            </Tab>
            
          </Tabs>
    </>
  )
}

export default Formulario
