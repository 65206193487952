/* eslint-disable react/jsx-no-target-blank */
import {useContext} from 'react'
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'
import {UserContext} from '../../../../../../app/utility/context/userContext'

const Contabilidad = () => {
  const {Roles, userRoles} = useContext(UserContext)
  console.log('userRoles', userRoles)
  return (
    <AsideMenuItemWithSub to='/contabilidad' title='Contabilidad' biIcon='bi-book'>
      {userRoles.includes(Roles.a_cont) || userRoles.includes(Roles.aux_cont) ? (
        <>
          <AsideMenuItem to='/contabilidad/bancos' title='Bancos' biIcon='bi-bank' />
          <AsideMenuItem
            to='/contabilidad/cuentas'
            title='Cuentas'
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <AsideMenuItem
            to='/contabilidad/depreciaciones'
            title='Depreciaciones'
            biIcon='bi-circle'
          />
          <AsideMenuItem
            to='/contabilidad/conciliacion'
            title='Conciliación Bancaria'
            biIcon='bi-circle'
          />
          <AsideMenuItem
            to='/contabilidad/operaciones'
            title='Creacion Operaciones'
            biIcon='bi-circle'
          />
          {/* <AsideMenuItem
          to='/contabilidad/seccion-oficios-capacitacion'
          title='Sección de Oficios con Capacitación'
          icon='/media/icons/duotune/general/gen022.svg'
        />  */}
          <AsideMenuItem to='/contabilidad/libro-bancos' title='Libros Bancos' biIcon='bi-circle' />
          {userRoles.includes(Roles.a_cont) ? (
            <AsideMenuItem
              to='/contabilidad/usuarios'
              title='Asignar Encargados Rotativos'
              biIcon='bi-circle'
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        ''
      )}

      {userRoles.includes(Roles.a_inv) ? (
        <>
          <AsideMenuItem
            to='/contabilidad/cuentas'
            title='Cuentas'
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <AsideMenuItem
            to='/contabilidad/depreciaciones'
            title='Depreciaciones'
            biIcon='bi-circle'
          />
        </>
      ) : (
        ''
      )}

      {userRoles.includes(Roles.a_fin) ? (
        <>
          <AsideMenuItem to='/contabilidad/bancos' title='Bancos' biIcon='bi-bank' />
          <AsideMenuItem
            to='/contabilidad/cuentas'
            title='Cuentas'
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <AsideMenuItem
            to='/contabilidad/depreciaciones'
            title='Depreciaciones'
            biIcon='bi-circle'
          />
          <AsideMenuItem
            to='/contabilidad/conciliacion'
            title='Conciliación Bancaria'
            biIcon='bi-circle'
          />
          <AsideMenuItem
            to='/contabilidad/operaciones'
            title='Creacion Operaciones'
            biIcon='bi-circle'
          />
          <AsideMenuItem to='/contabilidad/libro-bancos' title='Libros Bancos' biIcon='bi-circle' />
          <AsideMenuItem
            to='/contabilidad/usuarios'
            title='Asignar Encargados Rotativos'
            biIcon='bi-circle'
          />
          {/* <AsideMenuItem
              to='/contabilidad/seccion-oficios-capacitacion'
              title='Sección de Oficios con Capacitación'
              icon='/media/icons/duotune/general/gen022.svg'
            />  */}
        </>
      ) : (
        ''
      )}
    </AsideMenuItemWithSub>
  )
}

export default Contabilidad
