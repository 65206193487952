/* eslint-disable react/jsx-no-target-blank */

import clsx from 'clsx'
import { AsideMenuItem } from '../../AsideMenuItem'

const IT = () => {

  return (
    <>
      <div className='menu-item'>
        <a className={clsx('menu-link without-sub')} href={'http://190.99.117.234:9093/'}  target="_blank" rel="noopener noreferrer">
          {<span className='menu-icon'><i className={clsx('bi fs-3', 'bi-file-earmark-text-fill')} /></span>}
          <span className='menu-title'>{'Encuesta Para Ahorro y Crédito'}</span>
        </a>
      </div>
      <div className='menu-item'>
        <a className={clsx('menu-link without-sub')} href={'http://190.99.117.234:90/'} target="_blank" rel="noopener noreferrer">
          {<span className='menu-icon'><i className={clsx('bi fs-3', 'bi-file-earmark-text-fill')} /></span>}
          <span className='menu-title'>{'Encuesta Para Agricolas'}</span>
        </a>
      </div>
      <div className='menu-item'>
        <a className={clsx('menu-link without-sub')} href={'http://190.99.117.234:9098/'} target="_blank" rel="noopener noreferrer">
          {<span className='menu-icon'><i className={clsx('bi fs-3', 'bi-file-earmark-text-fill')} /></span>}
          <span className='menu-title'>{'Encuesta para Comercializacion y Consumo'}</span>
        </a>
      </div>
      <AsideMenuItem to='/public/mis-capacitaciones/capa-coope' title='Sección De Capacitaciones' biIcon='bi-laptop' />

      </>
      
      
  )
}

export default IT